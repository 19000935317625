import {qaInformationAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreateQaInformationAction(action) {
  const result = yield Api.postCreateQaInformation(action.data);
  yield put({type: qaInformationAction.END_FETCH_CREATE_QA_INFORMATION, result: result});
}

function* onFetchIndexQaInformationAction(action) {
  const result = yield Api.getIndexQaInformation(action.data);
  yield put({type: qaInformationAction.END_FETCH_INDEX_QA_INFORMATION, result: result});
}

function* onFetchShowQaInformationAction(action) {
  const result = yield Api.getShowQaInformation(action.data);
  yield put({type: qaInformationAction.END_FETCH_SHOW_QA_INFORMATION, result: result});
}

function* onFetchUpdateQaInformationAction(action) {
  const result = yield Api.putUpdateQaInformation(action.data);
  yield put({type: qaInformationAction.END_FETCH_UPDATE_QA_INFORMATION, result: result});
}

function* onFetchDeleteQaInformationAction(action) {
  const result = yield Api.deleteDeleteQaInformation(action.data);
  yield put({type: qaInformationAction.END_FETCH_DELETE_QA_INFORMATION, result: result});
}

export function* watchOnFetchQaInformation() {
  yield takeLatest(qaInformationAction.ON_FETCH_CREATE_QA_INFORMATION, onFetchCreateQaInformationAction);
  yield takeLatest(qaInformationAction.ON_FETCH_INDEX_QA_INFORMATION, onFetchIndexQaInformationAction);
  yield takeLatest(qaInformationAction.ON_FETCH_SHOW_QA_INFORMATION, onFetchShowQaInformationAction);
  yield takeLatest(qaInformationAction.ON_FETCH_UPDATE_QA_INFORMATION, onFetchUpdateQaInformationAction);
  yield takeLatest(qaInformationAction.ON_FETCH_DELETE_QA_INFORMATION, onFetchDeleteQaInformationAction);
}
