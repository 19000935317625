import { fromJS, Map, List } from 'immutable';
import MenuContent from 'dan-api/ui/menu';
import {mailPaymentAction} from "../constants/actionTypes";

const initialState = {
  loadingMailPaymentCreate: false,
  loadingMailPaymentIndex: false,
  loadingMailPaymentShow: false,
  loadingMailPaymentData: false,
  mailPaymentData: Map(),
  initMailPaymentData: Map({
    company_id: '',
    kind: '',
    email: '',
    tel: '',
    csv: '',
    payment_form_id: '',
    delivery_at: '',
    limit_date: '',
    subject: '',
    body: '',
    test_email: '',
    test_tel: ''
  })
};

const initialImmutableState = fromJS(initialState);
const mailPaymentReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case mailPaymentAction.ON_FETCH_CREATE_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentCreate', true);
      });
    case mailPaymentAction.END_FETCH_CREATE_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentCreate', false);
      });
    case mailPaymentAction.ON_FETCH_INDEX_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentIndex', true);
      });
    case mailPaymentAction.END_FETCH_INDEX_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentIndex', false);
      });
    case mailPaymentAction.ON_FETCH_SHOW_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentShow', true);
      });
    case mailPaymentAction.END_FETCH_SHOW_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentShow', false);
        mutableState.set('mailPaymentData', action.result);
      });
    case mailPaymentAction.ON_FETCH_UPDATE_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentData', true);
      });
    case mailPaymentAction.END_FETCH_UPDATE_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentData', false);
      });
    case mailPaymentAction.ON_FETCH_DELETE_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentData', true);
      });
    case mailPaymentAction.END_FETCH_DELETE_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentData', false);
      });
    case mailPaymentAction.ON_FETCH_TEST_SEND_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentData', true);
      });
    case mailPaymentAction.END_FETCH_TEST_SEND_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentData', false);
      });
    case mailPaymentAction.CLEAR_MAIL_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('mailPaymentData', initialState.initMailPaymentData);
      });
    default:
      return state
  }
};

export default mailPaymentReducers;