import React from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { Route } from 'react-router-dom';
import ErrorWrap from 'dan-components/Error/ErrorWrap';

const title = brand.name + ' - Page Not Found';
const description = brand.desc;

const NotFound = () => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.status = 404; // eslint-disable-line
      }
      return (
        <div>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <ErrorWrap title="404" desc="ページが見つかりません" />
        </div>
      );
    }}
  />
);

export default NotFound;
