import { fromJS, List } from 'immutable';
import MenuContent from 'dan-api/ui/menu';
import {accountAction} from "../constants/actionTypes";

const initialState = {
  loadingCreateAccount: false,
  loadingUserLogin: false,
  loadingUserInfo: false,
  loadingPasswordReminder: false,
  loadingResetPassword: false
};

const initialImmutableState = fromJS(initialState);
const accountReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case accountAction.ON_FETCH_CREATE_ACCOUNT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingCreateAccount', true);
      });
    case accountAction.END_FETCH_CREATE_ACCOUNT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingCreateAccount', false);
      });
    case accountAction.ON_FETCH_USER_LOGIN:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserLogin', true);
      });
    case accountAction.END_FETCH_USER_LOGIN:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserLogin', false);
      });
    case accountAction.ON_FETCH_USER_LOGOUT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserLogout', true);
      });
    case accountAction.END_FETCH_USER_LOGOUT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserLogout', false);
      });
    case accountAction.ON_FETCH_USER_INFO:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserInfo', true);
      });
    case accountAction.END_FETCH_USER_INFO:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserInfo', false);
      });
    case accountAction.ON_FETCH_CREATE_PASSWARD_REMINDER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPasswordReminder', true);
      });
    case accountAction.END_FETCH_CREATE_PASSWARD_REMINDER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPasswordReminder', false);
      });
    case accountAction.ON_FETCH_UPDATE_PASSWARD_REMINDER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingResetPassword', true);
      });
    case accountAction.END_FETCH_UPDATE_PASSWARD_REMINDER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingResetPassword', false);
      });
    default:
      return state
  }
};

export default accountReducers;