import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, FieldArray } from 'redux-form/immutable';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, CheckboxRedux, renderRadioGroup, SwitchRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import * as Validator from '../../../utils/validate'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { fromJS } from 'immutable';
import TextEditor from '../Companies/TextEditor';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Hidden from '@material-ui/core/Hidden';
import renderPlans from './renderPlans.jsx';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function renderServices(props) {
  const {
    classes,
    fields,
    isNormalPayment,
    isNew,
    accessToken,
    meta: { touched, error, submitFailed }
  } = props;

  useEffect(() => {
    if (isNew) {
      fields.removeAll();
      addItem()
    }
  }, []);

  const addItem = (name = "", content = "", option = "", is_required = false, has_quantity = false, is_terms = false, terms_name = "", terms = "", payment_plans_attributes = [{name: "", content: "", price: "", max_number: "", stock: "", image: "", free_period: ""}]) => {
    fields.push(fromJS({name: name, content: content, option: option, is_required: is_required, has_quantity: has_quantity, is_terms: is_terms, terms_name, terms, payment_plans_attributes: fromJS(payment_plans_attributes)}))
  };

  useEffect(() => {
    fields.getAll() && updateServiceIndex();
  },[fields]);

  const [indexData, setIndexData] = useState([]);
  const [lastServiceIndex, setLastServiceIndex] = useState();
  const updateServiceIndex = () => {
    let serviceIndex = 1;
    const data = fields.getAll().toJS().map(item => {
       return item._destroy ? item : { ...item, serviceIndex: serviceIndex++ };
    });
    setIndexData(data)
    const filteredData = data.filter(item => item.serviceIndex)
    const lastServiceIndex = filteredData[filteredData.length - 1].serviceIndex;
    setLastServiceIndex(lastServiceIndex)
  }

  const moveService = (fromIndex, toIndex, direction) => {
    while (fields.get(toIndex).get("_destroy")) {
      if (direction == 'up') {
        toIndex -= 1
      } else if (direction == 'down') {
        toIndex += 1
      }
    }
    fields.move(fromIndex, toIndex);
  };

  return (
    <div>
      {fields.map((service, index) => (
        fields.get(index).get("_destroy") ? "" :
        <Paper key={index} style={{ border: "1px solid silver", padding: 15, marginBottom: 30 }} elevation={4}>
          <Typography className={classes.title} variant="h6" style={{ float:'left' }}>サービス {indexData[index] && indexData[index].serviceIndex}</Typography>
          { index !== 0 &&
            <Tooltip title="サービスを削除" placement="left">
              <FormControlLabel control={(<Field name={`${service}._destroy`} component={CheckboxRedux} icon={<CloseIcon className={classes.icon} />} onClick={() => updateServiceIndex()} style={{ margin: 0, padding: 0 }}/>)} style={{ float:'right' }}/>
            </Tooltip>
          }
          <IconButton size="small" onClick={() => moveService(index, index - 1, 'up')} disabled={index === 0} style={{ marginLeft: 15}}>
            <Tooltip title="並び替え(上)" placement="right">
              <KeyboardArrowUpIcon/>
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => moveService(index, index + 1, 'down')} disabled={indexData[index] && indexData[index].serviceIndex === lastServiceIndex }>
            <Tooltip title="並び替え（下）" placement="right">
              <KeyboardArrowDownIcon/>
            </Tooltip>
          </IconButton>
          <Grid container spacing={3} color="primary">
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} >
                <Field
                  name={`${service}.name`}
                  label="サービス名 *"
                  placeholder="サービス名"
                  fullWidth
                  component={TextFieldRedux}
                  validate={[Validator.required]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">サービス内容</FormLabel>
              <FormControl className={classes.formControl}>
                <Field
                  name={`${service}.content`}
                  component={TextEditor}
                  className={classes.field}
                  accessToken={accessToken}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel component="label">プラン選択設定 *</FormLabel>
              <Field
                name={`${service}.option`}
                style={{display: "flex", flexDirection: "row"}}
                component={renderRadioGroup}
                validate={[Validator.required]}
              >
                <FormControlLabel value="single" control={<Radio />} label="単数選択" />
                <FormControlLabel value="multi" control={<Radio />} label="複数選択" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">プラン選択必須</FormLabel>
              <FormControlLabel control={<Field name={`${service}.is_required`} component={SwitchRedux} disabled={false}/>}/>
              必須
            </Grid>
            { isNormalPayment &&
              <Grid item xs={12}>
                <FormLabel component="legend">在庫管理</FormLabel>
                <FormControlLabel control={<Field name={`${service}.has_quantity`} component={SwitchRedux} disabled={false}/>}/>
                必要
              </Grid>
            }
            <Grid item xs={12}>
              <FormLabel component="legend">規約</FormLabel>
              <FormControlLabel control={<Field name={`${service}.is_terms`} component={SwitchRedux} disabled={false}/>}/>
              必要
            </Grid>
            {fields.get(index).get("is_terms") &&
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name={`${service}.terms_name`}
                      label="規約名 *"
                      placeholder="規約名"
                      fullWidth
                      component={TextFieldRedux}
                      validate={[Validator.required, Validator.length2]}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Field
                        name={`${service}.terms`}
                        component={TextEditor}
                        className={classes.field}
                        accessToken={accessToken}
                        validate={[Validator.required]}
                    />
                  </FormControl>
                </Grid>
              </React.Fragment>
            }
            <Hidden xsDown><Grid item sm={6}></Grid></Hidden>                        
            <Grid item xs={12}>
              <FieldArray
                name={`${service}.payment_plans_attributes`}
                component={renderPlans}
                accessToken={accessToken}
                isNormalPayment={isNormalPayment}
                hasQuantity={fields.get(index).get("has_quantity")}
              />
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Grid container color="primary" justify="center" style={{ marginTop: 20, marginBottom: 30 }}>
        <Button variant="contained" color="secondary" size="large" onClick={() => addItem()}>
          サービスを追加
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FormLabel component="legend">必須サービス設定(チェックしたサービスのうち最低でも1つ以上プランは設定させたい場合)</FormLabel>
        {fields.map((service, outerIndex) => (
          indexData.map((item, innerIndex) => (
            outerIndex == innerIndex && item.serviceIndex && (
              <FormControlLabel key={outerIndex} control={<Field name={`${service}.service_required`} component={CheckboxRedux} />} label={`サービス${item.serviceIndex}`} />
            )
          ))
        ))} 
      </Grid>
    </div>
  );
}

export default withStyles(styles)(renderServices);
