import React, { useState, useEffect, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import FileIcon from '@material-ui/icons/Description';
import ActionDelete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloudUpload from '@material-ui/icons/CloudUpload';
import 'dan-styles/vendors/react-dropzone/react-dropzone.css';
import isImage from './helpers/helpers.js';

const styles = theme => ({
  dropItem: {
    borderColor: theme.palette.divider,
    background: theme.palette.background.default,
    borderRadius: theme.rounded.medium,
    color: theme.palette.text.disabled,
    textAlign: 'center'
  },
  uploadIconSize: {
    display: 'inline-block',
    '& svg': {
      width: 72,
      height: 72,
      fill: theme.palette.secondary.main,
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      fill: theme.palette.common.white
    }
  },
  button: {
    marginTop: 20
  }
});

function MaterialDropZone(props) {
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptedFiles] = useState(props.acceptedFiles);
  // const [files, setFiles] = useState(props.files ? props.files : []);

  const {
    classes,
    showPreviews,
    maxSize,
    text,
    showButton,
    filesLimit,
    files,
    setFiles,
    input,
    ...rest
  } = props;

  const [imageDataUrl, setmIageDataUrl] = useState(props.files[0] ? props.files[0] : "");

  const toBlob = (dataurl) => {
    if (!dataurl) {
      return dataurl
    }
    if (dataurl && dataurl instanceof Blob) {
      return dataurl
    }
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  };

  const setImage = (images) => {
    let reader = new FileReader();
    // 画像をbase64にエンコードします.
    reader.onload = () => {
      setmIageDataUrl(reader.result)
    };
    reader.readAsDataURL(images[0]);
  }

  useEffect(() => {
    input.onChange(imageDataUrl)
  },[imageDataUrl])

  const onDrop = useCallback((filesVal) => {
    let oldFiles = files;
    const filesLimitVal = filesLimit || '3';
    oldFiles = oldFiles.concat(filesVal);
    if (oldFiles.length > filesLimit) {
      setOpenSnackbar(true);
      setErrorMessage(`${filesLimitVal}枚以上の画像をアップロードすることはできません`);
    } else {
      setFiles(oldFiles);
      setImage(filesVal);
    }
  }, [files, filesLimit]);

  const onDropRejected = () => {
    setOpenSnackbar(true);
    setErrorMessage('画像サイズが大きすぎます。3MB以下でアップロードをしてください。');
  };

  const handleRequestCloseSnackBar = () => {
    setOpenSnackbar(false);
  };

  const handleRemove = useCallback((file, fileIndex) => {
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    setFiles(thisFiles => {
      const tempFiles = [...thisFiles];
      tempFiles.splice(fileIndex, 1);
      return tempFiles;
    });
    input.onChange("")
  }, [files]);

  const fileSizeLimit = maxSize || 3000000;
  const DeleteBtn = ({ file, index }) => (
    <div className="middle">
      <IconButton onClick={() => handleRemove(file, index)}>
        <ActionDelete className="removeBtn" />
      </IconButton>
    </div>
  );

  DeleteBtn.propTypes = {
    file: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
  };

  const Previews = ({ filesArray }) => filesArray.map((file, index) => {
    const blobFile = toBlob(file)
    const base64Img = URL.createObjectURL(blobFile);
    if (isImage(blobFile)) {
      return (
        <div key={index.toString()}>
          <div className="imageContainer height100 col fileIconImg">
            <figure className="imgWrap"><img className="smallPreviewImg height92" src={base64Img} alt="preview" /></figure>
            <DeleteBtn file={blobFile} index={index} />
          </div>
        </div>
      );
    }
    return (
      <div key={index.toString()}>
        <div className="imageContainer height100 col fileIconImg">
          <FileIcon className="smallPreviewImg height92" alt="preview" />
          <DeleteBtn file={blobFile} index={index} />
        </div>
      </div>
    );
  });

  Previews.propTypes = {
    filesArray: PropTypes.array.isRequired
  };

  let dropzoneRef;
  return (
    <div>
      {files[(filesLimit - 1)] ? "" :
        <Dropzone
          accept={acceptedFiles.join(',')}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
          acceptClassName="stripes"
          rejectClassName="rejectStripes"
          maxSize={fileSizeLimit}
          ref={(node) => { dropzoneRef = node; }}
          {...rest}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classNames(classes.dropItem, 'dropZone')}>
              <div className="dropzoneTextStyle">
                <input {...getInputProps()} />
                <p className="dropzoneParagraph">{text}</p>
                <div className={classes.uploadIconSize}>
                  <CloudUpload />
                </div>
              </div>
            </div>
          )}
          {/* end */}
        </Dropzone>
      }
      <div className="row preview">
        {showPreviews && <Previews filesArray={files} />}
      </div>
      <Snackbar
        open={openSnackBar}
        message={errorMessage}
        autoHideDuration={4000}
        onClose={handleRequestCloseSnackBar}
      />
    </div>
  );
}

MaterialDropZone.propTypes = {
  files: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.array,
  showPreviews: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
  maxSize: PropTypes.number.isRequired,
  filesLimit: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

MaterialDropZone.defaultProps = {
  acceptedFiles: [],
  showButton: false,
};

export default withStyles(styles)(MaterialDropZone);
