import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FieldArray, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import BackDialog from '../../../components/Dialog/BackDialog';
import renderFormItems from './renderFormItems';

function ItemForm(props) {

  const [status, setStatus] = useState("");

  const {
    classes,
    handleSubmit,
    id,
    formValues,
    synchronousError,
    formItemData
  } = props;

  const accessToken = props.getAccessToken;

  return (
    <section className={classes.formWrap}>
      <form onSubmit={handleSubmit}>
        <FieldArray name="form_items_attributes" component={renderFormItems} accessToken={accessToken} display={true} syncErrors={synchronousError} formValues={formValues} isNormalPayment={formItemData.payment_form_type === "normal_payment"} isCvs={formItemData.is_cvs}/>
        <div className={classes.btnArea}>
          <Grid container justify="center">
            {/* <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/payment_form/index">
              戻る
            </Button> */}
            <BackDialog variant="outlined" color="primary" size="large" className={classes.button} message="戻る" url={"/admin/payment_form/" + id}/>
            <Button variant="contained" color="primary" size="large" type="submit">
              更新
            </Button>
          </Grid>
        </div>
      </form>
    </section>
  );
}

ItemForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const ItemFormReduxed = reduxForm({
  form: 'paymentFormItemForm',
  enableReinitialize: true
})(ItemForm);

const reducer = 'ui';
const ItemFormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    formValues: getFormValues("paymentFormItemForm")(state),
    synchronousError : getFormSyncErrors("paymentFormItemForm")(state)
  }),
)(ItemFormReduxed);

export default withStyles(styles)(ItemFormReduxedMapped);
