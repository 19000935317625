import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchShowPaymentFormAction, onFetchCreatePaymentFormAction, onFetchUpdatePaymentFormAction, onFetchDeletePaymentFormAction, onFetchIndexPaymentFormAction } from "../../../redux/actions/paymentForm";
import { onFetchShowCompanyAction } from "../../../redux/actions/company";
import accessToken from '../../../utils/accessToken';

function Edit(props) {
  const description = brand.desc;
  const { match } = props;
  const [loading, setLoading] = useState(true);

  const [isNew, setIsNew] = useState(false);
  const [titleName, setTitleName] = useState("");
  
  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const [paymentFormData, setPaymentFormData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const formId = match.params.id

  const url = '/admin/payment_form/index'

  const getAccessToken = accessToken(props);

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const resetLoading = () => {
    setLoading(true)
    setLoading(false)
  }

  const updatePaymentServicesAttributes = dataValues => {
    return dataValues.set("payment_services_attributes", dataValues.get("payment_services_attributes").map(updatePaymentPlansAttributes));
  }

  const updatePaymentPlansAttributes = values => {
    return values.set("payment_plans_attributes", values.get("payment_plans_attributes").map(updatePosition));
  }

  const updateDataValues = dataValues => {
    return dataValues.set("form_items_attributes", dataValues.get("form_items_attributes").map(updateFormItemAttributes));
  }

  const updateFormItemAttributes = values => {
    return values.set("form_item_selects_attributes", values.get("form_item_selects_attributes").map(updateFormItemSelectsAttributes));
  }

  const updateFormItemSelectsAttributes = values => {
    const formItemsAttributes = values.get("form_items_attributes");
    if (formItemsAttributes) {
      return values.set("form_items_attributes", formItemsAttributes.map(updatePosition));
    } else {
      return values;
    } 
  }

  const updatePosition = (values, index) => {
    return values.set('position', index + 1);
  }

  const [indexPaymentFormData, setIndexPaymentFormData] = useState([]);

  // 詳細取得
  useEffect(() => {
    let url = props.path.split('/')
    fetchCompany()
    if (url[url.length - 1] === 'new') {
      setIsNew(true)
      setTitleName("決済フォーム登録")
      fetchIndexPaymentForm()
      setLoading(false)
    } else {
      fetchForm()
      setTitleName("決済フォーム詳細")
    }
  }, []);

  const fetchIndexPaymentForm = company_id => {
    let data = {}
    if (company_id){
      data.company_id = company_id
    }
    data.page = 'all'
    props.onFetchIndexPaymentForm({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        const filterItem = item.data.filter(item => item.type == "sky-rocket-model-subscription-payment-forms")
        setIndexPaymentFormData(filterItem)
        item.data.length == 0 ? displayNotification("決済フォームが取得できませんでした。", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  const fetchCompany = (company_id = props.internalValues.companyId) => {
    props.onFetchShowCompany({
      accessToken: accessToken(props),
      data: {id: company_id},
      onSuccess: async data => {
        setCompanyData(data)
      },
      onErrorWithNotice: () => {
        displayNotification("加盟店情報を取得できませんでした")
      }
    })  
  };

  const fetchForm = values => {
    props.onFetchShowPaymentForm({
      accessToken: accessToken(props),
      data: {id: formId},
      onSuccess: async data => {
        if (data.image_url) {
          // 画像URLを元にblobを作成する
          data["image"] = await fetch(data.image_url, { mode: 'cors'}).then(r => r.blob())
          setPaymentFormData(data)
          resetLoading()
        }
        // if (data.payment_form_type === "subscription_payment") {
        //   data.payment_services_attributes.forEach( async (plan, index) => {
        //     if (plan["image_url"]) {
        //       // 画像URLを元にblobを作成する
        //       data.payment_services_attributes[index]["image"] = await fetch(plan["image_url"], { mode: 'cors'}).then(r => r.blob())
        //       setPaymentFormData(data)
        //       resetLoading()
        //     }
        //   });
        // }
        setPaymentFormData(data)
      },
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 登録
  const newForm = values => {
    let dataValues = values
    if (props.internalValues.role === "company") {
      dataValues = dataValues.set("company_id", props.internalValues.companyId)
    }
    // TODO 決済手段変更できるようになり次第コメント解除
    if (["normal_payment", "subscription_payment"].includes(dataValues.get("payment_form_type"))) {
      // 並び順(position)を設定
      dataValues = dataValues.set("payment_services_attributes", dataValues.get("payment_services_attributes").map((v, k) => v.set('position', k + 1)))
      dataValues = updatePaymentServicesAttributes(dataValues);
      dataValues = dataValues.delete("price")
    } else {
      dataValues = dataValues.delete("payment_services_attributes")
      dataValues = dataValues.delete("plan_change_available")
    }
    if ((dataValues.get("payment_form_type") === "subscription_payment" && dataValues.get("sbsc_payment_method") === "custom") || dataValues.get("payment_form_type") === "transfer_payment") {
      dataValues = dataValues.delete("spsv_free_2")
      dataValues = dataValues.delete("spsv_free_3")
    }
    if (dataValues.get("payment_form_type") !== "normal_payment") {
      dataValues = dataValues.delete("image")
      dataValues = dataValues.delete("split_available")
      dataValues = dataValues.delete("is_cvs")
    }
    if (dataValues.get("payment_form_type") !== "subscription_payment") {
      dataValues = dataValues.delete("has_initial_price")
      dataValues = dataValues.delete("initial_price")
      dataValues = dataValues.delete("initial_price_description")
    }
    if (dataValues.get("payment_form_type") !== "face_auth_payment") {
      dataValues = dataValues.delete("is_price?")
    }
    // 並び順(position)を設定
    dataValues = dataValues.set("form_items_attributes", dataValues.get("form_items_attributes").map((v, k) => v.set('position', k + 1)))
    dataValues = updateDataValues(dataValues);
    props.onFetchCreatePaymentForm({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "登録が完了しました", variant: 'success', createdFormUrl: data.form_url});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 更新
  const editForm = (values) => {
    let dataValues = values
    // 並び順(position)を設定
    if (["normal_payment", "subscription_payment"].includes(dataValues.get("payment_form_type"))) {
      dataValues = dataValues.set("payment_services_attributes", dataValues.get("payment_services_attributes").map((v, k) => v.set('position', k + 1)))
      dataValues = updatePaymentServicesAttributes(dataValues);
    }
    if (!dataValues.get("image")) {
      dataValues = dataValues.set("image", null)
    }
    dataValues = dataValues.delete("form_items_attributes")
    dataValues.id = formId
    props.onFetchUpdatePaymentForm({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        fetchForm()
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 削除
  const deleteForm = values => {
    props.onFetchDeletePaymentForm({
      accessToken: accessToken(props),
      data: {id: formId},
      onSuccess: async data => {
        props.history.push({ pathname: '/admin/payment_form/index', message: '決済フォームを削除しました', variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };
  
  return (
    <div>
      <Helmet>
        <title>{brand.name + " - " + titleName}</title>
      </Helmet>
        
          <div>
          {/* 決済フォーム情報取得Apiが終了するまでは、空のフォームを表示させる */}
          {loading || props.loadingPaymentFormShow ? <Form fetchIndexPaymentForm={fetchIndexPaymentForm}/> : <Form onSubmit={(values) => isNew ? newForm(values) : editForm(values)} deleteForm={deleteForm} initialValues={isNew ? {split_available: "not_able"} : paymentFormData} setPaymentFormData={setPaymentFormData} formId={formId} isNew={isNew} getAccessToken={getAccessToken} indexPaymentFormData={indexPaymentFormData} fetchIndexPaymentForm={fetchIndexPaymentForm} fetchCompany={fetchCompany} companyData={companyData}/>}
          </div>
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    paymentFormData: state.getIn(['paymentFormReducers', 'paymentFormData']),
    loadingPaymentFormShow: state.getIn(['paymentFormReducers', 'loadingPaymentFormShow']),
    loadingPaymentFormData: state.getIn(['paymentFormReducers', 'loadingPaymentFormData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShowPaymentForm: data => {
      dispatch(onFetchShowPaymentFormAction(data));
    },
    onFetchCreatePaymentForm: data => {
      dispatch(onFetchCreatePaymentFormAction(data));
    },
    onFetchUpdatePaymentForm: data => {
      dispatch(onFetchUpdatePaymentFormAction(data));
    },
    onFetchDeletePaymentForm: data => {
      dispatch(onFetchDeletePaymentFormAction(data));
    },
    onFetchIndexPaymentForm: data => {
      dispatch(onFetchIndexPaymentFormAction(data));
    },
    onFetchShowCompany: data => {
      dispatch(onFetchShowCompanyAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(FormInit));