import {qaInformationAction} from "../constants/actionTypes";

export const onFetchCreateQaInformationAction = (data) => {
  return {
    type: qaInformationAction.ON_FETCH_CREATE_QA_INFORMATION,
    data
  }
};

export const onFetchIndexQaInformationAction = (data) => {
  return {
    type: qaInformationAction.ON_FETCH_INDEX_QA_INFORMATION,
    data
  }
};

export const onFetchShowQaInformationAction = (data) => {
  return {
    type: qaInformationAction.ON_FETCH_SHOW_QA_INFORMATION,
    data
  }
};

export const onFetchUpdateQaInformationAction = (data) => {
  return {
    type: qaInformationAction.ON_FETCH_UPDATE_QA_INFORMATION,
    data
  }
};

export const onFetchDeleteQaInformationAction = (data) => {
  return {
    type: qaInformationAction.ON_FETCH_DELETE_QA_INFORMATION,
    data
  }
};

export const clearQaInformationAction = (data) => {
  return {
    type: qaInformationAction.CLEAR_QA_INFORMATION,
    data
  }
};