import "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import React from 'react';
import ReactDOM from 'react-dom';
import SkyRocketAdmin from '../app/SkyRocketAdmin';

ActiveStorage.start();
ReactDOM.render(
  <SkyRocketAdmin/>,
  document.getElementById('app')
);
