import {adminUserAction} from "../constants/actionTypes";

export const onFetchCreateAdminUserAction = (data) => {
  return {
    type: adminUserAction.ON_FETCH_CREATE_ADMIN_USER,
    data
  }
};

export const onFetchIndexAdminUserAction = (data) => {
  return {
    type: adminUserAction.ON_FETCH_INDEX_ADMIN_USER,
    data
  }
};

export const onFetchShowAdminUserAction = (data) => {
  return {
    type: adminUserAction.ON_FETCH_SHOW_ADMIN_USER,
    data
  }
};

export const onFetchUpdateAdminUserAction = (data) => {
  return {
    type: adminUserAction.ON_FETCH_UPDATE_ADMIN_USER,
    data
  }
};

export const onFetchDeleteAdminUserAction = (data) => {
  return {
    type: adminUserAction.ON_FETCH_DELETE_ADMIN_USER,
    data
  }
};

export const clearAdminUserAction = (data) => {
  return {
    type: adminUserAction.CLEAR_ADMIN_USER,
    data
  }
};