export const DEFAULT_STATE_INTERNAL_REDUCER = {
  companyId: 'samurai',
  tel: '',
  accessToken: '',
  login: '',
  role: '',
  password: '',
  birthday: '',
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: ''
};