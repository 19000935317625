import {all} from 'redux-saga/effects';
import {watchOnFetchAccount} from "./accountSaga";
import {watchOnFetchUser} from "./userSaga";
import {watchOnFetchPaymentHistory} from "./paymentHistorySaga";
import {watchOnFetchPaymentForm} from "./paymentFormSaga";
import {watchOnFetchMailPayment} from "./mailPaymentSaga";
import {watchOnFetchMailPaymentRecipient} from "./mailPaymentRecipientSaga";
import {watchOnFetchBulkPayment} from "./bulkPaymentSaga";
import {watchOnFetchBillingStatement} from "./billingStatementSaga";
import {watchOnFetchAnalysisPeriod} from "./analysisPeriodSaga";
import {watchOnFetchAdminUser} from "./adminUserSaga";
import {watchOnFetchCompany} from "./companySaga";
import {watchOnFetchQaInformation} from "./qaInformationSaga";
import {watchOnFetchQaCategory} from "./qaCategorySaga";
import { watchOnFetchExportLog } from "./exportLogSaga";

export default function* rootSaga() {
  yield all([
    watchOnFetchAccount(),
    watchOnFetchUser(),
    watchOnFetchPaymentHistory(),
    watchOnFetchPaymentForm(),
    watchOnFetchMailPayment(),
    watchOnFetchMailPaymentRecipient(),
    watchOnFetchBulkPayment(),
    watchOnFetchAdminUser(),
    watchOnFetchCompany(),
    watchOnFetchQaInformation(),
    watchOnFetchQaCategory(),
    watchOnFetchBillingStatement(),
    watchOnFetchAnalysisPeriod(),
    watchOnFetchExportLog(),
  ])
}
