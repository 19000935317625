import {adminUserAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreateAdminUserAction(action) {
  const result = yield Api.postCreateAdminUser(action.data);
  yield put({type: adminUserAction.END_FETCH_CREATE_ADMIN_USER, result: result});
}

function* onFetchIndexAdminUserAction(action) {
  const result = yield Api.getIndexAdminUser(action.data);
  yield put({type: adminUserAction.END_FETCH_INDEX_ADMIN_USER, result: result});
}

function* onFetchShowAdminUserAction(action) {
  const result = yield Api.getShowAdminUser(action.data);
  yield put({type: adminUserAction.END_FETCH_SHOW_ADMIN_USER, result: result});
}

function* onFetchUpdateAdminUserAction(action) {
  const result = yield Api.putUpdateAdminUser(action.data);
  yield put({type: adminUserAction.END_FETCH_UPDATE_ADMIN_USER, result: result});
}

function* onFetchDeleteAdminUserAction(action) {
  const result = yield Api.deleteDeleteAdminUser(action.data);
  yield put({type: adminUserAction.END_FETCH_DELETE_ADMIN_USER, result: result});
}

export function* watchOnFetchAdminUser() {
  yield takeLatest(adminUserAction.ON_FETCH_CREATE_ADMIN_USER, onFetchCreateAdminUserAction);
  yield takeLatest(adminUserAction.ON_FETCH_INDEX_ADMIN_USER, onFetchIndexAdminUserAction);
  yield takeLatest(adminUserAction.ON_FETCH_SHOW_ADMIN_USER, onFetchShowAdminUserAction);
  yield takeLatest(adminUserAction.ON_FETCH_UPDATE_ADMIN_USER, onFetchUpdateAdminUserAction);
  yield takeLatest(adminUserAction.ON_FETCH_DELETE_ADMIN_USER, onFetchDeleteAdminUserAction);
}
