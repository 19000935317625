import { exportLogAction } from "../../constants/actionTypes";
import { put, takeLatest } from "redux-saga/effects";
import { Api } from "./api";

function* onFetchIndexExportLogAction(action) {
  const result = yield Api.getIndexExportLog(action.data);
  yield put({
    type: exportLogAction.END_FETCH_INDEX_EXPORT_LOG,
    result: result
  });
}

function* onFetchDeleteExportLogAction(action) {
  const result = yield Api.deleteDeleteExportLog(action.data);
  yield put({
    type: exportLogAction.END_FETCH_DELETE_EXPORT_LOG,
    result: result
  });
}

function* onFetchDownloadExportLogAction(action) {
  const result = yield Api.getDownloadExportLog(action.data);
  yield put({
    type: exportLogAction.END_FETCH_DOWNLOAD_EXPORT_LOG,
    result: result
  });
}

export function* watchOnFetchExportLog() {
  yield takeLatest(
    exportLogAction.ON_FETCH_INDEX_EXPORT_LOG,
    onFetchIndexExportLogAction
  );
  yield takeLatest(
    exportLogAction.ON_FETCH_DELETE_EXPORT_LOG,
    onFetchDeleteExportLogAction
  );
  yield takeLatest(
    exportLogAction.ON_FETCH_DOWNLOAD_EXPORT_LOG,
    onFetchDownloadExportLogAction
  );
}
