import React, { useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FileIcon from '@material-ui/icons/Description';
import ActionDelete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloudUpload from '@material-ui/icons/CloudUpload';
import 'dan-styles/vendors/react-dropzone/react-dropzone.css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  dropItem: {
    borderColor: theme.palette.divider,
    background: theme.palette.background.default,
    borderRadius: theme.rounded.medium,
    color: theme.palette.text.disabled,
    textAlign: 'center'
  },
  uploadIconSize: {
    display: 'inline-block',
    '& svg': {
      width: 72,
      height: 72,
      fill: theme.palette.secondary.main,
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      fill: theme.palette.common.white
    }
  },
  button: {
    marginTop: 20
  }
});

function CsvDropZone(props) {
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [files, setFiles] = useState(props.files); // eslint-disable-line
  const [acceptedFiles] = useState(props.acceptedFiles); // eslint-disable-line

  const {
    classes,
    showPreviews,
    maxSize,
    text,
    showButton,
    filesLimit,
    ...rest
  } = props;

  const onDrop = useCallback((filesVal) => {
    let oldFiles = files;
    const filesLimitVal = filesLimit || '3';
    oldFiles = oldFiles.concat(filesVal);
    if (oldFiles.length > filesLimit) {
      setOpenSnackbar(true);
      setErrorMessage(`Cannot upload more than ${filesLimitVal} items.`);
    } else {
      setFiles(oldFiles);
      const selectedFile = oldFiles[0]; // 1つのファイルを選択した場合
      if (selectedFile) {
        props.input.onChange(selectedFile); // Redux Form の値を更新
      }
    }
  }, [files, filesLimit]);

  const onDropRejected = () => {
    setOpenSnackbar(true);
    setErrorMessage(`ファイルサイズが大きすぎます。 ${fileSizeLimit}以下でアップロードをしてください。`);
  };

  const handleRequestCloseSnackBar = () => {
    setOpenSnackbar(false);
  };

  const handleRemove = useCallback((file, fileIndex) => {
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    setFiles(thisFiles => {
      const tempFiles = [...thisFiles];
      tempFiles.splice(fileIndex, 1);
      return tempFiles;
    });

    props.input.onChange('')
  }, [files]);

  const fileSizeLimit = maxSize || 10000000;
  const DeleteBtn = ({ file, index }) => (
    <div className="middle">
      <IconButton onClick={() => handleRemove(file, index)}>
        <ActionDelete className="removeBtn" />
      </IconButton>
    </div>
  );

  DeleteBtn.propTypes = {
    file: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
  };

  const Previews = ({ filesArray }) => filesArray.map((file, index) => {
    const base64Img = URL.createObjectURL(file);
    const fileName = file.name;
    return (
      <>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} sm={12}>
          <Typography style={{ textAlign: 'center' }}>{fileName}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <div key={index.toString()}>
            <div className="imageContainer height100 col fileIconImg">
              <FileIcon className="smallPreviewImg height92" alt="preview" />
              <DeleteBtn file={file} index={index} />
            </div>
          </div>
        </Grid>
      </Grid>
      </>
    );
  });

  Previews.propTypes = {
    filesArray: PropTypes.array.isRequired
  };

  let dropzoneRef;
  return (
    <div>
      { files.length < filesLimit &&
        <>
          <Dropzone
            accept={acceptedFiles.join(',')}
            onDrop={onDrop}
            onDropRejected={onDropRejected}
            acceptClassName="stripes"
            rejectClassName="rejectStripes"
            maxSize={fileSizeLimit}
            ref={(node) => { dropzoneRef = node; }}
            {...rest}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={classNames(classes.dropItem, 'dropZone')}>
                <div className="dropzoneTextStyle">
                  <input {...getInputProps()} />
                  <p className="dropzoneParagraph">{text}</p>
                  <div className={classes.uploadIconSize}>
                    <CloudUpload />
                  </div>
                </div>
              </div>
            )}
            {/* end */}
          </Dropzone>
        </>
      }
      {showButton && (
        <Button
          className={classes.button}
          fullWidth
          variant="contained"
          onClick={() => {
            dropzoneRef.open();
          }}
          color="secondary"
        >
          Click to upload file(s)
          <span className={classes.rightIcon}>
            <CloudUpload />
          </span>
        </Button>
      )}
      <div className="row preview">
        {showPreviews && <Previews filesArray={files} />}
      </div>
      <Snackbar
        open={openSnackBar}
        message={errorMessage}
        autoHideDuration={4000}
        onClose={handleRequestCloseSnackBar}
      />
    </div>
  );
}

CsvDropZone.propTypes = {
  files: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.array,
  showPreviews: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
  maxSize: PropTypes.number.isRequired,
  filesLimit: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

CsvDropZone.defaultProps = {
  acceptedFiles: [],
  showButton: false,
};

export default withStyles(styles)(CsvDropZone);