import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import List from '../../../components/Lists/List';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchIndexQaCategoryAction } from '../../../redux/actions/qaCategory';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import {enumToJa} from '../../../utils/format';
import Loading from '../../../components/Loading/index'
import NotFound from '../../NotFound/NotFound';
import SearchForm from '../../../components/Forms/SearchForm';

function Index(props) {
  const { classes } = props;
  const title = brand.name + ' - QAカテゴリ一覧';
  const description = brand.desc;
  const url = '/admin/qa_category/'
  const internalValues = props.internalValues;
  const [qaCategoryData, setQaCategoryData] = useState("");
  const [loading, setLoading] = useState(true);
  // メッセージ関連
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }
  const [searchItems, setSearchItems] = useState(
    [
      { label: "カテゴリ名", name: "name", value: "", formType: "text" },
      { label: "ステータス", name: "status", value: "", formType: "select", menuItems: [{key: '有効', value: '1'}, {key: '無効', value: '2'}]},
    ]
  )
  const [listItems, setlistItems] = useState(
    [
      { label: "並び替え" , name: 'position'},
      { label: "QAカテゴリ名" , name: 'name'},
      { label: "ステータス" , name: 'status', format: (value)=>{return enumToJa(value['status'])}},
    ]
  )

  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    data.page = page
    props.onFetchIndexQaCategory({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setQaCategoryData(item)
        item.data.length == 0 ? displayNotification("QAカテゴリ情報がありませんでした", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  }; 

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  return (
    <React.Fragment>
      {internalValues.role === 'company' ? <NotFound /> :
        <div>
          <Helmet>
          <title>{title}</title>
          </Helmet>
          {<PapperBlock title="QAカテゴリ検索" whiteBg icon="ios-search-outline" desc="">
              <section className={classes.formWrap}>
                <Grid container spacing={3}>
                  { searchItems.map( (item, index) => {
                    return(
                      <Grid key={index} item xs={12} sm={6}>
                        <SearchForm searchItem={item} key={index} id={index} changeValue={changeValue} role={props.internalValues.role} />
                      </Grid>
                    )}
                    )}
                </Grid>
              </section>
                <div className={classes.btnArea}>
                  <Button variant="contained" color="primary" size="large" onClick={submitForm}>
                    検索
                  </Button>
                </div>
            </PapperBlock>
          }
          {/*検索を開始するまでは一覧は表示しない
            検索を開始し、管理者一覧APIが終了するまではローディング画面
            管理者一覧APIが終了し、データが格納されている場合は一覧を表示
            管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない*/}
          {loading || qaCategoryData.data.length === 0 ? "" :
            <PapperBlock title="QAカテゴリ一覧" whiteBg icon="ios-list"desc="">
              { props.loadingQaCategoryIndex ? 
                <div><List listItems={listItems} searchResult={qaCategoryData} url={url} submitForm={submitForm}/><Loading /></div> :
                <div><List listItems={listItems} searchResult={qaCategoryData} url={url} submitForm={submitForm}/></div>
              }
            </PapperBlock>
          }
          <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
        </div>
      }
    </React.Fragment>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingQaCategoryIndex: state.getIn(['qaCategoryReducers', 'loadingQaCategoryIndex'])
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexQaCategory: data => {
      dispatch(onFetchIndexQaCategoryAction(data));
    }
  };
};
const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
export default withStyles(styles)(withCookies(withRouter(FormInit)));