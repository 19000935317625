import { fromJS, Map, List } from 'immutable';
import MenuContent from 'dan-api/ui/menu';
import {paymentHistoryAction} from "../constants/actionTypes";

const initialState = {
  loadingPaymentHistoryIndex: false,
  loadingPaymentHistoryShow: false,
  loadingPaymentHistoryData: false,
  loadingPaymentHistoryExport: false,
  paymentHistoryData: Map()
};

const initialImmutableState = fromJS(initialState);
const paymentHistoryReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case paymentHistoryAction.ON_FETCH_INDEX_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryIndex', true);
      });
    case paymentHistoryAction.END_FETCH_INDEX_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryIndex', false);
      });
    case paymentHistoryAction.ON_FETCH_SHOW_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryShow', true);
      });
    case paymentHistoryAction.END_FETCH_SHOW_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryShow', false);
        mutableState.set('paymentHistoryData', action.result);
      });
    case paymentHistoryAction.ON_FETCH_UPDATE_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryData', true);
      });
    case paymentHistoryAction.END_FETCH_UPDATE_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryData', false);
      });
    case paymentHistoryAction.ON_FETCH_EXPORT_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryExport', true);
      });
    case paymentHistoryAction.END_FETCH_EXPORT_PAYMENT_HISTORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentHistoryExport', false);
      });
    default:
      return state
  }
};

export default paymentHistoryReducers;