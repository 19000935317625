import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteImageDialog from '../../../components/Dialog/DeleteImageDialog';

function FormItemAnswer(props) {

  const {
    objects,
    handleDeleteImage,
    handleDownloadImage
  } = props; 

  const [dialogData, setDialogData] = useState({open: false});
  const [formItemAnswerId, setFormItemAnswerId] = useState(null);

  const Preview = props => {
    const {formItemAnswer} = props;
    return (
      <div className="imageContainer col fileIconImg">
        <figure className="imgWrap"><img className="smallPreviewImg height200" src={formItemAnswer.image_url} alt="preview" /></figure>
        <div className="middle">
          <IconButton onClick={() => handleDownloadImage(formItemAnswer.id)}>
            <GetAppIcon />
          </IconButton>
          <IconButton onClick={() => {
            setDialogData({open: true})
            setFormItemAnswerId(formItemAnswer.id)
          }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    )
  }
  
  return (
    <>
      <Grid item xs={12}>
        <Paper style={{ border: "1px solid silver", padding: 30 }} elevation={4}>
          <Typography variant="h6">
            フォーム項目回答内容
          </Typography>
          <List>
            {objects.map(obj => (
              obj.form_item_answers.map((formItemAnswer, formItemAnswerIndex) => (
                <div key={formItemAnswerIndex}>
                  <ListItem>
                    <ListItemText primary={formItemAnswer.name} />
                    {!['image'].includes(formItemAnswer.form_type) &&
                      <Typography>
                        {['select', 'nest_select'].includes(formItemAnswer.form_type)
                          ? formItemAnswer.form_item_answer_selects[0] ? formItemAnswer.form_item_answer_selects[0].value : ""
                          : formItemAnswer.answer}
                      </Typography>
                    }
                  </ListItem>
                  {['image'].includes(formItemAnswer.form_type) && formItemAnswer.image_url &&
                    <ListItem>
                      <Preview formItemAnswer={formItemAnswer} />
                    </ListItem>
                  }
                  <Divider />
                  {formItemAnswer.form_item_answers.length > 0 && formItemAnswer.form_item_answers.map((nestAnswer, nestAnswerIndex) => (
                    <div key={nestAnswerIndex}>
                      <ListItem>
                        <ListItemText primary={nestAnswer.name} />
                        {!['image'].includes(nestAnswer.form_type) &&
                          <Typography>
                            {['select'].includes(nestAnswer.form_type)
                              ? nestAnswer.form_item_answer_selects[0].value
                              : nestAnswer.answer}
                          </Typography> 
                        }
                      </ListItem>
                      {['image'].includes(nestAnswer.form_type) && nestAnswer.image_url &&
                        <ListItem>
                          <Preview formItemAnswer={nestAnswer} />
                        </ListItem>
                      }
                      <Divider />
                    </div>
                  ))}
                </div>
              ))
            ))}
          </List>
        </Paper>
      </Grid>
      <DeleteImageDialog dialogData={dialogData} deleteImage={() => handleDeleteImage(formItemAnswerId)} />
    </>
  )
}

export default withStyles(styles)(FormItemAnswer);
