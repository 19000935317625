import {paymentFormAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreatePaymentFormAction(action) {
  const result = yield Api.postCreatePaymentForm(action.data);
  yield put({type: paymentFormAction.END_FETCH_CREATE_PAYMENT_FORM, result: result});
}

function* onFetchIndexPaymentFormAction(action) {
  const result = yield Api.getIndexPaymentForm(action.data);
  yield put({type: paymentFormAction.END_FETCH_INDEX_PAYMENT_FORM, result: result});
}

function* onFetchShowPaymentFormAction(action) {
  const result = yield Api.getShowPaymentForm(action.data);
  yield put({type: paymentFormAction.END_FETCH_SHOW_PAYMENT_FORM, result: result});
}

function* onFetchUpdatePaymentFormAction(action) {
  const result = yield Api.putUpdatePaymentForm(action.data);
  yield put({type: paymentFormAction.END_FETCH_UPDATE_PAYMENT_FORM, result: result});
}

function* onFetchDeletePaymentFormAction(action) {
  const result = yield Api.deleteDeletePaymentForm(action.data);
  yield put({type: paymentFormAction.END_FETCH_DELETE_PAYMENT_FORM, result: result});
}

function* onFetchUpdatePaymentFormItemAction(action) {
  const result = yield Api.postUpdatePaymentFormItem(action.data);
  yield put({type: paymentFormAction.END_FETCH_UPDATE_PAYMENT_FORM_ITEM, result: result});
}

function* onFetchUpdatePaymentFormPlanAction(action) {
  const result = yield Api.postUpdatePaymentFormPlan(action.data);
  yield put({type: paymentFormAction.END_FETCH_UPDATE_PAYMENT_FORM_PLAN, result: result});
}

function* onFetchQrIssuePaymentFormAction(action) {
  const result = yield Api.postQrIssuePaymentForm(action.data);
  yield put({type: paymentFormAction.END_FETCH_QR_ISSUE_PAYMENT_FORM, result: result});
}

export function* watchOnFetchPaymentForm() {
  yield takeLatest(paymentFormAction.ON_FETCH_CREATE_PAYMENT_FORM, onFetchCreatePaymentFormAction);
  yield takeLatest(paymentFormAction.ON_FETCH_INDEX_PAYMENT_FORM, onFetchIndexPaymentFormAction);
  yield takeLatest(paymentFormAction.ON_FETCH_SHOW_PAYMENT_FORM, onFetchShowPaymentFormAction);
  yield takeLatest(paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM, onFetchUpdatePaymentFormAction);
  yield takeLatest(paymentFormAction.ON_FETCH_DELETE_PAYMENT_FORM, onFetchDeletePaymentFormAction);
  yield takeLatest(paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM_ITEM, onFetchUpdatePaymentFormItemAction);
  yield takeLatest(paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM_PLAN, onFetchUpdatePaymentFormPlanAction);
  yield takeLatest(paymentFormAction.ON_FETCH_QR_ISSUE_PAYMENT_FORM, onFetchQrIssuePaymentFormAction);
}
