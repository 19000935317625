import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import { TextFieldRedux, SelectRedux, DateRedux, renderRadioGroup } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import * as Validator from '../../../utils/validate';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

function ServiceForm(props) {
  const {
    classes,
    handleSubmit,
    deleteForm,
    isNew,
    isShow,
    isMyCompany
  } = props;

  const { role } = props.internalValues

  const [showEscottPassword, setShowEscottPassword] = useState(false);
  const [showSpPassword, setShowSpPassword] = useState(false);
  const [showPaymentPassword, setShowPaymentPassword] = useState(false);

  const handleClickShowPassword = (is_escott) => {
    if (is_escott) {
      setShowEscottPassword(show => !show);
    } else {
      setShowSpPassword(show => !show);
    }
  };

  const handleClickShowPaymentPassword = () => {
    setShowPaymentPassword(show => !show);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <section className={classes.formWrap}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="name"
                  component={TextFieldRedux}
                  placeholder="加盟店名"
                  label="加盟店名 *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="code"
                  component={TextFieldRedux}
                  placeholder="加盟店コード"
                  label="加盟店コード *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Field
                  name="url"
                  component={TextFieldRedux}
                  placeholder="サイトURL"
                  label="サイトURL *"
                  validate={[Validator.url, Validator.required]}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5} sm={3}>
              <FormControl className={classes.formControl}>
                <Field
                  name="zip1"
                  component={TextFieldRedux}
                  placeholder="郵便番号(3ケタ)"
                  label="郵便番号(3ケタ) *"
                  validate={[Validator.required, Validator.zip1]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={1} container justify="center" alignItems="center">
              <div>ー</div>
            </Grid>
            <Grid item xs={5} sm={3}>
              <FormControl className={classes.formControl}>
                <Field
                  name="zip2"
                  component={TextFieldRedux}
                  placeholder="郵便番号(4ケタ)"
                  label="郵便番号(4ケタ) *"
                  validate={[Validator.required, Validator.zip2]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="pref"
                  label="都道府県 *"
                  fullWidth
                  component={SelectRedux}
                  validate={[Validator.required]}
                >
                  <MenuItem value="北海道">北海道</MenuItem>
                  <MenuItem value="青森県">青森県</MenuItem>
                  <MenuItem value="岩手県">岩手県</MenuItem>
                  <MenuItem value="宮城県">宮城県</MenuItem>
                  <MenuItem value="秋田県">秋田県</MenuItem>
                  <MenuItem value="山形県">山形県</MenuItem>
                  <MenuItem value="福島県">福島県</MenuItem>
                  <MenuItem value="茨城県">茨城県</MenuItem>
                  <MenuItem value="栃木県">栃木県</MenuItem>
                  <MenuItem value="群馬県">群馬県</MenuItem>
                  <MenuItem value="埼玉県">埼玉県</MenuItem>
                  <MenuItem value="千葉県">千葉県</MenuItem>
                  <MenuItem value="東京都">東京都</MenuItem>
                  <MenuItem value="神奈川県">神奈川県</MenuItem>
                  <MenuItem value="新潟県">新潟県</MenuItem>
                  <MenuItem value="富山県">富山県</MenuItem>
                  <MenuItem value="石川県">石川県</MenuItem>
                  <MenuItem value="福井県">福井県</MenuItem>
                  <MenuItem value="山梨県">山梨県</MenuItem>
                  <MenuItem value="長野県">長野県</MenuItem>
                  <MenuItem value="岐阜県">岐阜県</MenuItem>
                  <MenuItem value="静岡県">静岡県</MenuItem>
                  <MenuItem value="愛知県">愛知県</MenuItem>
                  <MenuItem value="三重県">三重県</MenuItem>
                  <MenuItem value="滋賀県">滋賀県</MenuItem>
                  <MenuItem value="京都府">京都府</MenuItem>
                  <MenuItem value="大阪府">大阪府</MenuItem>
                  <MenuItem value="兵庫県">兵庫県</MenuItem>
                  <MenuItem value="奈良県">奈良県</MenuItem>
                  <MenuItem value="和歌山県">和歌山県</MenuItem>
                  <MenuItem value="鳥取県">鳥取県</MenuItem>
                  <MenuItem value="島根県">島根県</MenuItem>
                  <MenuItem value="岡山県">岡山県</MenuItem>
                  <MenuItem value="広島県">広島県</MenuItem>
                  <MenuItem value="山口県">山口県</MenuItem>
                  <MenuItem value="徳島県">徳島県</MenuItem>
                  <MenuItem value="香川県">香川県</MenuItem>
                  <MenuItem value="愛媛県">愛媛県</MenuItem>
                  <MenuItem value="高知県">高知県</MenuItem>
                  <MenuItem value="福岡県">福岡県</MenuItem>
                  <MenuItem value="佐賀県">佐賀県</MenuItem>
                  <MenuItem value="長崎県">長崎県</MenuItem>
                  <MenuItem value="熊本県">熊本県</MenuItem>
                  <MenuItem value="大分県">大分県</MenuItem>
                  <MenuItem value="宮崎県">宮崎県</MenuItem>
                  <MenuItem value="鹿児島県">鹿児島県</MenuItem>
                  <MenuItem value="沖縄県">沖縄県</MenuItem>
                </Field>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="city"
                  component={TextFieldRedux}
                  placeholder="市区町村"
                  label="市区町村 *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="street"
                  component={TextFieldRedux}
                  placeholder="番地"
                  label="番地 *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="street2"
                  component={TextFieldRedux}
                  placeholder="建物名"
                  label="建物名"
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="contract_period_from"
                  component={DateRedux}
                  placeholder="契約開始日"
                  label="契約開始日 *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="contract_period_to"
                  component={DateRedux}
                  placeholder="契約終了日"
                  label="契約終了日 *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            {role === 'super' && (
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="plan"
                    label="プラン *"
                    fullWidth
                    component={SelectRedux}
                    validate={[Validator.required]}
                  >
                    <MenuItem value='light'>ライト</MenuItem>
                    <MenuItem value='standard'>スタンダード</MenuItem>
                    <MenuItem value='premium'>プレミアム</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="status"
                  label="ステータス *"
                  fullWidth
                  component={SelectRedux}
                  validate={[Validator.required]}
                  >
                  <MenuItem value='active'>有効</MenuItem>
                  <MenuItem value='not_active'>無効</MenuItem>
                  <MenuItem value='temporary'>仮登録</MenuItem>
                </Field>
              </FormControl>
            </Grid>
            {/* TODO LINE決済実装時追加 */}
            {/* <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="line_code"
                  component={TextFieldRedux}
                  placeholder="LINEチャンネルコード"
                  label="LINEチャンネルコード"
                  required
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="line_token"
                  component={TextFieldRedux}
                  placeholder="LINEチャンネルトークン"
                  label="LINEチャンネルトークン"
                  required
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="sps_code"
                  component={TextFieldRedux}
                  placeholder="ソニーペイメントコード"
                  label="ソニーペイメントコード *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="sps_password"
                  type={showSpPassword ? 'text' : 'password'}
                  component={TextFieldRedux}
                  placeholder="ソニーペイメントパスワード"
                  label={isNew ? "ソニーペイメントパスワード *" : "ソニーペイメントパスワード"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => handleClickShowPassword(false)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showSpPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  validate={isNew ? [Validator.required, Validator.password] : [Validator.password] }
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="sps_token"
                  component={TextFieldRedux}
                  placeholder="ソニーペイメントトークン"
                  label="ソニーペイメントトークン *"
                  validate={[Validator.required]}
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="escott_password"
                  type={showEscottPassword ? 'text' : 'password'}
                  component={TextFieldRedux}
                  placeholder="e-SCOTTパスワード"
                  label={isNew ? "e-SCOTTパスワード *" : "e-SCOTTパスワード" }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => handleClickShowPassword(true)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showEscottPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  validate={isNew ? [Validator.required, Validator.password] : [Validator.password] }
                  className={classes.field}
                />
              </FormControl>
            </Grid>
            { role !== 'super' ? '' :
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="payment_password"
                    type={showPaymentPassword ? 'text' : 'password'}
                    component={TextFieldRedux}
                    placeholder="決済パスワード"
                    label={isNew ? "決済パスワード *" : "決済パスワード"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() => handleClickShowPaymentPassword(true)}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPaymentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    validate={isNew ? [Validator.required, Validator.password] : [Validator.password] }
                    className={classes.field}
                    />
                </FormControl>
              </Grid>
            }
            <Grid item xs={12}>
              <FormLabel component="label" required>売上送信タイミング</FormLabel>
              <Field name="capture_kind" style={{display: "flex", flexDirection: "row"}} component={renderRadioGroup} validate={[Validator.required]}>
                <FormControlLabel value="ordered" control={<Radio />} label="注文時に請求" />
                <FormControlLabel value="shipped" control={<Radio />} label="管理画面で操作" />
              </Field>
            </Grid>
          </Grid>
        </div>
        <div className={classes.btnArea}>
          <Grid container justify="center">
            {isNew || isShow ? '' :
              <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/company/index">           
                戻る
              </Button>
            }
            <Button variant="contained" color="primary" size="large" type="submit">
              {isNew ? '登録' : '更新'}
            </Button>
          </Grid>
        </div>
        {isNew || isShow || isMyCompany ? '' :
          <div className={classes.btnArea}>
            <Grid container justify="center">
              <DeleteDialog color="secondary" className={classes.button} message="この加盟店を削除する" deleteForm={deleteForm}/>
            </Grid>
          </div>
        }
      </section>
    </form>
  );
}
ServiceForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired
};
const ServiceFormReduxed = reduxForm({
  form: 'companyForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(ServiceForm);
const reducer = 'ui';
const FormInit = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    internalValues: state.getIn(['internalReducers', 'internalValues'])
  }),
)(ServiceFormReduxed);
export default withStyles(styles)(FormInit);