import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchShowPaymentHistoryAction, onFetchUpdatePaymentHistoryAction } from "../../../redux/actions/paymentHistory";
import accessToken from '../../../utils/accessToken';

function Edit(props) {
  const title = brand.name + ' - 決済履歴詳細';
  const description = brand.desc;
  const { match } = props;
  const [loading, setLoading] = useState(true);
  
  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  } 

  // 詳細取得
  useEffect(() => {
    fetchForm()
  }, []);

  const fetchForm = values => {
    props.onFetchShowPaymentHistory({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onErrorWithNotice: error => {
        props.history.push('/admin/error');
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 更新
  const submitForm = values => {
    var dataValues = values
    dataValues.id = match.params.id
    props.onFetchUpdatePaymentHistory({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        fetchForm()
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };
  
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
          {/* 決済履歴情報取得Apiが終了するまでは、空のフォームを表示させる */}
          {loading || props.loadingPaymentHistoryShow ? <Form /> : <Form onSubmit={(values) => submitForm(values)} initialValues={props.paymentHistoryData} submitForm={submitForm}/>}
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    paymentHistoryData: state.getIn(['paymentHistoryReducers', 'paymentHistoryData']),
    loadingPaymentHistoryShow: state.getIn(['paymentHistoryReducers', 'loadingPaymentHistoryShow']),
    loadingPaymentHistoryData: state.getIn(['paymentHistoryReducers', 'loadingPaymentHistoryData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShowPaymentHistory: data => {
      dispatch(onFetchShowPaymentHistoryAction(data));
    },
    onFetchUpdatePaymentHistory: data => {
      dispatch(onFetchUpdatePaymentHistoryAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(FormInit));