import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import SearchForm from '../../../components/Forms/SearchForm';
import List from '../../../components/Lists/List';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchIndexBulkPaymentAction, onFetchDeleteBulkPaymentAction } from '../../../redux/actions/bulkPayment';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import {enumToJa, sliceText, dateTimeFormat} from '../../../utils/format';
import Loading from '../../../components/Loading/index'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

function Index(props) {
  const { classes } = props;
  const location = props.location
  const { companies } = props.internalValues

  const title = brand.name + ' - 一括決済一覧';
  const description = brand.desc;
  const url = '/admin/bulk_payment/'

  // 検索項目
  const [searchItems, setSearchItems] = useState(
    [
      // TODO 通常決済以外の決済種別が利用できるようになり次第コメント解除
      // { label: "決済方法" , name: "type" , value: "" , formType: "select", menuItems: [{key: '通常決済' , value: 'normal_payment'} , {key: '継続課金決済' , value: 'subscription_payment'}　, {key: '顔認証決済' , value: 'face_auth_payment'}]},
      { label: "決済実施日時〜", name: "payment_from", value: null, formType: "datetime" },
      { label: "〜決済実施日時", name: "payment_to", value: null, formType: "datetime" },
      { label: "一括決済名" , name: "name" , value: "" , formType: "text"},
      { label: "加盟店名", name: "company_id", value: "", formType: "select", super: true,
        menuItems: companies ? companies.map( (company) => ({key: company.name, value: company.id} )) : null
      }
    ]
  )

  const [listItems, setlistItems] = useState(
    [
      { label: "加盟店名", name: "company", super: true},
      { label: "決済実施日時", name: "payment-at", format: (value)=>{return dateTimeFormat(value['payment-at'])}},
      { label: "一括決済名", name: "name", format: (value)=>{return sliceText(value['name'])}},
      { label: "処理件数", name: "process-count", format: (value)=>{return sliceText(value['process-count'])}},
      { label: "処理結果", name: "process-result", format: (value)=>{return value['process-result']}}
    ]
  )

  // 一覧
  const [loading, setLoading] = useState(true);
  const [bulkPaymentData, setBulkPaymentData] = useState("")
  const [selectedId, setSelectedId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const deleteAction = form => {
    setSelectedId(form.id);
    setIsDeleteDialogOpen(true);
  };

  function handleClose() {
    setIsDeleteDialogOpen(false);
  }

  function handleSubmit() {
    props.onFetchDeleteBulkPayment({
      accessToken: accessToken(props),
      data: { id: selectedId },
      onSuccess: data => {
        setIsDeleteDialogOpen(false);
        displayNotification("削除しました");
        submitForm();
      },
      onErrorWithNotice: error => {
        setIsDeleteDialogOpen(false);
        displayNotification("削除できませんでした");
      }
    });
  }
  
  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    data.page = page
    props.onFetchIndexBulkPayment({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setBulkPaymentData(item)
        item.data.length == 0 ? displayNotification("検索結果がありませんでした", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // メッセージ関連
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  const jumpPaymentHistory = (formName) => {
    props.history.push({ pathname: '/admin/payment_history/index', bulkPaymentName: formName });
  };

  const [nameJumpActions, setNameJumpActions] = useState(
    [
      { label: "決済履歴", function: jumpPaymentHistory}
    ]
  )

  const actions = [
    { label: "削除", function: deleteAction },
  ]

  return (
    <div>
      <Helmet>
      <title>{title}</title>
      </Helmet>
      <PapperBlock title="一括決済検索" whiteBg icon="ios-search-outline" desc="">
        <section className={classes.formWrap}>
          <Grid container spacing={3}>
            { searchItems.map( (item, index) => {
                return(
                  <Grid key={index} item xs={12} sm={6}>
                    <SearchForm searchItem={item} key={index} id={index} changeValue={changeValue} role={props.internalValues.role}/>
                  </Grid>
                )}
              )
            }
          </Grid>
        </section>
          <div className={classes.btnArea}>
            <Button variant="contained" color="primary" size="large" onClick={submitForm}>
              検索
            </Button>
          </div>
      </PapperBlock>
      {/*検索を開始するまでは一覧は表示しない
         検索を開始し、管理者一覧APIが終了するまではローディング画面
         管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない
         管理者一覧APIが終了し、データが格納されている場合は一覧を表示*/}
      {loading || bulkPaymentData.data.length === 0 ?  "" :
        <PapperBlock title="一括決済一覧" whiteBg icon="ios-list"desc="">
          { props.loadingBulkPaymentIndex ? 
            <div>
              <List listItems={listItems} searchResult={bulkPaymentData} url={url} submitForm={submitForm} role={props.internalValues.role} is_show_hide={true} nameJumpActions={nameJumpActions} actions={actions}/><Loading /></div> 
            :
              <List listItems={listItems} searchResult={bulkPaymentData} url={url} submitForm={submitForm} role={props.internalValues.role} is_show_hide={true} nameJumpActions={nameJumpActions} actions={actions}/>
          }
          <Dialog
          open={isDeleteDialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"削除しますか?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                削除してしまうと元に戻すことは出来ません！
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                戻る
              </Button>
              <Button onClick={handleSubmit} color="primary">
                削除
              </Button>
            </DialogActions>
          </Dialog>
        </PapperBlock>
      }
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingBulkPaymentIndex: state.getIn(['bulkPaymentReducers', 'loadingBulkPaymentIndex'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexBulkPayment: data => {
      dispatch(onFetchIndexBulkPaymentAction(data));
    },
    onFetchDeleteBulkPayment: data => {
      dispatch(onFetchDeleteBulkPaymentAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default withStyles(styles)(withCookies(withRouter(FormInit)));