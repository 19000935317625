import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux , renderRadioGroup, DateRedux, CheckboxRedux} from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import * as Validator from '../../../utils/validate'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

function Form(props) {
  const {
    classes,
    handleSubmit,
    deleteForm,
    url,
    formValues,
    action,
    adminUserData
  } = props;

  const { login, role, companies, browsingRight } = props.internalValues

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <section className={classes.formWrap}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="last_name"
                label="姓 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required]}
                className={classes.field}
                type={browsingRight == 1 || (browsingRight != 1 && login == adminUserData.login) || action ? 'text' : 'password'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="first_name"
                label="名 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required]}
                className={classes.field}
                type={browsingRight == 1 || (browsingRight != 1 && login == adminUserData.login) || action ? 'text' : 'password'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="last_name_kana"
                label="セイ"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.kana]}
                className={classes.field}
                type={browsingRight == 1 || (browsingRight != 1 && login == adminUserData.login) || action ? 'text' : 'password'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="first_name_kana"
                label="メイ"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.kana]}
                className={classes.field}
                type={browsingRight == 1 || (browsingRight != 1 && login == adminUserData.login) || action ? 'text' : 'password'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="status"
                label="ステータス *"
                fullWidth
                component={SelectRedux}
                validate={[Validator.required]}
                >
                <MenuItem value="approved">有効</MenuItem>
                <MenuItem value="not_approve">無効</MenuItem>
                <MenuItem value="temporary">仮登録</MenuItem>
              </Field>
            </FormControl>
          </Grid>
          {role!=='super' ? '' :
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <Field
                  name="company_id"
                  label="加盟店名 *"
                  fullWidth
                  component={SelectRedux}
                  validate={[Validator.required]}
                >
                  {companies.map( (company, key) => (<MenuItem value={company.id} key={key}>{company.name}</MenuItem>) )}
                </Field>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="email"
                label="メールアドレス *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required, Validator.email]}
                className={classes.field}
                type={browsingRight == 1 || (browsingRight != 1 && login == adminUserData.login) || action ? 'text' : 'password'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="login"
                label="ログインID *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required, Validator.login]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          { !action &&
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Field
                  name="current_password"
                  label="現在のパスワード"
                  fullWidth
                  component={TextFieldRedux}
                  validate={formValues && formValues.get("password") && formValues.get("password").length > 0 ? [Validator.required] : [] }
                  className={classes.field}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Grid>
          }
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="password"
                label={action ? "パスワード *" : "変更したいパスワード"}
                fullWidth
                component={TextFieldRedux}
                validate={action ? [Validator.required, Validator.password] : [Validator.password] }
                className={classes.field}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>
          {action ? '' :
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="created_at"
                    component={DateRedux}
                    placeholder="登録日"
                    label="登録日"
                    className={classes.field}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="last_sign_in_at"
                    component={DateRedux}
                    placeholder="最終ログイン日時"
                    label="最終ログイン日時"
                    className={classes.field}
                    isTime={true}
                    disabled
                  />
                </FormControl>
              </Grid>
            </React.Fragment>
          }
          {role!=='super' ? '' :
            <Grid item xs={12}>
              <FormLabel component="label" required>アカウント権限</FormLabel>
              <Field
                name="role"
                style={{display: "flex", flexDirection: "row"}}
                component={renderRadioGroup}
                validate={[Validator.required]}
              >
                <FormControlLabel value="super" control={<Radio />} label="システム管理者" />
                <FormControlLabel value="company" control={<Radio />} label="加盟店管理者" />
              </Field>
            </Grid>
          }

          <Grid item xs={12}>
            <FormLabel component="label">個人情報閲覧権限</FormLabel> 
            {props.action ? 
              <Field
                name="browsing_right"
                component={CheckboxRedux}
              >
                <FormControlLabel control={<Checkbox />} />
              </Field>
              :
              role == 'super' ? 
                <Field
                name="browsing_right"
                component={CheckboxRedux}
                >
                  <FormControlLabel control={<Checkbox />} />
                </Field>
              :
                <Field
                  name="browsing_right"
                  component={CheckboxRedux}
                  disabled={true}
                >
                  <FormControlLabel control={<Checkbox />} />
                </Field>
            }
          </Grid>

        </Grid>
        <div className={classes.btnArea}>
          <Grid container justify="center">
            <Button variant="outlined" color="primary" size="large" component={Link} to={url}>
              戻る
            </Button>
            <Button variant="contained" color="primary" size="large" type="submit">
              {action ? '登録' : '更新'}
            </Button>
          </Grid>
        </div>
        {action || (adminUserData && adminUserData.login === login) ? '' :
          <div className={classes.btnArea}>
            <Grid container justify="center">
              <DeleteDialog color="secondary" className={classes.button} message="この管理者を削除する" deleteForm={deleteForm}/>
            </Grid>
          </div>
        }
      </form>
    </section>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'adminUserForm',
  enableReinitialize: true,
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    loadingAdminUserShow: state.getIn(['adminUserReducers', 'loadingAdminUserShow']),
    initialValues: state.getIn(['adminUserReducers', 'adminUserData']),
    adminUserData: state.getIn(['adminUserReducers', 'adminUserData']),
    formValues: getFormValues("adminUserForm")(state)
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
