import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { ThemeProviderTextFieldRedux, CheckboxRedux} from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import * as Validator from '../../../utils/validate'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { fromJS } from 'immutable';
import SelectedOptionDialog from '../../../components/Dialog/SelectedOptionDialog';

function renderFormItems(props) {

  const {
    classes,
    fields, 
    form_type,
    itemIndex,
    syncErrors,
    formValues,
    meta: { touched, error, submitFailed },
  } = props;

  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [dialogData, setDialogData] = useState(
    { 
      open: false
    }
  );

  const handleOpenDialog = (index) => {
    setOpenDialogIndex(index)
    setDialogData({open: true})
  };

  const handleCloseDialog = () => {
    setOpenDialogIndex(null)
    setDialogData({open: false})
  };

  const addItemSelect = () => {
    fields.push(fromJS({value: ""}))
  };

  const checkOptionArray = (index) => {
    const array = fields.get(index).toJS().form_items_attributes
    const result = Array.isArray(array) && array.some(item => item._destroy !== true)
    return result
  }

  return (
    <div>
      {fields.map((select, index) => (
        fields.get(index).get("_destroy") ? "" :
          <div key={index}>
          <Grid container spacing={3} color="primary" justify="center" alignItems="center">
            <Grid container item xs={10} >
              <FormControl className={classes.formControl} >
              { form_type !== "nest_select" ? 
                <Field
                  name={`${select}.value`}
                  // label={"選択肢"}
                  placeholder={"項目内容を入力してください"}
                  fullWidth
                  id="standard-basic"
                  component={ThemeProviderTextFieldRedux}
                  validate={[Validator.required, Validator.length]}
                /> :
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={10} >
                    <Field
                      name={`${select}.value`}
                      // label={"選択肢"}
                      placeholder={"項目内容を入力してください"}
                      fullWidth
                      id="standard-basic"
                      component={ThemeProviderTextFieldRedux}
                      validate={[Validator.required, Validator.length]}
                    />
                  </Grid>
                  <Grid item xs={2} >
                    <Button color="primary" variant={checkOptionArray(index) ? "outlined" : "contained"} size="small" onClick={() => {handleOpenDialog(index)}}>
                      分岐フォーム
                    </Button>
                  </Grid>
                  {openDialogIndex === index && (
                    <SelectedOptionDialog dialogData={dialogData} handleCloseDialog={handleCloseDialog} select={select} index={index} syncErrors={syncErrors} itemIndex={itemIndex} formValues={formValues.get("form_items_attributes").toJS()[itemIndex]}/>
                  )}
                </Grid>
              }
              </FormControl>
            </Grid>
            <Grid container item xs={1} justify="flex-start" alignItems="center">
              <Tooltip title="選択項目を削除" placement="right">
                <FormControlLabel control={(<Field name={`${select}._destroy`} component={CheckboxRedux} icon={<CloseIcon className={classes.icon} />}　/>)} style={{ float:'right' }}/>
              </Tooltip>
            </Grid>
          </Grid>
          </div>
        ))}
      <Grid container color="primary" justify="center" >
        <Button variant="contained" color="secondary" size="large" onClick={addItemSelect}>
          選択項目を追加
        </Button>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(renderFormItems);
