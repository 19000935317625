const ErrorMessages = {
  required: '必須項目です',
  email: '正しいメールアドレスの形式で入力してください',
  password: '英字、数字を組み合わせた8文字以上100文字以内で入力してください',
  stock: '0以上の整数で入力してください',
  num: '1以上の整数で入力してください',
  url: 'URLの形式が間違っています。 例：https://example.com',
  kana: '50文字以内の全角カタカナで入力してください',
  login: '6文字以上30文字以内で入力してください',
  zip1: '数字3桁で入力してください',
  zip2: '数字4桁で入力してください',
  price: '1円〜999,999円の間で入力してください',
  length: '15文字以内で入力してください',
  length2: '10文字以内で入力してください',
  subjectMaxlength: '30文字以内で入力してください',
  messageMaxlength: '600文字以内で入力してください',
  spsvFreeMaxlength: '64Byte以内で入力してください',
  spsvFree: '「&<>"\'」は入力できません',
  tel: '正しい電話番号の形式で入力してください',
  ipAddress: '正しいIPアドレスの形式で入力してください',
  code: '10文字以内の半角英数字で入力してください',
  notUnderBar: '「_」は入力できません',
  html: 'HTMLの形式が間違っています',
  payLimit: '1以上60以下の整数で入力してください',
  byte16: '指定された16バイトの英数字を入力してください',
}

const Regex = {
  email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  password: /^(?=.*?[a-zA-Z])(?=.*?\d)[!-\~\s]{8,100}$/,
  stock: /^(0|[1-9][0-9]*)$/,
  num: /^([1-9]\d*)$/,
  url: /^(https?)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/,
  kana:/^([ァ-ヴ][ァ-ヴー・]*)$/,
  login: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]{6,30}$/,
  zip1: /^[0-9]{3}$/,
  zip2: /^[0-9]{4}$/,
  price: /^[1-9]{1}[0-9]{0,5}$/,
  blank: /\S/,
  spsvFree: /[&<>"']/,
  tel: /^\d{10,11}$/,
  ipv4Pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
  ipv6Pattern: /^([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4})$/,
  code: /^[a-zA-Z0-9]{1,10}$/,
  notUnderBar: /[_]/,
  html: /<[^>]*>/,
  payLimit: /^(?:[1-9]|[1-5][0-9]|60)$/,
  byte16: /.{16}$/
}

export const required = value => (value && Regex.blank.test(value) || value === 0 ? undefined : ErrorMessages.required)

export const email = value => (value && !Regex.email.test(value) ? ErrorMessages.email : undefined)

export const password = value => (value && !Regex.password.test(value) ? ErrorMessages.password : undefined)

export const stock = value => (value && !Regex.stock.test(value) ? ErrorMessages.stock : undefined)

export const num = value => (value && !Regex.num.test(value) ? ErrorMessages.num : undefined)

export const url = value => (value && !Regex.url.test(value) ? ErrorMessages.url : undefined)

export const extendValidator = text => func => (func ? text : undefined)

export const kana = value => (value && !Regex.kana.test(value) ? ErrorMessages.kana : undefined)

export const login = value => (value && !Regex.login.test(value) ? ErrorMessages.login : undefined)

export const zip1 = value => (value && !Regex.zip1.test(value) ? ErrorMessages.zip1 : undefined)

export const zip2 = value => (value && !Regex.zip2.test(value) ? ErrorMessages.zip2 : undefined)

export const price = value => (value && !Regex.price.test(value) ? ErrorMessages.price : undefined)

export const length = value => (value && value.length > 15 ? ErrorMessages.length : undefined)

export const length2 = value => (value && value.length > 10 ? ErrorMessages.length2 : undefined)

export const subjectMaxlength = value => (value && value.length > 30 ? ErrorMessages.subjectMaxlength : undefined)

export const messageMaxlength = value => (value && value.length > 600 ? ErrorMessages.messageMaxlength : undefined)

export const spsvFreeMaxlength = value => (value && new TextEncoder().encode(value).length > 64 ? ErrorMessages.spsvFreeMaxlength : undefined)

export const spsvFree = value => (value && Regex.spsvFree.test(value) ? ErrorMessages.spsvFree : undefined)

export const tel = value => (value && !Regex.tel.test(value) ? ErrorMessages.tel : undefined)

export const ipAddress = value => (value && !Regex.ipv4Pattern.test(value) && !Regex.ipv6Pattern.test(value) ? ErrorMessages.ipAddress : undefined
)

export const code = value => (value && !Regex.code.test(value) ? ErrorMessages.code : undefined)

export const notUnderBar = value => (value && Regex.notUnderBar.test(value) ? ErrorMessages.notUnderBar : undefined)

export const html = value => (value && !Regex.html.test(value) ? ErrorMessages.html : undefined)

export const payLimit = value => (value && !Regex.payLimit.test(value) ? ErrorMessages.payLimit : undefined)

export const byte16 = value => (value && !Regex.byte16.test(value) ? ErrorMessages.byte16 : undefined)