import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import SearchForm from '../../../components/Forms/SearchForm';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import Loading from '../../../components/Loading/index'
import AreaChart from './AreaChart';
import { onFetchIndexAnalysisPeriodAction } from '../../../redux/actions/analysisPeriod';
import { dateFormat } from '../../../utils/format';

function Index(props) {
  const { classes } = props;
  const { companies } = props.internalValues

  const title = brand.name + ' - 請求書一覧';
  const description = brand.desc;

  // 集計開始日
  let first_of_month = new Date();
  first_of_month.setDate(1);

  // 集計終了日
  let last_of_month = new Date();
  last_of_month.setDate(1);
  last_of_month.setMonth(last_of_month.getMonth() + 1)
  last_of_month.setDate(0);

  // 検索項目
  const [searchItems, setSearchItems] = useState(
    [
      { label: "集計開始日 *", name: "period_from", value: first_of_month, formType: "date", required: true},
      { label: "集計終了日 *", name: "period_to", value: last_of_month, formType: "date", required: true},
      { label: "集計単位 *", name: "period_type", value: "day", formType: "select", required: true,
        menuItems: [
          {key: '日単位', value: 'day'},
          {key: '月単位', value: 'month'},
          {key: '週単位', value: 'week_day'},
          {key: '時間単位', value: 'hour'}
        ]
      },
      { label: "決済手段", name: "payment_type", value: "", formType: "select",
        menuItems: [
          {key: '通常決済', value: 'SkyRocketModel::NormalPaymentHistory'},
          {key: '継続課金', value: 'SkyRocketModel::SubscriptionPaymentHistory'},
          {key: '顔認証決済', value: 'SkyRocketModel::FaceAuthPaymentHistory'},
          {key: 'メール決済', value: 'SkyRocketModel::MailPaymentHistory'}
        ]
      },
      { label: "加盟店名", name: "company_id", value: "", formType: "select", super: true,
        menuItems: companies ? companies.map( (company) => ({key: company.name, value: company.id} )) : null
      }
    ]
  )

  const [searchedItems, setSearchedItems] = useState([ first_of_month, last_of_month, "day"])

  // 一覧情報
  const [loading, setLoading] = useState(true);
  const [analysisPeriodData, setAnalysisPeriodData] = useState("")
  
  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    data.page = page
    if (data.period_from !== null && data.period_to !== null) {
      data.period_from = dateFormat(data.period_from)
      data.period_to = dateFormat(data.period_to)
      props.onFetchIndexAnalysisPeriod({
        accessToken: accessToken(props),
        data: data,
        onSuccess: data => {
          const item = data
          setAnalysisPeriodData(item)
          setSearchedItems([searchItems[0].value, searchItems[1].value, searchItems[2].value])
          item.data.length == 0 ? displayNotification("検索結果がありませんでした", 'warning') : setLoading(false)
        },
        onErrorWithNotice: error => {
          displayNotification(error)
        }
      })
    } else {
      displayNotification('*がついている項目は必須です')
    }
  };

  // アラートメッセージ
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PapperBlock title="期間別売上" whiteBg icon="ios-search-outline" desc="">
        <section className={classes.formWrap}>
          <Grid container spacing={3}>
            { searchItems.map( (item, index) => {
                return(
                  <Grid key={index} item xs={12} sm={6}>
                    <SearchForm
                      searchItem={item}
                      key={index}
                      id={index}
                      changeValue={changeValue}
                      role={props.internalValues.role}
                      initialValues={{period_from: first_of_month, period_to: last_of_month, period_type: 'day'}}
                    />
                  </Grid>
                )}
              )
            }
          </Grid>
        </section>
        <div className={classes.btnArea}>
          <Button variant="contained" color="primary" size="large" onClick={submitForm}>
            検索
          </Button>
        </div>
      </PapperBlock>
      {/*検索を開始するまでは一覧は表示しない
         検索を開始し、管理者一覧APIが終了するまではローディング画面
         管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない
         管理者一覧APIが終了し、データが格納されている場合は一覧を表示*/}
      {loading || analysisPeriodData.data.length === 0 ?  "" : props.loadingAnalysisPeriodIndex ? <Loading /> :
        <PapperBlock title="集計結果" icon="ios-podium-outline" desc="" overflowX>
          <AreaChart searchedItems={searchedItems} searchResult={analysisPeriodData} />
        </PapperBlock>
      }
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingAnalysisPeriodIndex: state.getIn(['analysisPeriodReducers', 'loadingAnalysisPeriodIndex'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexAnalysisPeriod: data => {
      dispatch(onFetchIndexAnalysisPeriodAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default withStyles(styles)(withCookies(withRouter(FormInit)));
