import React, { useState, useEffect } from 'react';
import { Route, Redirect } from "react-router-dom"
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import Loading from '../Loading/index'
import { connect } from 'react-redux';
import { onFetchUserInfoAction } from "../../redux/actions/account";
import { onSetStateCommonAction } from "../../redux/actions/internal";

function LoginRequiredRoute(props) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { component : Component, ...rest } = props;

  useEffect(() => {
    if (props.cookies.get('accessToken') != null && new Date() <= new Date(props.cookies.get('accessTokenExpireDate'))){
      setIsAuthenticated(true);
    }
    props.onFetchUserInfo({
      accessToken: props.cookies.get('accessToken'),
      onSuccess: async data => {
        var now = new Date();
        var hour = new Date();
        hour.setHours(now.getHours()+1);
        props.cookies.set('accessToken', data.access_token, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.cookies.set('accessTokenExpireDate', data.access_token_expire_date, { path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax' });
        props.onSetStateCommon({
          login: data.login,
          role: data.role,
          companyId: data.company_id,
          company: data.company,
          companies: data.companies,
          userName: data.user_name,
          browsingRight: data.browsing_right
        });
      },
      onErrorWithNotice: error => {
        setIsAuthenticated(false)
      }
    })
    setLoading(false)
  },[window.location.pathname]);

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Route {...rest} render={() => {
      // 未ログインなら、ログイン画面にリダイレクト.
      if (!isAuthenticated) {
        return <Redirect to={{ pathname: '/admin/login', state: { from: props.location }, message: '認証エラーです。ログインし直してください', variant: 'error' }} />
      }
      // ログイン済なら、指定されたコンポーネントを表示.
      if (!props.loadingUserInfo){
        return <Component {...props} />
      } else {
        return <Loading />
      }
    }}
    />
  )
}

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingUserInfo: state.getIn(['accountReducers', 'loadingUserInfo'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUserInfo: data => {
      dispatch(onFetchUserInfoAction(data));
    },
    onSetStateCommon: data => {
      dispatch(onSetStateCommonAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginRequiredRoute);

export default withCookies(withRouter(FormInit));