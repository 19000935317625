import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import LoginForm from 'dan-components/Forms/LoginForm';
import styles from 'dan-components/Forms/user-jss';
import { connect } from 'react-redux';
import { onFetchUserLoginAction } from "../../../redux/actions/account";
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';

function Login(props) {
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const submitForm = values => {
    props.onFetchUserLogin({
      data: values,
      onSuccess: async data => {
        var now = new Date();
        var hour = new Date();
        hour.setMinutes(now.getMinutes() + 10);
        props.cookies.set('twoFactorToken', data.two_factor_token, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.cookies.set('twoFactorTokenExpireDate', data.two_factor_token_expire_date, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.history.push('/admin/two-factor')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  const title = brand.name + ' - Login Page';
  const description = brand.desc;
  const { classes } = props;
  return (
    <div className={classes.rootFull}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.containerSide}>
        <Hidden smDown>
          <div className={classes.opening}>
            <Typography variant="h3" component="h1" className={classes.opening} gutterBottom>
              Welcome to&nbsp;
              {brand.name}
            </Typography>
            <Typography variant="h6" component="p" className={classes.subpening}>Please sign in to continue</Typography>
          </div>
        </Hidden>
        <div className={classes.sideFormWrap}>
          <LoginForm initialValues={{company: props.cookies.get('company')}} onSubmit={(values) => submitForm(values)} />
        </div>
        <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
      </div>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingUserLogin: state.getIn(['accountReducers', 'loadingUserLogin'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUserLogin: data => {
      dispatch(onFetchUserLoginAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default withCookies(withRouter(withStyles(styles)(FormInit)));
