import { fromJS, Map } from "immutable";
import { exportLogAction } from "../constants/actionTypes";

const initialState = {
  loadingExportLogIndex: false,
  loadingExportLogDelete: false,
  loadingExportLogDownload: false,
  exportLogData: Map()
};

const initialImmutableState = fromJS(initialState);
const exportLogReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case exportLogAction.ON_FETCH_INDEX_EXPORT_LOG:
      return state.withMutations(mutableState => {
        mutableState.set("loadingExportLogIndex", true);
      });
    case exportLogAction.END_FETCH_INDEX_EXPORT_LOG:
      return state.withMutations(mutableState => {
        mutableState.set("loadingExportLogIndex", false);
      });
    case exportLogAction.ON_FETCH_DELETE_EXPORT_LOG:
      return state.withMutations(mutableState => {
        mutableState.set("loadingExportLogDelete", true);
      });
    case exportLogAction.END_FETCH_DELETE_EXPORT_LOG:
      return state.withMutations(mutableState => {
        mutableState.set("loadingExportLogDelete", false);
      });
    case exportLogAction.ON_FETCH_DOWNLOAD_EXPORT_LOG:
      return state.withMutations(mutableState => {
        mutableState.set("loadingExportLogDownload", true);
      });
    case exportLogAction.END_FETCH_DOWNLOAD_EXPORT_LOG:
      return state.withMutations(mutableState => {
        mutableState.set("loadingExportLogDownload", false);
      });
    default:
      return state;
  }
};

export default exportLogReducers;
