import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import Outer from '../Templates/Outer';
import Application from './Application';
import { ThemeContext } from './ThemeWrapper';
import Login from '../Pages/AdminUsers/Login';
import ResetPasswordSendMail from '../Pages/AdminUsers/ResetPasswordSendMail';
import ResetPassword from '../Pages/AdminUsers/ResetPassword';
import TwoFactor from '../Pages/AdminUsers/TwoFactor';
import NotFound from '../NotFound/NotFound';

function Auth(props) {
  const changeMode = useContext(ThemeContext);
  return (
    <Outer>
      <Switch>
        <Route path="/admin/login" component={Login} />
        <Route path="/admin/reset-password-send-mail" component={ResetPasswordSendMail} />
        <Route path="/admin/reset-password" component={ResetPassword} />
        <Route path="/admin/two-factor" component={TwoFactor} />
        <Route path="/admin" component={Application} />
        <Route component={NotFound} />
      </Switch>
    </Outer>
  );
}

export default Auth;
