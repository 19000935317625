import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* getIndexUser(data) {
  const url = `${API.samuraiApiUrl}/users`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowUser(data) {
  const url = `${API.samuraiApiUrl}/users/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdateUser(data) {
  const url = `${API.samuraiApiUrl}/users/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteDeleteUser(data) {
  const url = `${API.samuraiApiUrl}/users/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getExport(data) {
  const url = `${API.samuraiApiUrl}/users/export`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* creditCardChangeOrder(data) {
  const url = `${API.samuraiApiUrl}/users/credit_card_change_order`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteImage(data) {
  const url = `${API.samuraiApiUrl}/users/delete_image`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getDownloadImage(data) {
  const url = `${API.samuraiApiUrl}/users/download_image`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  getIndexUser,
  getShowUser,
  putUpdateUser,
  deleteDeleteUser,
  getExport,
  creditCardChangeOrder,
  deleteImage,
  getDownloadImage
};
