import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux, DateRedux, DateTimeRedux } from '../Forms/ReduxFormMUI';
import styles from '../Forms/user-jss';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import SelectSuggestions from './SelectSuggestions';

function SearchForm(props) {
  const { classes, searchItem, id, role, indexPaymentFormData, formValues } = props

  useEffect(() => {
    if (searchItem.formType == 'text') {
      props.change(searchItem.name, searchItem.value);
    }
  }, []);

  const getFormContent = ()  => {
    //superユーザーでのみ表示できる項目の場合には権限をチェックしておく
    if(searchItem.super === true && role !== 'super') return ""
    switch (searchItem.formType ){
      case "select":
        return(
          <FormControl className={classes.formControl}>
            <Field
              name={searchItem.name}
              label={searchItem.label}
              component={SelectRedux}
              onChange={evt => handleChange(evt)}
              >
              {!searchItem.required && <MenuItem key="選択しない" value="">選択しない</MenuItem>}
              { searchItem.menuItems.map( (menu ,index) => { 
                return(
                  <MenuItem key={index} value={menu.value}>{menu.key}</MenuItem>
                )}
              )}
            </Field>
          </FormControl>
        );
      case "date":
        return(
          <FormControl className={classes.formControl}>
            <Field
              name={searchItem.name}
              label={searchItem.label}
              value={searchItem.value}
              component={DateRedux}
              clearable
              onChange={evt => handleChange(evt)}
              >
            </Field>
          </FormControl>
        );
      case "datetime":
        return(
          <FormControl className={classes.formControl}>
            <Field
              name={searchItem.name}
              label={searchItem.label}
              value={searchItem.value}
              component={DateTimeRedux}
              clearable
              onChange={evt => handleChange(evt)}
              >
            </Field>
          </FormControl>
        );
      case "select_suggestion":
        return(
          <FormControl className={classes.formControl}>
            <Field
              name={searchItem.name}
              label={searchItem.label}
              component={SelectSuggestions}
              inputId="react-select-single"
              TextFieldProps={{
                label: '決済フォーム',
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                }
              }}
              placeholder='決済フォームを検索し選択してください'
              options={[{ value: "", label: "選択しない" }, ...(indexPaymentFormData && indexPaymentFormData.length > 0 ? indexPaymentFormData.map((paymentForm, key) => ({value: paymentForm.id, label: paymentForm.attributes.name})):[])] }
              className={classes.field}
              onChange={evt => handleChange(evt)}
            />
          </FormControl>
        );
      case "number":
      case "tel":
      case "text":
      default:
        return(
          <FormControl className={classes.formControl}>
            <Field
              name={searchItem.name}
              component={TextFieldRedux}
              label={searchItem.label}
              className={classes.field}
              onChange={evt => handleChange(evt)}
              />
          </FormControl>
        );
    }
  }

  const handleChange = (e) => {
    if (searchItem.formType === "date" || searchItem.formType === "datetime" || searchItem.formType === "select_suggestion") {
      props.changeValue(id, e)
    } else {
      props.changeValue(id, e.target.value)
    }
  }
  return (
    <React.Fragment>{getFormContent()}</React.Fragment>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  searchItem: PropTypes.object.isRequired
};

const SearchFormReduxed = reduxForm({
  form: 'searchForm',
  // Todo 検索条件保持の対応を行う
  // destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(SearchForm);

const reducer = 'ui';
const SearchFormMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration'])
  }),
)(SearchFormReduxed);

export default withStyles(styles)(SearchFormMapped);