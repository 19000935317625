import { fromJS, List, Map } from 'immutable';
import { internalAction } from "../constants/actionTypes";
import {DEFAULT_STATE_INTERNAL_REDUCER} from "../../constants/VariableConstants";

const initialState = {
  internalValues: Map(DEFAULT_STATE_INTERNAL_REDUCER)
};

const initialImmutableState = fromJS(initialState);
const internalReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case internalAction.SET_STATE_COMMON:
      return state.withMutations((mutableState) => {
        mutableState.set('internalValues', action.data);
      });
    default:
      return state
  }
};

export default internalReducers;