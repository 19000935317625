import {qaCategoryAction} from "../constants/actionTypes";

export const onFetchCreateQaCategoryAction = (data) => {
  return {
    type: qaCategoryAction.ON_FETCH_CREATE_QA_CATEGORY,
    data
  }
};

export const onFetchIndexQaCategoryAction = (data) => {
  return {
    type: qaCategoryAction.ON_FETCH_INDEX_QA_CATEGORY,
    data
  }
};

export const onFetchShowQaCategoryAction = (data) => {
  return {
    type: qaCategoryAction.ON_FETCH_SHOW_QA_CATEGORY,
    data
  }
};

export const onFetchUpdateQaCategoryAction = (data) => {
  return {
    type: qaCategoryAction.ON_FETCH_UPDATE_QA_CATEGORY,
    data
  }
};

export const onFetchDeleteQaCategoryAction = (data) => {
  return {
    type: qaCategoryAction.ON_FETCH_DELETE_QA_CATEGORY,
    data
  }
};

export const clearQaCategoryAction = (data) => {
  return {
    type: qaCategoryAction.CLEAR_QA_CATEGORY,
    data
  }
};