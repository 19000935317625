import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchShowMailPaymentAction, onFetchCreateMailPaymentAction, onFetchUpdateMailPaymentAction, onFetchDeleteMailPaymentAction, onFetchTestSendMailPaymentAction, clearMailPaymentAction } from "../../../redux/actions/mailPayment";
import { onFetchIndexPaymentFormAction } from "../../../redux/actions/paymentForm";
import accessToken from '../../../utils/accessToken';
import * as Validator from '../../../utils/validate';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import { onFetchIndexMailPaymentRecipientAction } from "../../../redux/actions/mailPaymentRecipient";
import List from "../../../components/Lists/List";
import Grid from "@material-ui/core/Grid";
import SearchForm from "../../../components/Forms/SearchForm";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../components/Forms/user-jss";
import { enumToJa, sliceText } from "../../../utils/format";

function Edit(props) {
  const description = brand.desc;
  const { match } = props;
  const { role } = props.internalValues
  const [loading, setLoading] = useState(true);

  const [isNew, setIsNew] = useState(false);
  const [titleName, setTitleName] = useState("");
  const [mailPaymentRecipientData, setMailPaymentRecipientData] = useState("");

  // const [csvFiles, setCsvFiles] = useState([]); // eslint-disable-line

  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const [indexPaymentFormData, setIndexPaymentFormData] = useState([]);

  const [listItems, setlistItems] = useState([
    { label: "メールアドレス", name: "email" },
    { label: "電話番号", name: "tel" },
    { label: "決済フォーム", name: "payment-form-name", format: (value)=>{return sliceText(value['payment-form-name'])}},
    { label: "配信ステータス", name: "status", format: (value)=>{return enumToJa(value['status'])}},
    { label: "決済ステータス", name: "payment-status", format: (value)=>{return enumToJa(value['payment-status'])}}
  ]);

  // 検索項目
  const [searchItems, setSearchItems] = useState(
    [
      { label: "メールアドレス" , name: "email" , value: "" , formType: "text"},
      { label: "電話番号" , name: "tel" , value: "" , formType: "text"},
    ]
  )

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  const formId = match.params.id

  const url = '/admin/mail_payment/index'
  const urlRecipient = '/admin/mail_payment_recipient/'

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const resetLoading = () => {
    setLoading(true)
    setLoading(false)
  }
  const submitForm = (values, page = 1) => {
    let data = {};
    data.mail_payment_id = formId;
    data.page = page
    searchItems.forEach(item => {
      data[item.name] = item.value;
    });
    props.onFetchIndexMailPaymentRecipient({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setMailPaymentRecipientData(item);
        item.data.length === 0
          ? displayNotification("検索結果がありませんでした", "warning")
          : setLoading(false);
      },
      onErrorWithNotice: error => {
        displayNotification(error);
      }
    });
  };

  // 詳細取得
  useEffect(() => {
    let url = props.path.split('/')
    if (url[url.length - 1] === 'new') {
      setIsNew(true)
      setTitleName("配信決済")
      fetchIndexPaymentForm()
      setLoading(false)
      props.clearMailPayment()
    } else {
      fetchForm()
      setTitleName("配信決済詳細")
      submitForm()
    }
  }, []);

  const fetchIndexPaymentForm = company_id => {
    let data = {}
    if (company_id){
      data.company_id = company_id
    }
    data.page = 'all'
    props.onFetchIndexPaymentForm({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setIndexPaymentFormData(item.data)
        item.data.length == 0 ? displayNotification("決済フォームが取得できませんでした。", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  const fetchForm = values => {
    props.onFetchShowMailPayment({
      accessToken: accessToken(props),
      data: {id: formId},
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 登録
  const newForm = values => {
    // CSVを正常に取得するためFormDataを利用
    const formData = new FormData();
    // valuesが存在し、かつvaluesのキーが存在する場合のみ処理を行う
    if (values && values.keys) {
      // valuesのキーのイテレーターを取得し、各キーと値をFormDataにセット
      for (const key of values.keys()) {
        formData.append(`mail_payment[${key}]`, values.get(key));
      }
    }
    let message = "登録が完了しました"
    if (values && (values.get("kind") === "csv")) {
      message = "CSV一括登録処理を開始しました"
    }
    props.onFetchCreateMailPayment({
      accessToken: accessToken(props),
      data: formData,
      onSuccess: async data => {
        props.history.push({ pathname: url, message: message, variant: 'success', createdFormUrl: data.form_url});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 更新
  const editForm = (values) => {
    let dataValues = values
    dataValues.id = formId
    props.onFetchUpdateMailPayment({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 削除
  const deleteForm = values => {
    props.onFetchDeleteMailPayment({
      accessToken: accessToken(props),
      data: {id: formId},
      onSuccess: async data => {
        props.history.push({ pathname: '/admin/mail_payment/index', message: '配信決済を削除しました', variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };

  // 更新
  const testSendForm = (values) => {
    const requiredEmailError = values.get('kind') === 'email' ? Validator.required(values.get('test_email')) : null;
    const emailError = values.get('kind') === 'email' ? Validator.email(values.get('test_email')) : null;
    const requiredTelError = values.get('kind') === 'sms' ? Validator.required(values.get('test_tel')) : null;
    const telError = values.get('kind') === 'sms' ? Validator.tel(values.get('test_tel')) : null;
    const requiredCompanyError = (role ==='super' && !values.get('company_id')) ? '加盟店を選択してください' : null;
    const requiredPaymentFormError = !values.get('payment_form_id') ? '決済フォームを選択してください' : null;

    const errorMessage = requiredEmailError || emailError || requiredTelError || telError || requiredCompanyError || requiredPaymentFormError;

    if (errorMessage) {
      displayNotification(errorMessage);
      return;
    }


    let dataValues = values
    props.onFetchTestSendMailPayment({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        displayNotification("送信しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  return (
    <div>
      <Helmet>
        <title>{brand.name + " - " + titleName}</title>
      </Helmet>
      <div>
        {/* 決済フォーム情報取得Apiが終了するまでは、空のフォームを表示させる */}
        {loading || props.loadingMailPaymentShow ? <Form fetchIndexPaymentForm={fetchIndexPaymentForm}/> : <Form onSubmit={(values) => isNew ? newForm(values) : editForm(values)} deleteForm={deleteForm} testSendForm={testSendForm} initialValues={props.mailPaymentData} indexPaymentFormData={indexPaymentFormData} fetchIndexPaymentForm={fetchIndexPaymentForm} formId={formId} isNew={isNew}/>}
      </div>
      {props.mailPaymentData.kind === "csv" &&
        <PapperBlock title="配信個別検索" whiteBg icon="ios-search-outline" desc="">
          <section className={props.classes.formWrap}>
            <Grid container spacing={3}>
              {searchItems.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6}>
                    <SearchForm searchItem={item} key={index} id={index} changeValue={changeValue} role={props.internalValues.role}/>
                  </Grid>
                )
              })}
            </Grid>
          </section>
          <div className={props.classes.btnArea}>
            <Button variant="contained" color="primary" size="large" onClick={submitForm}>
              検索
            </Button>
          </div>
        </PapperBlock>
      }
      {loading || mailPaymentRecipientData.length === 0 || props.mailPaymentData.kind === "email" || props.mailPaymentData.kind === "sms" ? (
        ""
      ) : (
        <PapperBlock
          title="検索結果"
          whiteBg
          icon="ios-list"
          desc=""
          submitForm={submitForm}
        >
          {props.loadingMailPaymentRecipientIndex ? (
            <div>
              <List
                listItems={listItems}
                searchResult={mailPaymentRecipientData}
                url={urlRecipient}
                submitForm={submitForm}
                role={props.internalValues.role}
                browsingRight={props.internalValues.browsingRight}
              />
              <Loading/>
            </div>
          ) : (
            <List
              listItems={listItems}
              searchResult={mailPaymentRecipientData}
              url={urlRecipient}
              submitForm={submitForm}
              role={props.internalValues.role}
              browsingRight={props.internalValues.browsingRight}
            />
          )}
        </PapperBlock>
      )}
      <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification}/>
    </div>
  );
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    mailPaymentData: state.getIn(['mailPaymentReducers', 'mailPaymentData']),
    loadingMailPaymentShow: state.getIn(['mailPaymentReducers', 'loadingMailPaymentShow']),
    loadingMailPaymentData: state.getIn(['mailPaymentReducers', 'loadingMailPaymentData']),
    loadingMailPaymentRecipientIndex: state.getIn(['mailPaymentRecipientReducers', 'loadingMailPaymentRecipientIndex'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexPaymentForm: data => {
      dispatch(onFetchIndexPaymentFormAction(data));
    },
    onFetchShowMailPayment: data => {
      dispatch(onFetchShowMailPaymentAction(data));
    },
    onFetchCreateMailPayment: data => {
      dispatch(onFetchCreateMailPaymentAction(data));
    },
    onFetchUpdateMailPayment: data => {
      dispatch(onFetchUpdateMailPaymentAction(data));
    },
    onFetchDeleteMailPayment: data => {
      dispatch(onFetchDeleteMailPaymentAction(data));
    },
    onFetchTestSendMailPayment: data => {
      dispatch(onFetchTestSendMailPaymentAction(data));
    },
    clearMailPayment: data => {
      dispatch(clearMailPaymentAction(data));
    },
    onFetchIndexMailPaymentRecipient: data => {
      dispatch(onFetchIndexMailPaymentRecipientAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withStyles(styles)(withCookies(withRouter(FormInit)));