import React from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/ja';

export default class TextEditor extends React.Component {
  constructor (props) {
    super(props);

    this.handleModelChange = this.handleModelChange.bind(this);

  }

  handleModelChange(model) {
    this.props.input.onChange(model);
  }

  render () {
    const accessToken = this.props.accessToken
    const config = {
      key: 'yDC5hH4H4B11A8C7F5F6g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4G3F2H3A10A4C4D4==',
      placeholderText: 'ご入力ください。',
      charCounterCount: false,
      attribution: false,
      toolbarSticky: false,
      toolbarButtons: [
        'fullscreen',
        'insertImage',
        'bold',
        'italic',
        'underline','|',
        'fontFamily',
        'fontSize',
        'color',
        'lineHeight', '|',
        'paragraphFormat',
        'align',
        'formatOL',
        'formatUL',
        'embedly',
        'insertTable', '|',
        'emoticons',
        'fontAwesome',
        'specialCharacters',
        'insertHR',
        'clearFormatting', '|',
        'spellChecker', 'help', 'html', '|', 'undo', 'redo'
      ],
      quickInsertButtons: ['embedly', 'table', 'ul', 'ol', 'hr'],
      imageResizeWithPercent: true,
      imageDefaultWidth: 100,
      language: 'ja',
      theme: 'dark',
      zindex: 0,
      imageUploadURL: '/admin/api/v1/upload_image',
      imageManagerLoadURL: '/admin/api/v1/load_images',
      imageUploadParams: {
        id: 'my_editor'
      },
      imageManagerDeleteURL: '/admin/api/v1/delete_image',
      requestHeaders: {'Authorization': accessToken}
    };
    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <FroalaEditor
          tag='textarea'
          model={this.props.input.value}
          onModelChange={this.handleModelChange}
          config={config}
        />
        <span style={{color: 'red', fontSize: '12px'}}>{this.props.meta.error}</span>
      </div>
    );
  }
}