import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const CsvExportDialog = ({open: isOpen, count, onExport, onClose}) => {
  const [open, setOpen] = useState(isOpen);
  const [started, setStarted] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function handleClose() {
    setOpen(false);
    onClose(false);
    if (started) {
      setStarted(false);
    }
  }

  function handleSubmit() {
    if (started) {
      setOpen(false);
      onClose(false);
      setStarted(false);
    } else {
      onExport();
      setStarted(true);
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {started ? 'CSVエクスポートを開始しました。' : 'CSVエクスポートを開始しますか？'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {started ? 'エクスポートの完了はメールでお知らせします。' : `エクスポート件数は${count}件です。`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!started && (
            <Button onClick={handleClose} color="primary">
              もどる
            </Button>
          )}
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CsvExportDialog;
