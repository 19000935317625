import { fromJS, Map } from 'immutable';
import {analysisPeriodAction} from "../constants/actionTypes";

const initialState = {
  loadingAnalysisPeriodIndex: false,
  analysisPeriodData: Map()
};

const initialImmutableState = fromJS(initialState);
const analysisPeriodReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case analysisPeriodAction.ON_FETCH_INDEX_ANALYSIS_PERIOD:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAnalysisPeriodIndex', true);
      });
    case analysisPeriodAction.END_FETCH_INDEX_ANALYSIS_PERIOD:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAnalysisPeriodIndex', false);
      });
    default:
      return state
  }
};

export default analysisPeriodReducers;
