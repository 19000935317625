import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ResetPasswordSendMailForm from 'dan-components/Forms/ResetPasswordSendMailForm';
import styles from '../../../components/Forms/user-jss';
import { connect } from 'react-redux';
import { onFetchCreatePasswordReminderAction } from "../../../redux/actions/account";
import MySnackbar from '../../../components/Notification/StyledNotif';

function ResetPasswordSendMail(props) {
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const submitForm = useCallback((values) => {
    props.onFetchCreatePasswordReminder({
      data: values,
      onSuccess: async data => {
        displayNotification("メールを送信しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  });

  const title = brand.name + ' - Reset Password';
  const description = brand.desc;
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <ResetPasswordSendMailForm onSubmit={(values) => submitForm(values)} />
        </div>
        <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
      </div>
    </div>
  );
}

ResetPasswordSendMail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loadingPasswordReminder: state.getIn(['accountReducers', 'loadingPasswordReminder'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCreatePasswordReminder: data => {
      dispatch(onFetchCreatePasswordReminderAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordSendMail);

export default withStyles(styles)(FormInit);
