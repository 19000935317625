import { fromJS, Map } from "immutable";
import { mailPaymentRecipientAction } from "../constants/actionTypes";

const initialState = {
  loadingMailPaymentRecipientIndex: false,
  loadingMailPaymentRecipientShow: false,
  loadingMailPaymentRecipientData: false,
  mailPaymentRecipientData: Map(),
  initMailPaymentRecipientData: Map({
    payment_form_id: '',
    no: '',
    kind: '',
    email: '',
    tel: '',
    subject: '',
    body: ''
  })
};

const initialImmutableState = fromJS(initialState);
const mailPaymentRecipientReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case mailPaymentRecipientAction.ON_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations(mutableState => {
        mutableState.set("mailPaymentRecipientIndex", true);
      });
    case mailPaymentRecipientAction.END_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations(mutableState => {
        mutableState.set("mailPaymentRecipientIndex", false);
      });
    case mailPaymentRecipientAction.ON_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations(mutableState => {
        mutableState.set("mailPaymentRecipientShow", true);
      });
    case mailPaymentRecipientAction.END_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations(mutableState => {
        mutableState.set("mailPaymentRecipientShow", false);
        mutableState.set('mailPaymentRecipientData', action.result);
      });
    case mailPaymentRecipientAction.ON_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentRecipientData', true);
      });
    case mailPaymentRecipientAction.END_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentRecipientData', false);
      });
    case mailPaymentRecipientAction.ON_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentRecipientData', true);
      });
    case mailPaymentRecipientAction.END_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingMailPaymentRecipientData', false);
      });
    case mailPaymentRecipientAction.CLEAR_MAIL_PAYMENT_RECIPIENT:
      return state.withMutations((mutableState) => {
        mutableState.set('mailPaymentRecipientData', initialState.initMailPaymentRecipientData);
      });
    default:
      return state;
  }
};
export default mailPaymentRecipientReducers;