import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* postCreateAccount(data) {
  const url = `${API.samuraiApiUrl}/admin_users`;
  return yield callApi("post", url, data.data, null, null, data.onSuccess);
}

function* postUserLogin(data) {
  const url = `${API.samuraiApiUrl}/sessions`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postUserLogout(data) {
  const url = `${API.samuraiApiUrl}/sessions/logout`;
  return yield callApi("post", url, null, data.accessToken, null, null, null, null);
}

function* postUserInfo(data) {
  const url = `${API.samuraiApiUrl}/admin_users/info`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postCreatePasswordReminder(data) {
  const url = `${API.samuraiApiUrl}/password_reminders`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postUpdatePasswordReminder(data) {
  const url = `${API.samuraiApiUrl}/password_reminders`;
  return yield callApi("patch", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postTwoFactorVerify(data) {
  const url = `${API.samuraiApiUrl}/sessions/two_factor_verify`;
  return yield callApi("post", url, data.data, null, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postTwoFactorResend(data) {
  const url = `${API.samuraiApiUrl}/sessions/two_factor_resend`;
  return yield callApi("post", url, data.data, null, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  postCreateAccount,
  postUserLogin,
  postUserLogout,
  postUserInfo,
  postCreatePasswordReminder,
  postUpdatePasswordReminder,
  postTwoFactorVerify,
  postTwoFactorResend,
};
