import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* getIndexBillingStatement(data) {
  const url = `${API.samuraiApiUrl}/billing_statements`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowBillingStatement(data) {
  const url = `${API.samuraiApiUrl}/billing_statements/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  getIndexBillingStatement,
  getShowBillingStatement
};