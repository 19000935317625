import {bulkPaymentAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreateBulkPaymentAction(action) {
  const result = yield Api.postCreateBulkPayment(action.data);
  yield put({type: bulkPaymentAction.END_FETCH_CREATE_BULK_PAYMENT, result: result});
}

function* onFetchIndexBulkPaymentAction(action) {
  const result = yield Api.getIndexBulkPayment(action.data);
  yield put({type: bulkPaymentAction.END_FETCH_INDEX_BULK_PAYMENT, result: result});
}

function* onFetchShowBulkPaymentAction(action) {
  const result = yield Api.getShowBulkPayment(action.data);
  yield put({type: bulkPaymentAction.END_FETCH_SHOW_BULK_PAYMENT, result: result});
}

function* onFetchUpdateBulkPaymentAction(action) {
  const result = yield Api.putUpdateBulkPayment(action.data);
  yield put({type: bulkPaymentAction.END_FETCH_UPDATE_BULK_PAYMENT, result: result});
}

function* onFetchDeleteBulkPaymentAction(action) {
  const result = yield Api.deleteDeleteBulkPayment(action.data);
  yield put({type: bulkPaymentAction.END_FETCH_DELETE_BULK_PAYMENT, result: result});
}

export function* watchOnFetchBulkPayment() {
  yield takeLatest(bulkPaymentAction.ON_FETCH_CREATE_BULK_PAYMENT, onFetchCreateBulkPaymentAction);
  yield takeLatest(bulkPaymentAction.ON_FETCH_INDEX_BULK_PAYMENT, onFetchIndexBulkPaymentAction);
  yield takeLatest(bulkPaymentAction.ON_FETCH_SHOW_BULK_PAYMENT, onFetchShowBulkPaymentAction);
  yield takeLatest(bulkPaymentAction.ON_FETCH_UPDATE_BULK_PAYMENT, onFetchUpdateBulkPaymentAction);
  yield takeLatest(bulkPaymentAction.ON_FETCH_DELETE_BULK_PAYMENT, onFetchDeleteBulkPaymentAction);
}
