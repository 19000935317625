import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchShowUserAction, onFetchUpdateUserAction, onFetchDeleteUserAction, creditCardChangeOrderAction, onFetchDeleteImageAction, onFetchDownloadImageAction } from "../../../redux/actions/user";
import accessToken from '../../../utils/accessToken';

function Edit(props) {
  const title = brand.name + ' - 会員詳細';
  const description = brand.desc;
  const { match } = props;
  const [loading, setLoading] = useState(true);

  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  } 

  // 詳細取得
  useEffect(() => {
    fetchForm()
  }, []);

  const fetchForm = values => {
    props.onFetchShowUser({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 更新
  const submitForm = values => {
    var dataValues = values
    dataValues.id = match.params.id
    props.onFetchUpdateUser({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification("更新できませんでした")
      }
    })
  };

  // 削除
  const deleteForm = values => {
    props.onFetchDeleteUser({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onSuccess: async data => {
        props.history.push({ pathname: '/admin/user/index', message: '会員を削除しました', variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };

  // クレカ変更依頼通知
  const notifyOrder = (userID) => {
    props.creditCardChangeOrder({
      accessToken: accessToken(props),
      data: { id: userID },
      onSuccess: async data => {
        displayNotification("送信完了しました", 'success');
      },
      onErrorWithNotice: error => {
        displayNotification("送信できませんでした");
      }
    });
  }

  const handleDeleteImage = id => {
    props.onFetchDeleteImage({
      accessToken: accessToken(props),
      data: { id: id },
      onSuccess: async data => {
        displayNotification("画像を削除しました", 'success');
        fetchForm()
      },
      onErrorWithNotice: error => {
        displayNotification("画像の削除に失敗しました");
      }
    })
  }

  const handleDownloadImage = id => {
    props.onFetchDownloadImage({
      accessToken: accessToken(props),
      data: { id: id },
      onSuccess: data => {
        const url = window.URL.createObjectURL(data.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          data.headers["content-disposition"].match(/filename="([^"]+)"/)[1]
        ); // ファイル名を設定
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // リンクをクリーンアップ
      },
      onErrorWithNotice: error => {
        displayNotification("ダウンロードできませんでした");
      }
    })
  }
  
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
        <PapperBlock title="会員詳細" whiteBg icon="ios-contact-outline" desc="">
          <div>
          {/* 会員情報取得Apiが終了するまでは、空のフォームを表示させる */}
          {loading || props.loadingUserShow ? <Form /> : <Form onSubmit={(values) => submitForm(values)} deleteForm={deleteForm} notifyOrder={(userID) => notifyOrder(userID)} handleDeleteImage={handleDeleteImage} handleDownloadImage={handleDownloadImage} initialValues={props.userData} browsingRight={props.internalValues.browsingRight} />}
          </div>
        </PapperBlock>
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userData: state.getIn(['userReducers', 'userData']),
    loadingUserShow: state.getIn(['userReducers', 'loadingUserShow']),
    loadingUserData: state.getIn(['userReducers', 'loadingUserData']),
    loadingImageDownload: state.getIn(['userReducers', 'loadingImageDownload'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShowUser: data => {
      dispatch(onFetchShowUserAction(data));
    },
    onFetchUpdateUser: data => {
      dispatch(onFetchUpdateUserAction(data));
    },
    onFetchDeleteUser: data => {
      dispatch(onFetchDeleteUserAction(data));
    },
    creditCardChangeOrder: data => {
      dispatch(creditCardChangeOrderAction(data));
    },
    onFetchDeleteImage: data => {
      dispatch(onFetchDeleteImageAction(data));
    },
    onFetchDownloadImage: data => {
      dispatch(onFetchDownloadImageAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(FormInit));