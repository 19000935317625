import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* getIndexPaymentHistory(data) {
  const url = `${API.samuraiApiUrl}/payment_histories`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowPaymentHistory(data) {
  const url = `${API.samuraiApiUrl}/payment_histories/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdatePaymentHistory(data) {
  const url = `${API.samuraiApiUrl}/payment_histories/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getExportPaymentHistory(data) {
  const url = `${API.samuraiApiUrl}/payment_histories/export`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  getIndexPaymentHistory,
  getShowPaymentHistory,
  putUpdatePaymentHistory,
  getExportPaymentHistory,
};
