import { fromJS, Map } from 'immutable';
import {billingStatementAction} from "../constants/actionTypes";

const initialState = {
  loadingBillingStatementIndex: false,
  loadingBillingStatementShow: false,
  loadingBillingStatementData: false,
  billingStatementData: Map()
};

const initialImmutableState = fromJS(initialState);
const billingStatementReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case billingStatementAction.ON_FETCH_INDEX_BILLING_STATEMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBillingStatementIndex', true);
      });
    case billingStatementAction.END_FETCH_INDEX_BILLING_STATEMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBillingStatementIndex', false);
      });
    case billingStatementAction.ON_FETCH_SHOW_BILLING_STATEMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBillingStatementShow', true);
      });
    case billingStatementAction.END_FETCH_SHOW_BILLING_STATEMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBillingStatementShow', false);
        mutableState.set('billingStatementData', action.result);
      });
    default:
      return state
  }
};

export default billingStatementReducers;