import React, { useState } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import MaterialDropZone from 'dan-components/Forms/MaterialDropZone';
import { TextFieldRedux } from 'dan-components/Forms/ReduxFormMUI';
import ColorPickerField from "./ColorPickerField";

function ImageForm(props) {
  const {
    classes,
    handleSubmit,
    action,
    formValues,
    isShow
  } = props;

  const [imageFiles, setImageFiles] = useState([]);

  return (
    <form onSubmit={handleSubmit}>    
      <h1>ヘッダー設定</h1>
      <FormControl className={classes.formControl}>
        <Field
          name={`image`}
          component={MaterialDropZone}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          type="file"
          showPreviews
          files={(formValues && formValues.get("image")) ? [formValues.get("image")] : []}
          setFiles={setImageFiles}
          maxSize={5000000}
          filesLimit={1}
          text="ここに画像をドラッグ＆ドロップまたはクリックして画像を選択してください"
        />
      </FormControl>

      <Grid item xs={4} sm={2}>
        <FormControl className={classes.formControl}>
          <Field 
            name="header_bg_color"
            component={ColorPickerField}
          />
        </FormControl>
      </Grid>

      <h1 style={{marginTop: 30, marginBottom: 10}}>フッター設定</h1>
      <Grid item xs={6} sm={3}>
        <FormControl className={classes.formControl} >
          <Field
            name="footer_text"
            label="フッターテキスト"
            fullWidth
            component={TextFieldRedux}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl className={classes.formControl}>
          <Field 
            name="footer_text_color"
            component={ColorPickerField}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4} sm={2}>
        <FormControl className={classes.formControl}>
          <Field 
            name="footer_bg_color"
            component={ColorPickerField}
          />
        </FormControl>
      </Grid>

      <div className={classes.btnArea}>
        <Grid container justify="center">
            {isShow ? '' :
              <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/company/index">
                戻る
              </Button>
            }
          <Button variant="contained" color="primary" size="large" type="submit" disabled={props.invalid}>            
            {action ? '登録' : '更新'}
          </Button>
        </Grid>
      </div>
    </form>
  )
}

ImageForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const ImageFormReduxed = reduxForm({
  form: 'companyForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ImageForm);

const reducer = 'ui';

const FormInit = connect(
  state => ({
    force: state,
    formValues: getFormValues("companyForm")(state)
  }),
)(ImageFormReduxed);

export default withStyles(styles)(FormInit);