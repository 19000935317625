import { fromJS, Map, List } from 'immutable';
import {companyAction} from "../constants/actionTypes";
const initialState = {
  loadingCreateCompany: false,
  loadingShowCompany: false,
  loadingIndexCompany: false,
  loadingUpdateCompany: false,
  companyData: Map(),
  initCompanyData: Map({
    name: '',
    url: '',
    mail_signature: '',
    line_code: '',
    line_token: '',
    sps_code: '',
    sps_password: '',
    sps_token: '',
    escott_password: '',
    status: '',
    plan: '',
    capture_kind: '',
    image_url: '',
    image: '',
    zip1: '',
    zip2: '',
    pref: '',
    city: '',
    street: '',
    street2: '',
    contract_period_from: null,
    contract_period_to: null,
  })
};
const initialImmutableState = fromJS(initialState);
const companyReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case companyAction.ON_FETCH_CREATE_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingCreateCompany', true);
      });
    case companyAction.END_FETCH_CREATE_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingCreateCompany', false);
      });
    case companyAction.ON_FETCH_INDEX_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingIndexCompany', true);
      });
    case companyAction.END_FETCH_INDEX_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingIndexCompany', false);
      });
    case companyAction.ON_FETCH_SHOW_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingShowCompany', true);
      });
    case companyAction.END_FETCH_SHOW_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingShowCompany', false);
        let result = action.result
        result["image"] = ""
        mutableState.set('companyData', result);
      });
    case companyAction.ON_FETCH_UPDATE_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUpdateCompany', true);
      });
    case companyAction.END_FETCH_UPDATE_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUpdateCompany', false);
      });
    case companyAction.ON_FETCH_DELETE_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingDeleteCompany', true);
      });
    case companyAction.END_FETCH_DELETE_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingDeleteCompany', false);
      });
    case companyAction.CLEAR_COMPANY:
      return state.withMutations((mutableState) => {
        mutableState.set('companyData', initialState.initCompanyData);
      });
    default:
      return state
  }
};
export default companyReducers;