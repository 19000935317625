import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import SearchForm from '../../../components/Forms/SearchForm';
import List from '../../../components/Lists/List';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchIndexMailPaymentAction } from '../../../redux/actions/mailPayment';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import {enumToJa, sliceText, dateTimeFormat} from '../../../utils/format';
import Loading from '../../../components/Loading/index'

function Index(props) {
  const { classes } = props;
  const location = props.location
  const { companies } = props.internalValues

  const title = brand.name + ' - 配信決済一覧';
  const description = brand.desc;
  const url = '/admin/mail_payment/'

  // 検索項目
  const [searchItems, setSearchItems] = useState(
    [
      // TODO 通常決済以外の決済種別が利用できるようになり次第コメント解除
      // { label: "決済方法" , name: "type" , value: "" , formType: "select", menuItems: [{key: '通常決済' , value: 'normal_payment'} , {key: '継続課金決済' , value: 'subscription_payment'}　, {key: '顔認証決済' , value: 'face_auth_payment'}]},
      { label: "配信日時〜", name: "delivery_from", value: null, formType: "datetime" },
      { label: "〜配信日時", name: "delivery_to", value: null, formType: "datetime" },
      { label: "配信種別" , name: "kind" , value: "" , formType: "select" , menuItems: [{key: 'CSV' , value: '0'}, {key: 'メールアドレス' , value: '1'} , {key: 'SMS' , value: '2'}]},
      { label: "決済フォーム名" , name: "payment_form_name" , value: "" , formType: "text"},
      { label: "配信コード" , name: "code" , value: "" , formType: "text"},
      { label: "CSVファイル名" , name: "csv_file_name" , value: "" , formType: "text"},
      { label: "メールアドレス" , name: "email" , value: "" , formType: "text"},
      { label: "電話番号" , name: "tel" , value: "" , formType: "text"},
      { label: "加盟店名", name: "company_id", value: "", formType: "select", super: true,
        menuItems: companies ? companies.map( (company) => ({key: company.name, value: company.id} )) : null
      }
    ]
  )

  const [listItems, setlistItems] = useState(
    [
      { label: "加盟店名", name: "company", super: true},
      { label: "配信日時", name: "delivery-at", format: (value)=>{return dateTimeFormat(value['delivery-at'])}},
      { label: "配信種別", name: "kind", format: (value)=>{return enumToJa(value['kind'])}},
      { label: "配信コード", name: "code", format: (value)=>{return sliceText(value['code'])}},
      { label: "決済数", name: "paid-count", format: (value)=>{return sliceText(value['paid-count'])}}
    ]
  )

  // 一覧
  const [loading, setLoading] = useState(true);
  const [mailPaymentData, setMailPaymentData] = useState("")
  
  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    data.page = page
    props.onFetchIndexMailPayment({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setMailPaymentData(item)
        item.data.length == 0 ? displayNotification("検索結果がありませんでした", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // メッセージ関連
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  return (
    <div>
      <Helmet>
      <title>{title}</title>
      </Helmet>
      <PapperBlock title="配信決済検索" whiteBg icon="ios-search-outline" desc="">
        <section className={classes.formWrap}>
          <Grid container spacing={3}>
            { searchItems.map( (item, index) => {
                return(
                  <Grid key={index} item xs={12} sm={6}>
                    <SearchForm searchItem={item} key={index} id={index} changeValue={changeValue} role={props.internalValues.role}/>
                  </Grid>
                )}
              )
            }
          </Grid>
        </section>
          <div className={classes.btnArea}>
            <Button variant="contained" color="primary" size="large" onClick={submitForm}>
              検索
            </Button>
          </div>
      </PapperBlock>
      {/*検索を開始するまでは一覧は表示しない
         検索を開始し、管理者一覧APIが終了するまではローディング画面
         管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない
         管理者一覧APIが終了し、データが格納されている場合は一覧を表示*/}
      {loading || mailPaymentData.data.length === 0 ?  "" :
          <PapperBlock title="配信決済一覧" whiteBg icon="ios-list"desc="">
            { props.loadingMailPaymentIndex ? 
              <div><List listItems={listItems} searchResult={mailPaymentData} url={url} submitForm={submitForm} role={props.internalValues.role}/><Loading /></div> :
              <List listItems={listItems} searchResult={mailPaymentData} url={url} submitForm={submitForm} role={props.internalValues.role}/>
            }
          </PapperBlock>
      }
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingMailPaymentIndex: state.getIn(['mailPaymentReducers', 'loadingMailPaymentIndex'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexMailPayment: data => {
      dispatch(onFetchIndexMailPaymentAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default withStyles(styles)(withCookies(withRouter(FormInit)));