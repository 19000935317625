import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import ServiceForm from './ServiceForm';
import brand from 'dan-api/dummy/brand';
import { connect } from 'react-redux';
import { onFetchCreateCompanyAction, clearCompanyAction } from "../../../redux/actions/company";
import accessToken from '../../../utils/accessToken';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import NotFound from '../../NotFound/NotFound';

function New(props) {
  const title = brand.name + ' - 加盟店登録'
  const description = brand.desc;
  const url = '/admin/company/index'
  const internalValues = props.internalValues;
  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  // フォーム初期化
  useEffect(() => {
    props.clearCompany()
  }, []);

  // 登録
  const submitForm = values => {
    let dataValues = values
    props.onFetchCreateCompany({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "登録が完了しました", variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  return (
    <React.Fragment>
      {internalValues.role === 'company' ? <NotFound /> :
        <div>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <PapperBlock title='加盟店登録' whiteBg icon="ios-document-outline" desc="">
            <ServiceForm onSubmit={(values) => submitForm(values)} isNew={true} initialValues={props.companyData}/>
          </PapperBlock>
          <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
        </div>
      }
    </React.Fragment>
    );
  }

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    companyData: state.getIn(['companyReducers', 'companyData']),
    loadingCreateCompany: state.getIn(['companyReducers', 'loadingCreateCompany'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCreateCompany: data => {
      dispatch(onFetchCreateCompanyAction(data));
    },
    clearCompany: data => {
      dispatch(clearCompanyAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(New);

export default withCookies(withRouter(FormInit));