import { fromJS, List, Map } from 'immutable';
import { errorAction } from "../constants/actionTypes";

const initialState = {
  errorData: Map(),
  errorToastData: Map()
};

const initialImmutableState = fromJS(initialState);
const errorReducers = (state = initialImmutableState, action) => {
  if (action.type === errorAction.RAISE_ERROR) {
    return state.withMutations((mutableState) => {
      mutableState.set('errorData', action.data);
    });
  } else if (action.type === errorAction.RAISE_ERROR_TOAST) {
    return state.withMutations((mutableState) => {
      mutableState.set('errorToastData', action.data);
    });
  }
  return state
};
export default errorReducers;
