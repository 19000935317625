let samuraiDomain = 'http://localhost:3002'

switch (import.meta.env.MODE) {
  case "development":
    samuraiDomain = 'http://localhost:3002';
    break;
  case "review":
    samuraiDomain = 'https://dev-admin.samuraipay.jp/';
    break;
  case "staging":
    samuraiDomain = 'https://skyrocket-admin-stg.samurai-ar.info/';
    break;
  case "production":
    samuraiDomain = 'https://admin.samuraipay.jp/';
    break;
}

export default {
  samuraiDomain,
  samuraiApiUrl: samuraiDomain + '/admin/api/v1',
};
