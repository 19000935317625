import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import Grid from '@material-ui/core/Grid';
import CounterIconsWidget from '../../../components/Widget/CounterIconsWidget';
import { onFetchIndexPaymentHistoryAction } from '../../../redux/actions/paymentHistory';
import { onFetchIndexAnalysisPeriodAction } from '../../../redux/actions/analysisPeriod';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import accessToken from '../../../utils/accessToken';
import PropTypes from 'prop-types';
import Loading from '../../../components/Loading/index'
import RecentlyList from './RecentlyList';
import { dateFormat, numberWithDelimiter } from '../../../utils/format';
import MySnackbar from '../../../components/Notification/StyledNotif';

function TopPage(props) {
  const title = brand.name + ' - Top';
  const description = brand.desc;
  const role = props.internalValues.role ;

  const [loading, setLoading] = useState(true);
  const [paymentHistoryData, setPaymentHistoryData] = useState("");
  const [daily, setDaily] = useState(0);
  const [monthly, setMonthly] = useState(0);
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  // 今月初
  let first_of_month = new Date();
  first_of_month.setDate(1);

  // 今月末
  let last_of_month = new Date();
  last_of_month.setDate(1);
  last_of_month.setMonth(last_of_month.getMonth() + 1)
  last_of_month.setDate(0);

  // 昨日
  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1);

  let isMounted = false;
  useEffect(() => {
    isMounted = true;
    getPaymentHistory();
    return () => { 
      isMounted = false 
    };
  }, []);

  // 決済履歴取得
  const getPaymentHistory = () => {
    let paymentData = {}
    paymentData.limit = 5
    props.onFetchIndexPaymentHistory({
      accessToken: accessToken(props),
      data: paymentData,
      onSuccess: data => {
        const item = data
        if(isMounted){
          setPaymentHistoryData(item)
          getAnalysisPeriod()
        }
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 売上データ取得
  const getAnalysisPeriod = () => {
    let analysisData = {}
    analysisData.period_from = dateFormat(first_of_month)
    analysisData.period_to = dateFormat(last_of_month)
    analysisData.period_type = "day"
    props.onFetchIndexAnalysisPeriod({
      accessToken: accessToken(props),
      data: analysisData,
      onSuccess: data => {
        const item = data
        countAnalysisPeriodData(item)
        setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 取得した売上データから、昨日の売上/今月の売上を算出
  const countAnalysisPeriodData = (item) => {
    let price = []
    item.data.length > 0 && item.data.map((i) => {
      price.push(parseInt(i.attributes['payment-sum-price']))
      if (dateFormat(i.attributes['date']).includes(dateFormat(yesterday))){
        setDaily(numberWithDelimiter(i.attributes['payment-sum-price']))
      }
    });
    let sumPrice = price.length > 0 ? price.reduce(function(a,b){return a + b}) : 0
    setMonthly(numberWithDelimiter(sumPrice))
  }

  // 通知バー
  const displayNotification = (message, valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {loading ? <Loading /> :
        <React.Fragment>
          {/* ## TODO 分析機能実装完了後縮退解除 */}
          {/* <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              <CounterIconsWidget daily={daily} monthly={monthly}/>
            </Grid>
          </Grid> */}
        { paymentHistoryData &&
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <PapperBlock title="最近の取引" whiteBg icon="ios-list" desc="">
                {paymentHistoryData.data.length === 0 ? "最近の取引はありません" : <RecentlyList paymentHistoryData={paymentHistoryData} role={role}/>}
              </PapperBlock>
            </Grid>
          </Grid>
        }
        </React.Fragment>
      }
      <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
    </div>
  );
}

TopPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingPaymentHistoryIndex: state.getIn(['paymentHistoryReducers', 'loadingPaymentHistoryIndex']),
    loadingAnalysisPeriodIndex: state.getIn(['analysisPeriodReducers', 'loadingAnalysisPeriodIndex']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexPaymentHistory: data => {
      dispatch(onFetchIndexPaymentHistoryAction(data));
    },
    onFetchIndexAnalysisPeriod: data => {
      dispatch(onFetchIndexAnalysisPeriodAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopPage);

export default withStyles(styles)(withCookies(withRouter(FormInit)));
