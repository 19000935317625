import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";
function* postCreateCompany(data) {
  const url = `${API.samuraiApiUrl}/companies`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}
function* getIndexCompany(data) {
  const url = `${API.samuraiApiUrl}/companies`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}
function* getShowCompany(data) {
  const url = `${API.samuraiApiUrl}/companies/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}
function* putUpdateCompany(data) {
  const url = `${API.samuraiApiUrl}/companies/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}
function* deleteDeleteCompany(data) {
  const url = `${API.samuraiApiUrl}/companies/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}
export const Api = {
  postCreateCompany,
  getIndexCompany,
  getShowCompany,
  putUpdateCompany,
  deleteDeleteCompany
};
