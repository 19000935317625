import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment  from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, getFormValues } from 'redux-form/immutable';
import { TextFieldRedux, NakedTextFieldRedux, SelectRedux, DateTimeRedux } from '../Forms/ReduxFormMUI';
import styles from '../Forms/user-jss';
import * as Validator from '../../utils/validate';

function PriceChangeDialog(props) {
  const {
    classes,
    dialogData,
    handleSubmit
  } = props;

  useEffect(() => {
    setOpen(dialogData.open)
  }, [dialogData]);

  const [open, setOpen] = useState(false);

  function handleClose() {
    
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'決済金額変更'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1} justify="center">
            {/* <Grid item xs={12}>
              <DialogContentText id="alert-dialog-description">
                変更金額
              </DialogContentText>
            </Grid> */}
            <Grid container item xs={12} sm={6} alignItems="flex-start">
            <FormControl className={classes.formControl}>
              <Field
                name="change_price"
                label="変更金額"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">円</InputAdornment>,
                }}
                component={TextFieldRedux}
                validate={[Validator.required, Validator.price]}
                required
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}></Grid>
          {/* <Grid item xs={12}>
            <DialogContentText id="alert-dialog-description">
              決済パスワード
            </DialogContentText>
          </Grid> */}
          <Grid container item xs={12} sm={6} alignItems="flex-start">
            <FormControl className={classes.formControl}>
              <Field
                name="payment_password"
                component={TextFieldRedux}
                type="password"
                label="決済パスワード"
                required
                validate={[Validator.required]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}></Grid>
          </Grid>
          <div className={classes.btnArea}>
            <Grid container spacing={3} justify="center">
              <Button variant="contained" color="primary" size="large" type="submit" onClick={handleClose} disabled={props.invalid} >
                更新
              </Button>
            </Grid>
          </div>
        </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PriceChangeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const FormReduxed = reduxForm({
  form: 'paymentPriceChamgeForm',
  enableReinitialize: true,
})(PriceChangeDialog);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration'])
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);