import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import brand from 'dan-api/dummy/brand';
import logo from 'dan-images/logo.svg';
import { TextFieldRedux } from './ReduxFormMUI';
import styles from './user-jss';
import * as Validator from '../../utils/validate'
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

// validation functions
const required = value => (value === null ? 'Required' : undefined);
const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email'
    : undefined
);

function ResetPasswordSendMailForm(props) {
  const {
    classes,
    handleSubmit,
    pristine,
    submitting,
    deco,
  } = props;
  return (
    <Paper className={classNames(classes.paperWrap, deco && classes.petal)}>
      <div className={classes.topBar}>
        <NavLink to="/" className={classes.brand}>
          <img src={logo} alt={brand.name} />
          {brand.name}
        </NavLink>
      </div>
      <Typography variant="h4" className={classes.title} gutterBottom>
        パスワードをリセットします
      </Typography>
      <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
        パスワードリセットのリンクを送信します
      </Typography>
      <section className={classes.formWrap}>
        <form onSubmit={handleSubmit}>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="company"
                    component={TextFieldRedux}
                    placeholder="企業コード"
                    label="企業コード"
                    required
                    validate={[Validator.required]}
                    className={classes.field}
                  />
                </FormControl>
                </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="login"
                    component={TextFieldRedux}
                    placeholder="ログインID"
                    label="ログインID"
                    required
                    validate={[Validator.required]}
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="last_name"
                    label="姓"
                    fullWidth
                    component={TextFieldRedux}
                    validate={[Validator.required]}
                    required
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="first_name"
                    label="名"
                    fullWidth
                    component={TextFieldRedux}
                    validate={[Validator.required]}
                    required
                    className={classes.field}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.btnArea}>
            <Grid container justify="center">
              <Button variant="outlined" color="primary" component={Link} to="/admin/login">           
                戻る
              </Button>
              <Button variant="contained" color="primary" type="submit">
                送信
                <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
              </Button>
            </Grid>
          </div>
        </form>
      </section>
    </Paper>
  );
}

ResetPasswordSendMailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const ResetPasswordSendMailFormReduxed = reduxForm({
  form: 'immutableEResetFrm',
  enableReinitialize: true,
})(ResetPasswordSendMailForm);

const reducer = 'ui';
const RegisterFormMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration'])
  }),
)(ResetPasswordSendMailFormReduxed);

export default withStyles(styles)(RegisterFormMapped);
