import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* postCreateBulkPayment(data) {
  const url = `${API.samuraiApiUrl}/bulk_payments`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getIndexBulkPayment(data) {
  const url = `${API.samuraiApiUrl}/bulk_payments`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowBulkPayment(data) {
  const url = `${API.samuraiApiUrl}/bulk_payments/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdateBulkPayment(data) {
  const url = `${API.samuraiApiUrl}/bulk_payments/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteDeleteBulkPayment(data) {
  const url = `${API.samuraiApiUrl}/bulk_payments/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  postCreateBulkPayment,
  getIndexBulkPayment,
  getShowBulkPayment,
  putUpdateBulkPayment,
  deleteDeleteBulkPayment
};
