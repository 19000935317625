import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* postCreateMailPayment(data) {
  const url = `${API.samuraiApiUrl}/mail_payments`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getIndexMailPayment(data) {
  const url = `${API.samuraiApiUrl}/mail_payments`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowMailPayment(data) {
  const url = `${API.samuraiApiUrl}/mail_payments/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdateMailPayment(data) {
  const url = `${API.samuraiApiUrl}/mail_payments/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteDeleteMailPayment(data) {
  const url = `${API.samuraiApiUrl}/mail_payments/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postTestSendMailPayment(data) {
  const url = `${API.samuraiApiUrl}/mail_payments/test_send`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  postCreateMailPayment,
  getIndexMailPayment,
  getShowMailPayment,
  putUpdateMailPayment,
  deleteDeleteMailPayment,
  postTestSendMailPayment
};
