import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import SearchForm from '../../../components/Forms/SearchForm';
import List from '../../../components/Lists/List';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchIndexCompanyAction } from '../../../redux/actions/company';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import {enumToJa} from '../../../utils/format';
import Loading from '../../../components/Loading/index'
import NotFound from '../../NotFound/NotFound';

function Index(props) {
  const { classes } = props;
  const { companies } = props.internalValues
  const title = brand.name + ' - 加盟店一覧';
  const description = brand.desc;
  const url = '/admin/company/'
  const internalValues = props.internalValues;
  // メッセージ関連
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }
  
  const [companyData, setCompanyData] = useState("")
  // 検索項目
  const [searchItems, setSearchItems] = useState(
    [
      { label: "ステータス", name: "status", value: "", formType: "select", menuItems: [{key: '有効', value: '1'}, {key: '無効', value: '2'}, {key: '仮登録', value: '3'}]},
      // { label: "プラン", name: "plan", value: "", formType: "select", menuItems: [{key: 'ライト', value: '1'}, {key: 'スタンダード', value: '2'}, {key: 'プレミアム', value: '3'}]},
      { label: "契約開始日", name: "contract_period_from", value: null, formType: "date" },
      { label: "契約終了日", name: "contract_period_to", value: null, formType: "date" },
      { label: "加盟店", name: "company_id", value: "", formType: "select", super: true,
        menuItems: companies ? companies.map( (company) => ({key: company.name, value: company.id} )) : null
      }
    ]
  )
  const [listItems, setlistItems] = useState(
    [
      { label: "加盟店名" , name: 'name'},
      { label: "ステータス" , name: 'status', format: (value)=>{return enumToJa(value['status'])}},
      // { label: "プラン" , name: 'plan', format: (value)=>{return enumToJa(value['plan'])}},
      { label: "契約日" , name: 'date', format: (value)=>{return (value['contract-period-from']) + (" ~ ") + (value['contract-period-to'])}}
    ]
  )
  // 一覧
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState("")
  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    data.page = page
    props.onFetchIndexCompany({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setCompanyData(item)
        item.data.length == 0 ? noData() : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  }; 

  const noData = () => {
    displayNotification('検索結果がありませんでした', 'warning')
  }

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  return (
    <React.Fragment>
      {internalValues.role === 'company' ? <NotFound /> :
        <div>
          <Helmet>
          <title>{title}</title>
          </Helmet>
          <PapperBlock title="加盟店検索" whiteBg icon="ios-search-outline" desc="">
            <section className={classes.formWrap}>
              <Grid container spacing={3}>
                { searchItems.map( (item, index) => {
                  return(
                    <Grid key={index} item xs={12} sm={6}>
                      <SearchForm searchItem={item} key={index} id={index} changeValue={changeValue} role={props.internalValues.role} />
                    </Grid>
                  )}
                  )}
              </Grid>
            </section>
              <div className={classes.btnArea}>
                <Button variant="contained" color="primary" size="large" onClick={submitForm}>
                  検索
                </Button>
              </div>
          </PapperBlock>
          {/*検索を開始するまでは一覧は表示しない
            検索を開始し、管理者一覧APIが終了するまではローディング画面
            管理者一覧APIが終了し、データが格納されている場合は一覧を表示
            管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない*/}
          {loading || companyData.data.length === 0 ? "" :
            <PapperBlock title="加盟店一覧" whiteBg icon="ios-list"desc="">
              { props.loadingIndexCompany ? 
                <div><List listItems={listItems} searchResult={companyData} url={url} submitForm={submitForm}/><Loading /></div> :
                <div><List listItems={listItems} searchResult={companyData} url={url} submitForm={submitForm}/></div>
              }
            </PapperBlock>
          }
          <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
        </div>
      }
    </React.Fragment>
  );
}
Index.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingIndexCompany: state.getIn(['companyReducers', 'loadingIndexCompany'])
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexCompany: data => {
      dispatch(onFetchIndexCompanyAction(data));
    }
  };
};
const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
export default withStyles(styles)(withCookies(withRouter(FormInit)));