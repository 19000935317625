import {paymentFormAction} from "../constants/actionTypes";

export const onFetchCreatePaymentFormAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_CREATE_PAYMENT_FORM,
    data
  }
};

export const onFetchIndexPaymentFormAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_INDEX_PAYMENT_FORM,
    data
  }
};

export const onFetchShowPaymentFormAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_SHOW_PAYMENT_FORM,
    data
  }
};

export const onFetchUpdatePaymentFormAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM,
    data
  }
};

export const onFetchDeletePaymentFormAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_DELETE_PAYMENT_FORM,
    data
  }
};

export const onFetchUpdatePaymentFormItemAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM_ITEM,
    data
  }
};

export const onFetchUpdatePaymentFormPlanAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM_PLAN,
    data
  }
};

export const onFetchQrIssuePaymentFormAction = (data) => {
  return {
    type: paymentFormAction.ON_FETCH_QR_ISSUE_PAYMENT_FORM,
    data
  }
};