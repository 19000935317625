import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';

export default function BackDialog(props) {
  const {
    color,
    className,
    message,
    url,
    variant,
    size
  } = props;

  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    deleteForm();
    setOpen(false);
  }

  return (
    <div>
      <Button variant={variant} color={color} size={size} className={className} onClick={handleClickOpen}>
        {message}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'決済フォーム詳細画面に遷移しますか?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            更新途中の内容は破棄されます！
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            戻らない
          </Button>
          <Button component={Link} to={url} color="primary">
            戻る
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}