import { exportLogAction } from "../constants/actionTypes";

export const onFetchIndexExportLogAction = data => {
  return {
    type: exportLogAction.ON_FETCH_INDEX_EXPORT_LOG,
    data
  };
};

export const onFetchDeleteExportLogAction = data => {
  return {
    type: exportLogAction.ON_FETCH_DELETE_EXPORT_LOG,
    data
  };
};

export const onFetchDownloadExportLogAction = data => {
  return {
    type: exportLogAction.ON_FETCH_DOWNLOAD_EXPORT_LOG,
    data
  };
};
