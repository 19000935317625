import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import ItemForm from './ItemForm';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchShowPaymentFormAction, onFetchUpdatePaymentFormItemAction } from "../../../redux/actions/paymentForm";
import accessToken from '../../../utils/accessToken';
import Loading from '../../../components/Loading/index'

function ItemEdit(props) {
  const title = brand.name + ' - 決済フォーム項目詳細';
  const description = brand.desc;
  const { match } = props;
  const [loading, setLoading] = useState(true);
  
  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const [formItemData, setFormItemData] = useState();

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  } 

  const updateDataValues = dataValues => {
    return dataValues.set("form_items_attributes", dataValues.get("form_items_attributes").map(updateFormItemAttributes));
  }

  const updateFormItemAttributes = values => {
    return values.set("form_item_selects_attributes", values.get("form_item_selects_attributes").map(updateFormItemSelectsAttributes));
  }

  const updateFormItemSelectsAttributes = values => {
    const formItemsAttributes = values.get("form_items_attributes");
    if (formItemsAttributes) {
      return values.set("form_items_attributes", formItemsAttributes.map(updatePosition));
    } else {
      return values;
    } 
  }

  const updatePosition = (values, index) => {
    return values.set('position', index + 1);
  }

  const getAccessToken = accessToken(props);

  // 詳細取得
  useEffect(() => {
    fetchForm()
  }, []);

  const fetchForm = values => {
    props.onFetchShowPaymentForm({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onSuccess: async data => {
        setFormItemData({payment_form_type: data.payment_form_type, is_cvs: data.is_cvs, form_items_attributes: [...data.form_items_attributes]})
      },
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 更新
  const submitForm = values => {
    let dataValues = values
    dataValues = dataValues.set("form_items_attributes",dataValues.get("form_items_attributes").map((v, k) => v.set('position', k + 1)))
    dataValues = dataValues.delete("payment_form_type")
    dataValues = dataValues.delete("is_cvs")
    dataValues = updateDataValues(dataValues);
    dataValues.id = match.params.id
    props.onFetchUpdatePaymentFormItem({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        fetchForm()
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };
  
  return (
    <div>
      {loading || props.loadingPaymentFormShow ? <Loading /> : 
        <div>
          <Helmet>
            <title>{title}</title>
          </Helmet>
            <PapperBlock title="決済フォーム項目詳細" whiteBg icon="ios-contact-outline" desc="">
              <div>
              {/* 決済フォーム情報取得Apiが終了するまでは、空のフォームを表示させる */}
              <ItemForm onSubmit={(values) => submitForm(values)} formItemData={formItemData} setFormItemData={setFormItemData} initialValues={formItemData} id={match.params.id} getAccessToken={getAccessToken} />
              </div>
            </PapperBlock>
          <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
        </div>
      }
    </div>
  );
}

const mapStateToProps = state => {
  return {
    paymentFormData: state.getIn(['paymentFormReducers', 'paymentFormData']),
    loadingPaymentFormShow: state.getIn(['paymentFormReducers', 'loadingPaymentFormShow']),
    loadingPaymentFormData: state.getIn(['paymentFormReducers', 'loadingPaymentFormData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShowPaymentForm: data => {
      dispatch(onFetchShowPaymentFormAction(data));
    },
    onFetchUpdatePaymentFormItem: data => {
      dispatch(onFetchUpdatePaymentFormItemAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemEdit);

export default withCookies(withRouter(FormInit));