/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import history from '../utils/history';

import languageProviderReducer from '../containers/LanguageProvider/reducer';
import login from './reducers/login';
import uiReducer from './reducers/ui';
import initval from './reducers/initForm';
import accountReducers from "./reducers/account";
import internalReducers from "./reducers/internal";
import errorReducers from "./reducers/error";
import userReducers from "./reducers/user";
import paymentHistoryReducers from "./reducers/paymentHistory";
import paymentFormReducers from "./reducers/paymentForm";
import mailPaymentReducers from "./reducers/mailPayment";
import mailPaymentRecipientReducers from "./reducers/mailPaymentRecipient";
import bulkPaymentReducers from "./reducers/bulkPayment";
import billingStatementReducers from "./reducers/billingStatement";
import analysisPeriodReducers from "./reducers/analysisPeriod";
import adminUserReducers from "./reducers/adminUser";
import companyReducers from "./reducers/company";
import qaInformationReducers from "./reducers/qaInformation";
import qaCategoryReducers from "./reducers/qaCategory";
import exportLogReducers from "./reducers/exportLog";


/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    login,
    accountReducers,
    companyReducers,
    userReducers,
    paymentHistoryReducers,
    paymentFormReducers,
    mailPaymentReducers,
    mailPaymentRecipientReducers,
    bulkPaymentReducers,
    billingStatementReducers,
    qaInformationReducers,
    qaCategoryReducers,
    analysisPeriodReducers,
    internalReducers,
    errorReducers,
    ui: uiReducer,
    initval,
    adminUserReducers,
    exportLogReducers,
    language: languageProviderReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
