import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 14,
  },
  placeholder: {
    position: 'absolute',
    left: 8,
    bottom: 6,
    fontSize: 14,
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(-1),
    left: 0,
    right: 0,
  },
}));

function NoOptionsMessage(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

NoOptionsMessage.defaultProps = {
  children: null,
  innerProps: null
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

inputComponent.defaultProps = {
  inputRef: undefined
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, meta: { touched, error }},
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
      error={touched && Boolean(error)}
      helperText={touched && error && (
        <div style={{ color: 'red', position: 'absolute', top: '100%', left: 0 }}>
          {error}
        </div>
      )}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

Control.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: undefined
};

function Option(props) {
  const {
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children
  } = props;
  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

Option.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: undefined,
  isFocused: false,
  isSelected: false
};

function Placeholder(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

Placeholder.defaultProps = {
  children: null,
  innerProps: null,
};

function SingleValue(props) {
  const { selectProps, children, innerProps } = props;
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

SingleValue.defaultProps = {
  children: null,
  innerProps: null,
};

function ValueContainer(props) {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

ValueContainer.defaultProps = {
  children: null,
};

function MultiValue(props) {
  const {
    children,
    selectProps,
    removeProps,
    isFocused
  } = props;
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

MultiValue.defaultProps = {
  children: null,
  isFocused: false,
};

function Menu(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

Menu.defaultProps = {
  children: null,
  innerProps: null,
  selectProps: null,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export default function SelectSuggestions({meta: { touched, error }, formValues, input, disabled, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const [single, setSingle] = useState(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (input.value == null || input.value == undefined) {
      setSingle(null);
    } else {
      if (rest.options) {
        const matchingOption = rest.options.find(option => option.value === String(input.value));
        if (matchingOption) {
          setSingle(matchingOption);
        }
      }
    }
  }, [input.value, rest.options]);

  // 加盟店を変更した場合、値をリセット
  useEffect(() => {
    if (formValues && single !== null) {
      setSingle(null);
      input.onChange(null);
    }
  }, [formValues && formValues.get("company_id")]);

  function handleChangeSingle(value) {
    setSingle(value);
    const newValue = input.onChange(value.value);
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div ref={selectRef}>
      <NoSsr>
        <Select
          {...input}
          {...rest}
          classes={classes}
          styles={selectStyles}
          components={components}
          value={single}
          onChange={handleChangeSingle}
          onBlur={() => setIsOpen(false)}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => {
            setIsOpen(false);
            input.onBlur(); // セレクトボックスのメニューが閉じられた際に onBlur イベントを発生させる
          }}
          open={isOpen}
          isDisabled={disabled}
          meta={{
            touched: touched,
            error: error
          }}
        />
      </NoSsr>
    </div>
  );
}