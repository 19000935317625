import { mailPaymentAction, mailPaymentRecipientAction } from "../constants/actionTypes";

export const onFetchIndexMailPaymentRecipientAction = (data) => {
  return {
    type: mailPaymentRecipientAction.ON_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT,
    data
  }
};
export const onFetchShowMailPaymentRecipientAction = (data) => {
  return {
    type: mailPaymentRecipientAction.ON_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT,
    data
  }
};
export const onFetchUpdateMailPaymentRecipientAction = (data) => {
  return {
    type: mailPaymentRecipientAction.ON_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT,
    data
  }
};
export const onFetchDeleteMailPaymentRecipientAction = (data) => {
  return {
    type: mailPaymentRecipientAction.ON_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT,
    data
  }
};
