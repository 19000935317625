import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import accessToken from '../../../utils/accessToken';
import NotFound from '../../NotFound/NotFound';
import { 
  onFetchCreateQaCategoryAction,
  onFetchShowQaCategoryAction,
  onFetchUpdateQaCategoryAction,
  onFetchDeleteQaCategoryAction,
  clearQaCategoryAction
} from "../../../redux/actions/qaCategory";

function Edit(props) {
  const description = brand.desc;
  const { match } = props;

  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const [isNew, setIsNew] = useState(false);
  const [titleName, setTitleName] = useState("");
  const internalValues = props.internalValues;
  const url = '/admin/qa_category/index'

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  // 詳細取得
  useEffect(() => {
    const url = props.path.split('/')
    if (url[url.length - 1] === 'new') {
      setIsNew(true)
      setTitleName("QAカテゴリ登録")
      props.clearQaCategory()
    } else {
      fetchForm()
      setTitleName("QAカテゴリ詳細")
    }
  }, []);

  const fetchForm = values => {
    props.onFetchShowQaCategory({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
  };
  
  // 登録
  const newForm = values => {
    props.onFetchCreateQaCategory({
      accessToken: accessToken(props),
      data: values,
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "登録が完了しました", variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 更新
  const editForm = values => {
    let dataValues = values
    dataValues.id = match.params.id
    props.onFetchUpdateQaCategory({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 削除
  const deleteForm = values => {
    props.onFetchDeleteQaCategory({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "QAカテゴリを削除しました", variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };
  
  return (
    <React.Fragment>
      {internalValues.role === 'company' ? <NotFound /> :
        <div>
          <Helmet>
            <title>{brand.name + " - " + titleName}</title>
          </Helmet>
          <PapperBlock title={titleName} whiteBg icon="ios-person-outline" desc="">
            <Form onSubmit={(values) => isNew ? newForm(values) : editForm(values)} deleteForm={deleteForm} internalValues={props.internalValues} url={url} action={isNew} />
          </PapperBlock>
          <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
        </div>
      }
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    qaCategoryData: state.getIn(['qaCategoryReducers', 'qaCategoryData']),
    loadingQaCategoryCreate: state.getIn(['qaCategoryReducers', 'loadingQaCategoryCreate']),
    loadingQaCategoryShow: state.getIn(['qaCategoryReducers', 'loadingQaCategoryShow']),
    loadingQaCategoryData: state.getIn(['qaCategoryReducers', 'loadingQaCategoryData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCreateQaCategory: data => {
      dispatch(onFetchCreateQaCategoryAction(data));
    },
    onFetchShowQaCategory: data => {
      dispatch(onFetchShowQaCategoryAction(data));
    },
    onFetchUpdateQaCategory: data => {
      dispatch(onFetchUpdateQaCategoryAction(data));
    },
    onFetchDeleteQaCategory: data => {
      dispatch(onFetchDeleteQaCategoryAction(data));
    },
    clearQaCategory: data => {
      dispatch(clearQaCategoryAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(FormInit));