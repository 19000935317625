import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from '@material-ui/pickers';
import InputBase from '@material-ui/core/InputBase';
import { ThemeProvider } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "yyyy MMM", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "MMMd日", { locale: this.locale });
  }
}

/* Textfield */
export const TextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => (
  <TextField
    {...rest}
    {...input}
    error={touched && Boolean(error)}
    helperText={touched && error}
  />
);

TextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* ThemeProviderTextfield */
export const ThemeProviderTextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => (
  <ThemeProvider theme={theme}>
    <TextField
      {...rest}
      {...input}
      id="mui-theme-provider-standard-input"
      error={touched && Boolean(error)}
      helperText={touched && error}
    />
  </ThemeProvider>
);

TextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* NakedTextField */
export const NakedTextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => (
  <InputBase
    {...rest}
    {...input}
    inputProps={{ 'aria-label': 'naked' }}
    error={touched && Boolean(error)}
    helperText={touched && error}
  />
);

NakedTextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

NakedTextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* Select */
export const SelectRedux = ({ meta: { touched, error }, input, children, ...rest }) => (
  <TextField
    select
    {...input}
    {...rest}
    error={touched && Boolean(error)}
    helperText={touched && error}
  >
    {children}
  </TextField>
);

SelectRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
/* End */

/* Checkbox */
export const CheckboxRedux = ({ input, ...rest }) => (
  <Checkbox
    checked={input.value === '' ? false : input.value}
    {...input}
    {...rest}
  />
);

CheckboxRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* Switch */
export const SwitchRedux = ({ input, ...rest }) => (
  <Switch
    checked={input.value === '' ? false : input.value}
    {...input}
    {...rest}
  />
);

SwitchRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* Radio */
export const renderRadioGroup = ({ meta: { touched, error }, input, ...rest }) => (
  <>
    <RadioGroup
      {...input}
      {...rest}
      valueselected={input.value}
      onChange={(event, value) => input.onChange(value)}
    />
    {touched && error && <FormHelperText error>{error}</FormHelperText>}
  </>
);
/* End */

/* Date */
export const DateRedux = ({ meta: { touched, error }, input, isTime, ...rest }) => (
  <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
    <DatePicker
      {...rest}
      {...input}
      value={input.value ? input.value : null}
      format={isTime ? "yyyy/MM/dd HH:mm" : "yyyy/MM/dd"}
      animateYearScrolling={false}
      okLabel="決定"
      cancelLabel="キャンセル"
      clearLabel="削除"
      clearable
      error={touched && Boolean(error)}
      helperText={touched && error}
    />
  </MuiPickersUtilsProvider>
);
DateRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* DateTime */
export const DateTimeRedux = ({ meta: { touched, error }, input, isTime, ...rest }) => (
  <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
    <DateTimePicker
      {...rest}
      {...input}
      value={input.value ? input.value : null}
      format={"yyyy/MM/dd HH:mm"}
      okLabel="決定"
      cancelLabel="キャンセル"
      clearLabel="削除"
      clearable
      error={touched && Boolean(error)}
      helperText={touched && error}
    />
  </MuiPickersUtilsProvider>
);
DateTimeRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* DateTimeMinutesStep */
export const DateTimeMinutesStepRedux = ({ meta: { touched, error }, input, isTime, ...rest }) => {

  // 切り上げ処理を行う関数
  const roundUpTime = (time) => {
    const roundedTime = new Date(time);
    const roundedMinutes = Math.ceil(roundedTime.getMinutes() / rest.minutesStep) * rest.minutesStep;
    roundedTime.setMinutes(roundedMinutes);
    return roundedTime;
  };

  useEffect(() => {
    // input.value が変更されたときに、時間を切り上げて表示
    if (input.value) {
      input.onChange(roundUpTime(input.value, 5));
    }
  }, [input.value]);
  
  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <DateTimePicker
        {...rest}
        {...input}
        value={input.value ? input.value : null}
        format={"yyyy/MM/dd HH:mm"}
        okLabel="決定"
        cancelLabel="キャンセル"
        clearLabel="削除"
        clearable
        error={touched && Boolean(error)}
        helperText={touched && error}
      />
    </MuiPickersUtilsProvider>
  );
};
DateTimeMinutesStepRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */