import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function TabBackDialog(props) {  
  const {
    color,
    className,
    message,
    variant,
    size,
    isOpen,
    handleTabChange,
    onClose
  } = props;

  if (!isOpen) return null;

  return (
    <div>
      <Button variant={variant} color={color} size={size} className={className}>
        {message}
      </Button>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'タブ遷移しますか?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            更新途中の内容は破棄されます！
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTabChange} color="primary">
            遷移する
          </Button>
          <Button onClick={onClose} color="primary">
            戻る
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}