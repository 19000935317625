import {paymentHistoryAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchIndexPaymentHistoryAction(action) {
  const result = yield Api.getIndexPaymentHistory(action.data);
  yield put({type: paymentHistoryAction.END_FETCH_INDEX_PAYMENT_HISTORY, result: result});
}

function* onFetchShowPaymentHistoryAction(action) {
  const result = yield Api.getShowPaymentHistory(action.data);
  yield put({type: paymentHistoryAction.END_FETCH_SHOW_PAYMENT_HISTORY, result: result});
}

function* onFetchUpdatePaymentHistoryAction(action) {
  const result = yield Api.putUpdatePaymentHistory(action.data);
  yield put({type: paymentHistoryAction.END_FETCH_UPDATE_PAYMENT_HISTORY, result: result});
}

function* onFetchExportPaymentHistoryAction(action) {
  const result = yield Api.getExportPaymentHistory(action.data);
  yield put({type: paymentHistoryAction.END_FETCH_EXPORT_PAYMENT_HISTORY, result: result});
}

export function* watchOnFetchPaymentHistory() {
  yield takeLatest(paymentHistoryAction.ON_FETCH_INDEX_PAYMENT_HISTORY, onFetchIndexPaymentHistoryAction);
  yield takeLatest(paymentHistoryAction.ON_FETCH_SHOW_PAYMENT_HISTORY, onFetchShowPaymentHistoryAction);
  yield takeLatest(paymentHistoryAction.ON_FETCH_UPDATE_PAYMENT_HISTORY, onFetchUpdatePaymentHistoryAction);
  yield takeLatest(paymentHistoryAction.ON_FETCH_EXPORT_PAYMENT_HISTORY, onFetchExportPaymentHistoryAction);
}
