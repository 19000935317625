import Axios from "axios";
import {csrfToken} from "@rails/ujs"
import {put} from "redux-saga/effects";
import {errorAction} from "../redux/constants/actionTypes"

export default function* callApi(method, url, data = null, token = null, params = null, onSuccess = null, onError = null, onErrorWithNotice = null) {
  let isChecked = false;

  Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
  
  try {
    const config = {
      method: method.toUpperCase(),
      url: url,
      timeout: 30000,
      headers: token ? {
        Authorization: `${token}`,
      } : {},
      data,
      params
    };
    // TODO: 「responseType」に「blob」を設定する時の条件をパス以外で判別したい
    // CSVエクスポート/画像のダウンロードをする場合「responseType」に「blob」を設定する
    if (/\/export_logs\/\d+\/download|users\/download_image/.test(url)) {
      config.responseType = "blob";
    }
    let response = false;
    response = yield Axios(config).then((response) => {
      if (onSuccess && typeof onSuccess === "function") {
        if (/\/export_logs\/\d+\/download|users\/download_image/.test(url)) {
          // CSVエクスポート/画像のダウンロードをする場合「response」を返却する
          onSuccess(response);
        } else {
          onSuccess(response.data);
        }
      }
      return response;
    }, (error) => {
      if (onErrorWithNotice && typeof onErrorWithNotice === 'function') {
        throw error;
      } else if (onError && typeof onError === 'function') {
        onError(error);
        isChecked = true;
      } else {
        throw error
      }
    });
    return response.data;
  } catch (error) {
    if (!isChecked) {
      if (error.response) {
        const response = error.response;
        if (response.status === 401) {
          let errorText = '認証エラーです。ログインし直してください';
          if (JSON.stringify(response.data).includes("error")) {
            errorText = JSON.stringify(response.data.error).replace(/^"(.*)"$/, '$1');
          };
          onErrorWithNotice(errorHandling(errorText));
        } else if (response.data) {
          if (response.data.error) {
            onErrorWithNotice(errorHandling(response.data.error));
          } else {
            onErrorWithNotice('Something went wrong');
          }
          return null;
        }
      } else {
        onErrorWithNotice("接続がタイムアウトしました。電波状況の良いところで再度お試しください");
      }
    }

    return null;
  }
}

export function errorHandling(errorText) {
  switch (errorText) {
    case 'Access Denied. May be token is expired or invalid.':
      errorText = "認証エラーです ログインし直してください";
      break;
    case 'Access Denied. Please set token.':
      errorText = "認証エラーです ログインし直してください";
      break;
    case 'Please set company_code':
      errorText = "企業コードが設定されていません";
      break;
    case 'Company not found':
      errorText = "ログインID、パスワード、企業コードが間違っています";
      break;
    case 'Please set password':
      errorText = "パスワードが入力されていません";
      break;
    case 'Please set login':
      errorText = "ログインIDが入力されていません";
      break;
    case 'Password matches login ID':
      errorText = "パスワードがログインIDと一致しています";
      break;
    case 'Invalid login_id or password':
      errorText = "ログインID、パスワード、企業コードが間違っています";
      break;
    case 'Invalid login_id':
      errorText = "ログインIDが間違っています";
      break;
    case 'Invalid company or login_id or name':
      errorText = "企業コードまたはログインIDまたは氏名が間違っています";
      break;
    case 'Invalid password':
      errorText = "パスワードが間違っています";
      break;
    case 'メールアドレスはすでに登録されています。':
      errorText = "アカウントの作成に失敗しました\n" +
        "メールアドレスはすでに登録されています。";
      break;
    case 'form_item_selects_attributes must be set.':
      errorText = "選択項目を1つ以上設定してください";
      break;
    case 'payment_services_attributes must be set.':
      errorText = "サービスを1つ以上設定してください";
      break;
    case 'payment_plans_attributes must be set.':
      errorText = "プランを1つ以上設定してください";
      break;
    case 'form_item_name must be uniq.':
      errorText = "同じフォーム項目名は2つ以上登録できません";
      break;
    case 'cvs_form_item does not exist':
      errorText = "コンビニ決済を設定するには「名前(姓)」「名前(セイ)」「固定電話番号」を必須項目にしてください";
      break;
    case 'User is locked':
      errorText = "連続して不正なパスワードが入力されたため、アカウントをロックしました";
      break;
    case 'User is locked in two factor authentication':
      errorText = "連続して不正なコードが入力されたため、アカウントをロックしました";
      break;
    case 'Company does not exist':
      errorText = "加盟店を設定してください";
      break;
    case 'PaymentForm does not exist':
      errorText = "決済フォームを設定してください";
      break;
    case 'Invalid two-factor token':
      errorText = "認証エラーです、ログイン画面から再度ログインしてください";
      break;
    case 'Two-factor token has expired':
      errorText = "有効期限が切れました、認証コードを再送してください";
      break;
    case 'Invalid two-factor code':
      errorText = "認証コードが間違っています";
      break;
    case 'Csv must be set.':
      errorText = "CSVファイルを設定してください";
      break;
    case 'Not Csv.':
      errorText = "設定したファイルはCSVファイルではありません。CSVファイルを設定してください";
      break;
    case 'Too many rows in CSV.':
      errorText = "10000行を超えるCSVファイルは設定できません";
      break;
    case 'Reset password token has expired.':
      errorText = "有効期限が切れました、再度やり直してください";
      break;
    default:
      if (errorText.includes('Name has already been taken')){
        errorText = "入力した名前は既に使用されています";
      } else if (errorText.includes('Payment services name has already been taken')){
        errorText = "同じサービス名は2つ以上登録できません";
      } else if (errorText.includes('Payment plans name has already been taken')){
        errorText = "同じプラン名は2つ以上登録できません";
      } else if (errorText.includes('Form items name has already been taken')){
        errorText = "同じフォーム項目名は2つ以上登録できません";
      } else if (errorText.includes('Login has already been taken')){
        errorText = "入力したログインIDは既に使用されています";
      } else if (errorText.includes('Code has already been taken')){
        errorText = "入力したコードは既に使用されています";
      } else if (errorText.includes('Email has already been taken')){
        errorText = "入力したメールアドレスは既に使用されています";
      } else if (errorText.includes('不正なファイルが添付されています。')){
        errorText = "不正なファイルが添付されています。";
      } else {
        errorText = "エラーが発生しました しばらくしてからもう一度お試しください";
      }
      break;
  }

  return errorText;
}
