import {accountAction} from "../constants/actionTypes";

export const onFetchCreateAccountAction = (data) => {
  return {
    type: accountAction.ON_FETCH_CREATE_ACCOUNT,
    data
  }
};

export const onFetchUserLoginAction = (data) => {
  return {
    type: accountAction.ON_FETCH_USER_LOGIN,
    data
  }
};

export const onFetchUserLogoutAction = (data) => {
  return {
    type: accountAction.ON_FETCH_USER_LOGOUT,
    data
  }
};

export const onFetchUserInfoAction = (data) => {
  return {
    type: accountAction.ON_FETCH_USER_INFO,
    data
  }
};

export const onFetchCreatePasswordReminderAction = (data) => {
  return {
    type: accountAction.ON_FETCH_CREATE_PASSWARD_REMINDER,
    data
  }
};

export const onFetchUpdatePasswordReminderAction = (data) => {
  return {
    type: accountAction.ON_FETCH_UPDATE_PASSWARD_REMINDER,
    data
  }
};

export const onFetchPostTwoFactorVerifyAction = (data) => {
  return {
    type: accountAction.ON_FETCH_POST_TWO_FACTOR_VERIFY,
    data
  }
};

export const onFetchPostTwoFactorResendAction = (data) => {
  return {
    type: accountAction.ON_FETCH_POST_TWO_FACTOR_RESEND,
    data
  }
};
