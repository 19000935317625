import API from "../../../constants/EnvConstants";
import callApi from "../../../utils/handleCallApi";

function* getIndexExportLog(data) {
  const url = `${API.samuraiApiUrl}/export_logs`;
  return yield callApi(
    "get",
    url,
    data.data,
    data.accessToken,
    data.data,
    data.onSuccess,
    data.onError,
    data.onErrorWithNotice
  );
}

function* deleteDeleteExportLog(data) {
  const url = `${API.samuraiApiUrl}/export_logs/${data.data.id}`;
  return yield callApi(
    "delete",
    url,
    data.data,
    data.accessToken,
    null,
    data.onSuccess,
    data.onError,
    data.onErrorWithNotice
  );
}

function* getDownloadExportLog(data) {
  const url = `${API.samuraiApiUrl}/export_logs/${data.data.id}/download`;
  return yield callApi(
    "get",
    url,
    data.data,
    data.accessToken,
    null,
    data.onSuccess,
    data.onError,
    data.onErrorWithNotice
  );
}

export const Api = {
  getIndexExportLog,
  deleteDeleteExportLog,
  getDownloadExportLog
};
