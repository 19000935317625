import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/indigo';
import cyan from '@material-ui/core/colors/cyan';

const colorfull = [
  pink[400],
  purple[500],
  lightBlue[500],
  blue[500],
  orange[600],
  lightGreen[600],
  indigo.A200,
  cyan[500],
];

export default colorfull;
