import {bulkPaymentAction} from "../constants/actionTypes";

export const onFetchCreateBulkPaymentAction = (data) => {
  return {
    type: bulkPaymentAction.ON_FETCH_CREATE_BULK_PAYMENT,
    data
  }
};

export const onFetchIndexBulkPaymentAction = (data) => {
  return {
    type: bulkPaymentAction.ON_FETCH_INDEX_BULK_PAYMENT,
    data
  }
};

export const onFetchShowBulkPaymentAction = (data) => {
  return {
    type: bulkPaymentAction.ON_FETCH_SHOW_BULK_PAYMENT,
    data
  }
};

export const onFetchUpdateBulkPaymentAction = (data) => {
  return {
    type: bulkPaymentAction.ON_FETCH_UPDATE_BULK_PAYMENT,
    data
  }
};

export const onFetchDeleteBulkPaymentAction = (data) => {
  return {
    type: bulkPaymentAction.ON_FETCH_DELETE_BULK_PAYMENT,
    data
  }
};

export const clearBulkPaymentAction = (data) => {
  return {
    type: bulkPaymentAction.CLEAR_BULK_PAYMENT,
    data
  }
};