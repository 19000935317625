import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux, DateTimeRedux, SwitchRedux, DateTimeMinutesStepRedux } from '../../../components/Forms/ReduxFormMUI';
import SelectSuggestions from '../../../components/Forms/SelectSuggestions';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import * as Validator from '../../../utils/validate';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import { enumToJa, numberWithDelimiter } from '../../../utils/format';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import CsvDropZone from 'dan-components/Forms/CsvDropZone';
import CsvUploadDialog from '../../../components/Dialog/CsvUploadDialog';

function Form(props) {

  const [status, setStatus] = useState("");
  const { role, companies } = props.internalValues

  const {
    classes,
    handleSubmit,
    deleteForm,
    testSendForm,
    formId,
    indexPaymentFormData,
    formValues,
    fetchIndexPaymentForm,
    isNew
  } = props;
  const statuses = ['unsent', 'sent', 'send_failed'];
  const paymentStatuses = ['not_pay', 'auth', 're_auth', 'paid', 'pay_cancel', 're_auth_capture', 'temporary', 'failed', 'auth_cancel'];

  // ログ取得
  useEffect(() => {
    if (formValues && formValues.get("company_id")) {
      // company_idが変更された場合の処理をここに記述
      const newCompanyId = formValues.get("company_id");

      formValues.delete("payment_form_id");
      fetchIndexPaymentForm(newCompanyId)
    }
  }, [formValues && formValues.get("company_id")]);

  const isSend = formValues && formValues.get("is_send")

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <PapperBlock title={isNew ? "配信設定" : "配信設定詳細"} whiteBg icon="ios-contact-outline" desc="">
        <section className={classes.formWrap}>
          <div>
            <Grid container spacing={3}>
              {role ==='super' &&
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="company_id"
                      label="加盟店名 *"
                      fullWidth
                      component={SelectRedux}
                      validate={[Validator.required]}
                      disabled={isNew ? false : true}
                    >
                      {companies.map( (company, key) => (<MenuItem value={company.id} key={key}>{company.name}</MenuItem>) )}
                    </Field>
                  </FormControl>
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="kind"
                    label="配信種別 *"
                    fullWidth
                    component={SelectRedux}
                    validate={[Validator.required]}
                    disabled={isNew ? false : true}
                  >
                    <MenuItem value="email">メールアドレス</MenuItem>
                    <MenuItem value="sms">SMS</MenuItem>
                    <MenuItem value="csv">CSV</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              {formValues && formValues.get("kind") === "email" &&
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="email"
                      label="メールアドレス *"
                      fullWidth
                      component={TextFieldRedux}
                      validate={[Validator.required, Validator.email]}
                      className={classes.field}
                      disabled={isSend}
                      type={props.internalValues.browsingRight == 1 || isNew ? 'text' : 'password'}
                    />
                  </FormControl>
                </Grid>
              }
              {formValues && formValues.get("kind") === "sms" &&
                <Grid item xs={12}  sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="tel"
                      label="電話番号 *"
                      fullWidth
                      component={TextFieldRedux}
                      validate={[Validator.required, Validator.tel]}
                      className={classes.field}
                      disabled={isSend}
                      type={props.internalValues.browsingRight == 1 || isNew ? 'text' : 'password'}
                    />
                  </FormControl>
                </Grid>
              }
              { isNew && formValues && (formValues.get("kind") === "csv" ) &&
                <FormControl className={classes.formControl}>
                  <Field
                    name="csv"
                    component={CsvDropZone}
                    acceptedFiles={['.csv']}
                    type="file"
                    showPreviews
                    files={(formValues && formValues.get("csv")) ? [formValues.get("csv")] : []}
                    maxSize={50000000000}
                    filesLimit={1}
                    text="ここにCSVファイルをドラッグ＆ドロップまたはクリックしてCSVファイルを選択してください"
                  />
                </FormControl>
              }
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="code"
                    className={classes.field}
                    component={TextFieldRedux}
                    placeholder="配信コード"
                    validate={[Validator.required, Validator.length]}
                    label="配信コード *"
                    disabled={isSend}
                  />
                </FormControl>
              </Grid>
              { !isNew && formValues && (formValues.get("kind") !== "csv" ) &&
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="no"
                      className={classes.field}
                      component={TextFieldRedux}
                      placeholder="配信決済番号"
                      label="配信決済番号 *"
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              }
              {formValues && (formValues.get("kind") === "email" || formValues.get("kind") === "sms" ) &&
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="payment_form_id"
                      label='決済フォーム *'
                      component={SelectSuggestions}
                      inputId="react-select-single"
                      TextFieldProps={{
                        label: '決済フォーム *',
                        InputLabelProps: {
                          htmlFor: 'react-select-single',
                          shrink: true,
                        },
                        disabled: isSend
                      }}
                      placeholder='決済フォームを検索し選択してください'
                      formValues={formValues}
                      validate={[Validator.required]}
                      options={
                        indexPaymentFormData && indexPaymentFormData.length > 0 &&
                        indexPaymentFormData
                          .filter(paymentForm => paymentForm.attributes['payment-form-type'] === 'normal_payment')
                          .map((paymentForm, key) => ({
                            value: paymentForm.id,
                            label: paymentForm.attributes.name
                          }))
                      }
                      className={classes.field}
                      initialValue={formValues && formValues.payment_form_id}
                      isDisabled={isSend}
                    />
                  </FormControl>
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="delivery_at"
                    label="配信日時 *"
                    component={DateTimeMinutesStepRedux}
                    validate={[Validator.required]}
                    className={classes.field}
                    disabled={isSend}
                    minutesStep={5}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="limit_date"
                    label="決済メール有効期限 *"
                    component={DateTimeMinutesStepRedux}
                    validate={[Validator.required]}
                    className={classes.field}
                    disabled={isSend}
                    minutesStep={5}
                  />
                </FormControl>
              </Grid>
              {formValues && (formValues.get("kind") === "email" || formValues.get("kind") === "sms" ) &&
                <>
                  {formValues && formValues.get("kind") === "email" &&
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="subject"
                          className={classes.field}
                          component={TextFieldRedux}
                          placeholder="件名"
                          validate={[Validator.required, Validator.subjectMaxlength]}
                          label="件名 *"
                          disabled={isSend}
                        />
                      </FormControl>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Field
                        name="body"
                        className={classes.field}
                        component={TextFieldRedux}
                        placeholder="メッセージ"
                        label="メッセージ"
                        validate={[Validator.messageMaxlength]}
                        multiline={true}
                        rows={10}
                        disabled={isSend}
                      />
                    </FormControl>
                  </Grid>
                  {isNew && formValues && formValues.get("kind") === "email" &&
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="test_email"
                          label="テスト配信メールアドレス"
                          fullWidth
                          component={TextFieldRedux}
                          // validate={[Validator.email]}
                          className={classes.field}
                          disabled={isSend}
                        />
                      </FormControl>
                    </Grid>
                  }
                  {isNew && formValues && formValues.get("kind") === "sms" &&
                    <Grid item xs={12}  sm={6}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="test_tel"
                          label="テスト配信電話番号"
                          fullWidth
                          component={TextFieldRedux}
                          // validate={[Validator.tel]}
                          className={classes.field}
                          disabled={isSend}
                        />
                      </FormControl>
                    </Grid>
                  }
                  {isNew && formValues && (formValues.get("kind") === "email" || formValues.get("kind") === "sms") &&
                    <Grid item xs={4}  sm={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isSend || (formValues.get("kind") === "email" && !formValues.get("test_email")) || (formValues.get("kind") === "sms" && !formValues.get("test_tel"))}
                        style={{ marginTop: 20 }}
                        onClick={() => testSendForm(formValues)}
                      >
                        送信
                      </Button>
                    </Grid>
                  }
                  { !isNew &&
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="status"
                          label="配信ステータス *"
                          fullWidth
                          component={SelectRedux}
                          disabled={true}
                        >
                          {statuses.map(status => (
                            <MenuItem key={status} value={status}>{enumToJa(status)}</MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.formControl}>
                        <Field
                          name="payment_status"
                          label="決済ステータス *"
                          fullWidth
                          component={SelectRedux}
                          disabled={true}
                        >
                          {paymentStatuses.map(status => (
                            <MenuItem key={status} value={status}>{enumToJa(status)}</MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </>
                  }
                </>
              }
            </Grid>
          </div>
            { isNew ?
              // 余白用
              <div className={classes.btnArea}>
                <Grid container justify="center">
                  { formValues && (formValues.get("kind") === "csv")  ?
                    <CsvUploadDialog variant="contained" color="primary" submitForm={handleSubmit} className={classes.button} message="登録"/>
                    :
                    <Button variant="contained" color="primary" size="large" type="submit">
                      登録
                    </Button>
                  }
                </Grid>
              </div>
            :
              <div>
                <div className={classes.btnArea}>
                  <Grid container justify="center">
                    <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/mail_payment/index">
                      戻る
                    </Button>
                    <Button variant="contained" color="primary" size="large" type="submit" disabled={isSend}>
                      更新
                    </Button>
                  </Grid>
                </div>
                <div className={classes.btnArea}>
                  <Grid container justify="center">
                    <DeleteDialog color="secondary" className={classes.button} message="この配信決済を削除する" deleteForm={deleteForm} disabled={isSend}/>
                  </Grid>
                </div>
              </div>
            }
        </section>
      </PapperBlock>
      </form>
    </div>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'mailPayment',
  enableReinitialize: true
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    deco: state.getIn([reducer, 'decoration']),
    formValues: getFormValues("mailPayment")(state)
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
