import { fromJS, Map } from 'immutable';
import {userAction} from "../constants/actionTypes";

const initialState = {
  loadingUserIndex: false,
  loadingUserShow: false,
  loadingUserData: false,
  loadingExport: false,
  loadingImageDownload: false,
  userData: Map()
};

const initialImmutableState = fromJS(initialState);
const userReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case userAction.ON_FETCH_INDEX_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserIndex', true);
      });
    case userAction.END_FETCH_INDEX_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserIndex', false);
      });
    case userAction.ON_FETCH_SHOW_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserShow', true);
      });
    case userAction.END_FETCH_SHOW_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserShow', false);
        mutableState.set('userData', action.result);
      });
    case userAction.ON_FETCH_UPDATE_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserData', true);
      });
    case userAction.END_FETCH_UPDATE_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserData', false);
      });
    case userAction.ON_FETCH_DELETE_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserData', true);
      });
    case userAction.END_FETCH_DELETE_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingUserData', false);
      });
    case userAction.ON_FETCH_EXPORT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingExport', true);
      });
    case userAction.END_FETCH_EXPORT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingExport', false);
      });
    case userAction.ON_FETCH_DELETE_IMAGE:
      return state.withMutations(mutableState => {
        mutableState.set("loadingUserData", true);
      });
    case userAction.END_FETCH_DELETE_IMAGE:
      return state.withMutations(mutableState => {
        mutableState.set("loadingUserData", false);
      });
    case userAction.ON_FETCH_DOWNLOAD_IMAGE:
      return state.withMutations(mutableState => {
        mutableState.set("loadingImageDownload", true);
      });
    case userAction.END_FETCH_DOWNLOAD_IMAGE:
      return state.withMutations(mutableState => {
        mutableState.set("loadingImageDownload", false);
      });
    default:
      return state
  }
};

export default userReducers;