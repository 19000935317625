import { fromJS, Map, List } from 'immutable';
import MenuContent from 'dan-api/ui/menu';
import {bulkPaymentAction} from "../constants/actionTypes";

const initialState = {
  loadingBulkPaymentCreate: false,
  loadingBulkPaymentIndex: false,
  loadingBulkPaymentShow: false,
  loadingBulkPaymentData: false,
  bulkPaymentData: Map(),
  initBulkPaymentData: Map({
    company_id: '',
    name: '',
    payment_at: ''
  })
};

const initialImmutableState = fromJS(initialState);
const bulkPaymentReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case bulkPaymentAction.ON_FETCH_CREATE_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentCreate', true);
      });
    case bulkPaymentAction.END_FETCH_CREATE_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentCreate', false);
      });
    case bulkPaymentAction.ON_FETCH_INDEX_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentIndex', true);
      });
    case bulkPaymentAction.END_FETCH_INDEX_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentIndex', false);
      });
    case bulkPaymentAction.ON_FETCH_SHOW_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentShow', true);
      });
    case bulkPaymentAction.END_FETCH_SHOW_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentShow', false);
        mutableState.set('bulkPaymentData', action.result);
      });
    case bulkPaymentAction.ON_FETCH_UPDATE_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentData', true);
      });
    case bulkPaymentAction.END_FETCH_UPDATE_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentData', false);
      });
    case bulkPaymentAction.ON_FETCH_DELETE_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentData', true);
      });
    case bulkPaymentAction.END_FETCH_DELETE_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingBulkPaymentData', false);
      });
    case bulkPaymentAction.CLEAR_BULK_PAYMENT:
      return state.withMutations((mutableState) => {
        mutableState.set('bulkPaymentData', initialState.initBulkPaymentData);
      });
    default:
      return state
  }
};

export default bulkPaymentReducers;