import { fromJS, Map, List } from 'immutable';
import {adminUserAction} from "../constants/actionTypes";

const initialState = {
  loadingAdminUserCreate: false,
  loadingAdminUserIndex: true,
  loadingAdminUserShow: false,
  loadingAdminUserData: false,
  adminUserData: Map(),
  initAdminUserData: Map({
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    status: '',
    company_id: '',
    email: '',
    login: '',
    password: '',
    role: ''
  })
};

const initialImmutableState = fromJS(initialState);
const adminUserReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case adminUserAction.ON_FETCH_CREATE_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserCreate', true);
      });
    case adminUserAction.END_FETCH_CREATE_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserCreate', false);
      });
    case adminUserAction.ON_FETCH_INDEX_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserIndex', true);
      });
    case adminUserAction.END_FETCH_INDEX_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserIndex', false);
      });
    case adminUserAction.ON_FETCH_SHOW_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserShow', true);
      });
    case adminUserAction.END_FETCH_SHOW_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserShow', false);
        mutableState.set('adminUserData', action.result);
      });
    case adminUserAction.ON_FETCH_UPDATE_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserData', true);
      });
    case adminUserAction.END_FETCH_UPDATE_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserData', false);
      });
    case adminUserAction.ON_FETCH_DELETE_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserData', true);
      });
    case adminUserAction.END_FETCH_DELETE_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingAdminUserData', false);
      });
    case adminUserAction.CLEAR_ADMIN_USER:
      return state.withMutations((mutableState) => {
        mutableState.set('adminUserData', initialState.initAdminUserData);
      });
    default:
      return state
  }
};

export default adminUserReducers;