import {billingStatementAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchIndexBillingStatementAction(action) {
  const result = yield Api.getIndexBillingStatement(action.data);
  yield put({type: billingStatementAction.END_FETCH_INDEX_BILLING_STATEMENT, result: result});
}

function* onFetchShowBillingStatementAction(action) {
  const result = yield Api.getShowBillingStatement(action.data);
  yield put({type: billingStatementAction.END_FETCH_SHOW_BILLING_STATEMENT, result: result});
}

export function* watchOnFetchBillingStatement() {
  yield takeLatest(billingStatementAction.ON_FETCH_INDEX_BILLING_STATEMENT, onFetchIndexBillingStatementAction);
  yield takeLatest(billingStatementAction.ON_FETCH_SHOW_BILLING_STATEMENT, onFetchShowBillingStatementAction);
}
