import {paymentHistoryAction} from "../constants/actionTypes";

export const onFetchIndexPaymentHistoryAction = (data) => {
  return {
    type: paymentHistoryAction.ON_FETCH_INDEX_PAYMENT_HISTORY,
    data
  }
};

export const onFetchShowPaymentHistoryAction = (data) => {
  return {
    type: paymentHistoryAction.ON_FETCH_SHOW_PAYMENT_HISTORY,
    data
  }
};

export const onFetchUpdatePaymentHistoryAction = (data) => {
  return {
    type: paymentHistoryAction.ON_FETCH_UPDATE_PAYMENT_HISTORY,
    data
  }
};

export const onFetchExportPaymentHistoryAction = (data) => {
  return {
    type: paymentHistoryAction.ON_FETCH_EXPORT_PAYMENT_HISTORY,
    data
  }
};
