import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* postCreateAdminUser(data) {
  const url = `${API.samuraiApiUrl}/admin_users`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getIndexAdminUser(data) {
  const url = `${API.samuraiApiUrl}/admin_users`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowAdminUser(data) {
  const url = `${API.samuraiApiUrl}/admin_users/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdateAdminUser(data) {
  const url = `${API.samuraiApiUrl}/admin_users/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteDeleteAdminUser(data) {
  const url = `${API.samuraiApiUrl}/admin_users/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  postCreateAdminUser,
  getIndexAdminUser,
  getShowAdminUser,
  putUpdateAdminUser,
  deleteDeleteAdminUser
};
