import { fromJS, Map, List } from 'immutable';
import MenuContent from 'dan-api/ui/menu';
import {paymentFormAction} from "../constants/actionTypes";

const initialState = {
  loadingPaymentFormCreate: false,
  loadingPaymentFormIndex: false,
  loadingPaymentFormShow: false,
  loadingPaymentFormData: false,
  loadingPaymentFormQrIssue: false,
  paymentFormData: Map(),
  initPaymentFormData: Map({
    name: '',
    description: '',
    type: '',
    image: '',
    price: '',
    split_available: '',
    is_user_regist: '',
    status: '',
    is_credit_card: '',
    is_cvs: '',
    is_paypay: '',
    is_linepay: '',
    is_alipay: '',
    is_three_d_secure: '',
    public_from: '',
    public_to: '',
    form_items_attributes:  Map({
      name: '',
      description: '',
      position: '',
      form_type: '',
      _destroy: '',
      is_required: '',
      is_hidden: '',
      form_item_selects_attributes:  Map({
        value: '',
        position: '',
        _destroy: '',
        form_items_attributes:  Map({
          name: '',
          description: '',
          position: '',
          form_type: '',
          _destroy: '',
          is_required: '',
          is_hidden: '',
          form_item_selects_attributes:  Map({
            value: '',
            position: '',
            _destroy: ''
          })
        })
        })
      }),
    payment_plans_attributes:  Map({
      name: '',
      price: '',
      content: '',
      image: '',
      max_number: '',
      stock: '',
      position: '',
      _destroy: ''
      })
  })
};

const initialImmutableState = fromJS(initialState);
const paymentFormReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case paymentFormAction.ON_FETCH_CREATE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormCreate', true);
      });
    case paymentFormAction.END_FETCH_CREATE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormCreate', false);
      });
    case paymentFormAction.ON_FETCH_INDEX_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormIndex', true);
      });
    case paymentFormAction.END_FETCH_INDEX_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormIndex', false);
      });
    case paymentFormAction.ON_FETCH_SHOW_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormShow', true);
      });
    case paymentFormAction.END_FETCH_SHOW_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormShow', false);
        mutableState.set('paymentFormData', action.result);
      });
    case paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', true);
      });
    case paymentFormAction.END_FETCH_UPDATE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', false);
      });
    case paymentFormAction.ON_FETCH_DELETE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', true);
      });
    case paymentFormAction.END_FETCH_DELETE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', false);
      });
    case paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM_ITEM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', true);
      });
    case paymentFormAction.END_FETCH_UPDATE_PAYMENT_FORM_ITEM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', false);
      });
    case paymentFormAction.ON_FETCH_UPDATE_PAYMENT_FORM_PLAN:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', true);
      });
    case paymentFormAction.END_FETCH_UPDATE_PAYMENT_FORM_PLAN:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormData', false);
      });
    case paymentFormAction.ON_FETCH_QR_ISSUE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormQrIssue', true);
      });
    case paymentFormAction.END_FETCH_QR_ISSUE_PAYMENT_FORM:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingPaymentFormQrIssue', false);
      });
    case paymentFormAction.CLEAR_PAYMENT_FORM:
        return state.withMutations((mutableState) => {
          mutableState.set('paymentFormData', initialState.initPaymentFormData);
        });
    default:
      return state
  }
};

export default paymentFormReducers;