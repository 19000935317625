import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteImageDialog(props) {
  const {
    deleteImage,
    dialogData,
  } = props;

  useEffect(() => {
    setOpen(dialogData.open)
  }, [dialogData]);

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    deleteImage();
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'画像を削除しますか?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            画像を削除してしまうと元に戻すことは出来ません！
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            戻る
          </Button>
          <Button onClick={handleSubmit} color="primary">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}