import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {Table, TableBody, TableHead, TableContainer, TableRow, TableCell} from '@material-ui/core';

function NormalPaymentPlan(props) {
  const {
    paymentHistories,
  } = props; 
  
  return (
    <Grid item xs={12}>
      {paymentHistories.map((paymentHistory, paymentHistoryIndex) => (
        <Paper style={{ border: "1px solid silver", padding: 30 }} elevation={4} key={paymentHistoryIndex}>
          <TableContainer>
            <Typography variant="h6">
              注文内容
            </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                 <TableRow>
                   <TableCell>サービス名</TableCell>
                   <TableCell>プラン名</TableCell>
                   <TableCell align="right">単価</TableCell>
                   <TableCell align="right">数量</TableCell>
                   <TableCell align="right">小計</TableCell>
                 </TableRow>
              </TableHead>
              <TableBody>
                {paymentHistory.payment_history_details.map((plan, planIndex) => ( 
                  <TableRow
                    key={planIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{plan.payment_service_name}</TableCell>
                    <TableCell>{plan.payment_plan_name}</TableCell>
                    <TableCell align="right">{parseInt(plan.payment_plan_price, 10)}円</TableCell>
                    <TableCell align="right">{plan.quantity || "-"}</TableCell>
                    <TableCell align="right">{plan.quantity ? parseInt(plan.payment_plan_price, 10) * plan.quantity : parseInt(plan.payment_plan_price, 10)}円</TableCell>
                  </TableRow>
                ))}   
                <TableRow>
                  <TableCell colSpan={3}/> 
                  <TableCell>合計</TableCell>
                  <TableCell align="right">{parseInt(paymentHistory.price, 10)}円</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
    </Grid>
  )
}

export default withStyles(styles)(NormalPaymentPlan);
