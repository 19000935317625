import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import {Grid, Paper, Table, TableBody, TableHead, TableContainer, TableRow, TableCell, List, ListItem, ListItemText, Typography, Divider} from '@material-ui/core';

function SubscriptionPaymentPlan(props) {

  const {
    subscriptions
  } = props; 

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  return (
    <Grid item xs={12}>
      {subscriptions.map((subscription, subscriptionIndex) => {
        const totalSum = subscription.subscription_payment_plans.reduce(
          (sum, plan) => sum + parseInt(plan.payment_plan_data.price, 10), 0
        );
        return (
          <Paper style={{ border: "1px solid silver", padding: 30 }} elevation={4} key={subscriptionIndex}>
            <Typography variant="h6">
              注文内容
            </Typography>
            {subscription.transfer_form_id ?
              <List>
                <ListItem>
                  <ListItemText primary={"プラン登録日"} />
                  <Typography>{formatDate(subscription.registration_date)}</Typography>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={"有効期限"} />
                  <Typography>{subscription.expire_date ? formatDate(subscription.expire_date) : "-"}</Typography>
                </ListItem>
                <Divider />
              </List> :
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                       <TableCell style={{ width: '30%' }}>サービス名</TableCell>
                       <TableCell style={{ width: '30%' }}>プラン名</TableCell>
                       <TableCell style={{ width: '20%' }} align="right">単価</TableCell>
                       <TableCell style={{ width: '20%' }} align="right">小計</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscription.subscription_payment_plans.map((plan, planIndex) => ( 
                      <TableRow
                        key={planIndex}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">{plan.payment_plan_data.payment_service_name}</TableCell>
                        <TableCell>{plan.payment_plan_data.name}</TableCell>
                        <TableCell align="right">{parseInt(plan.payment_plan_data.price, 10)}円</TableCell>
                        <TableCell align="right">{parseInt(plan.payment_plan_data.price, 10)}円</TableCell>
                      </TableRow>
                    ))}   
                    <TableRow>
                      <TableCell rowSpan={5} colSpan={2} />
                      <TableCell>プラン総計</TableCell>
                      <TableCell align="right">{totalSum}円</TableCell>
                    </TableRow>
                    { subscription.payment_form.has_initial_price &&
                      <TableRow>
                        <TableCell>入会金額</TableCell>
                        <TableCell align="right">{parseInt(subscription.payment_form.initial_price)}円</TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell>合計</TableCell>
                      <TableCell align="right">{totalSum + parseInt(subscription.payment_form.initial_price)}円</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>プラン登録日</TableCell>
                      <TableCell align="right">{formatDate(subscription.registration_date)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>有効期限</TableCell>
                      <TableCell align="right">{subscription.expire_date ? formatDate(subscription.expire_date) : "-"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Paper>
        )
      })}
    </Grid>
  )
}

export default withStyles(styles)(SubscriptionPaymentPlan);
