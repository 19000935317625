import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchIndexAdminUserAction } from '../../../redux/actions/adminUser';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import SearchForm from '../../../components/Forms/SearchForm';
import List from '../../../components/Lists/List';
import { enumToJa } from '../../../utils/format';
import Loading from '../../../components/Loading/index'
// import { getFormValues } from "redux-form";
// import { formValueSelector } from 'redux-form';

// const selector = formValueSelector('immutableEResetFrm');

function Index(props) {
  const { classes } = props;
  const { companies } = props.internalValues

  const title = brand.name + " - 管理者一覧";
  const description = brand.desc;


  const url = '/admin/admin_user/'
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const [loading, setLoading] = useState(true);
  const [adminUserData, setAdminUserData] = useState("")
  const [searchItems, setSearchItems] = useState(
    [
      { label: "ステータス", name: "status", value: "", formType: "select", menuItems: [{key: '仮登録', value: '2'}, {key: '有効', value: '1'}, {key: '無効', value: '0'}]},
      { label: "メールアドレス", name: "email", value: "", formType: "text"},
      { label: "ログインID", name: "login", value: "", formType: "text"},
      { label: "加盟店名", name: "company_id", value: "", formType: "select", super: true,
        menuItems: companies ? companies.map( (company) => ({key: company.name, value: company.id} )) : null
      }
    ]
  )
  const [listItems, setListItems] = useState(
    [
      { label: "加盟店名", name: "company", super: true}, 
      { label: "ログインID", name: "login"}, 
      { label: "氏名", name: "user-name"},
      { label: "ステータス", name: "status", format: (value)=>{return enumToJa(value['status'])} }
    ]
  )
  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    data.page = page
    props.onFetchIndexAdminUser({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setAdminUserData(item)
        item.data.length == 0 ? displayNotification("検索結果がありませんでした", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  return (
    <div>
      <Helmet>
      <title>{title}</title>
      </Helmet>
      <PapperBlock title="管理者検索" whiteBg icon="ios-search-outline" desc="">
        <section className={classes.formWrap}>
          <Grid container spacing={3}>
            {
              searchItems.map( (item, index) => {
                return(
                  <Grid key={index} item xs={12} sm={6}>  
                    <SearchForm searchItem={item} key={index} id={index} changeValue={changeValue} role={props.internalValues.role} />
                  </Grid>
                )
              })
            }
          </Grid>
        </section>
          <div className={classes.btnArea}>
            <Button variant="contained" color="primary" size="large" onClick={submitForm}>
              検索
            </Button>
          </div>
      </PapperBlock>
      {/*検索を開始するまでは一覧は表示しない
         検索を開始し、管理者一覧APIが終了するまではローディング画面
         管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない
         管理者一覧APIが終了し、データが格納されている場合は一覧を表示*/}
      {loading || adminUserData.data.length === 0 ?  "" :
        <PapperBlock title="管理者一覧" whiteBg icon="ios-list"desc="">
          { props.loadingAdminUserIndex ?
            <div><List listItems={listItems} searchResult={adminUserData} url={url} submitForm={submitForm} role={props.internalValues.role} browsingRight={props.internalValues.browsingRight} login={props.internalValues.login} /><Loading /></div> : 
            <div><List listItems={listItems} searchResult={adminUserData} url={url} submitForm={submitForm} role={props.internalValues.role} browsingRight={props.internalValues.browsingRight} login={props.internalValues.login} /></div>
          }
        </PapperBlock>
      }
      <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
    </div>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingAdminUserIndex: state.getIn(['adminUserReducers', 'loadingAdminUserIndex'])
    // name: selector(state, 'login')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexAdminUser: data => {
      dispatch(onFetchIndexAdminUserAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default withStyles(styles)(withCookies(withRouter(FormInit)));