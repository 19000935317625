import {companyAction} from "../constants/actionTypes";

export const onFetchCreateCompanyAction = (data) => {
  return {
    type: companyAction.ON_FETCH_CREATE_COMPANY,
    data
  }
};

export const onFetchCompanyAction = (data) => {
  return {
    type: companyAction.ON_FETCH_COMPANY,
    data
  }
};

export const onFetchShowCompanyAction = (data) => {
  return {
    type: companyAction.ON_FETCH_SHOW_COMPANY,
    data
  }
};

export const onFetchIndexCompanyAction = (data) => {
  return {
    type: companyAction.ON_FETCH_INDEX_COMPANY,
    data
  }
};

export const onFetchUpdateCompanyAction = (data) => {
  return {
    type: companyAction.ON_FETCH_UPDATE_COMPANY,
    data
  }
};

export const onFetchDeleteCompanyAction = (data) => {
  return {
    type: companyAction.ON_FETCH_DELETE_COMPANY,
    data
  }
};

export const clearCompanyAction = (data) => {
  return {
    type: companyAction.CLEAR_COMPANY,
    data
  }
};