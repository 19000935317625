import { userAction } from "../constants/actionTypes";

export const onFetchCreateUserAction = (data) => {
  return {
    type: userAction.ON_FETCH_CREATE_USER,
    data
  }
};

export const onFetchIndexUserAction = (data) => {
  return {
    type: userAction.ON_FETCH_INDEX_USER,
    data
  }
};

export const onFetchShowUserAction = (data) => {
  return {
    type: userAction.ON_FETCH_SHOW_USER,
    data
  }
};

export const onFetchUpdateUserAction = (data) => {
  return {
    type: userAction.ON_FETCH_UPDATE_USER,
    data
  }
};

export const onFetchDeleteUserAction = (data) => {
  return {
    type: userAction.ON_FETCH_DELETE_USER,
    data
  }
};

export const onFetchExportAction = (data) => {
  return {
    type: userAction.ON_FETCH_EXPORT,
    data
  }
};

export const creditCardChangeOrderAction = (data) => {
  return {
    type: userAction.ON_CREDIT_CARD_CHANGE_ORDER,
    data
  }
};

export const onFetchDeleteImageAction = (data) => {
  return {
    type: userAction.ON_FETCH_DELETE_IMAGE,
    data
  }
};

export const onFetchDownloadImageAction = (data) => {
  return {
    type: userAction.ON_FETCH_DOWNLOAD_IMAGE,
    data
  }
};
