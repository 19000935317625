import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Header from 'dan-components/Header/Header';
import SidebarBig from 'dan-components/SidebarBig';
import superMenu from 'dan-api/ui/superMenu';
import Decoration from '../Decoration';
import styles from '../appStyles-jss';
import { connect } from 'react-redux';

function LeftSidebarBigLayout(props) {
  const {
    classes,
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    pageLoaded,
    mode,
    gradient,
    deco,
    history,
    bgPosition,
    changeMode,
    place,
    handleOpenGuide,
    internalValues
  } = props;
  return (
    <Fragment>
      <Header
        toggleDrawerOpen={toggleDrawer}
        margin={sidebarOpen}
        gradient={gradient}
        position="left-sidebar-big"
        changeMode={changeMode}
        mode={mode}
        title={place}
        history={history}
        openGuide={handleOpenGuide}
      />
      <SidebarBig
        loadTransition={loadTransition}
        open={sidebarOpen}
        toggleDrawerOpen={toggleDrawer}
        leftSidebarBig
        dataMenu={internalValues.role === 'super' ? superMenu :
          [
            {
              key: 'home',
              name: 'ホーム',
              icon: 'ios-home-outline',
              linkParent: '/admin/top',
            },
            {
              key: 'user',
              name: '会員管理',
              icon: 'ios-contact-outline',
              child: [
                {
                  key: 'user',
                  name: '会員管理',
                  title: true,
                },
                {
                  key: 'user_index',
                  name: '会員一覧',
                  link: '/admin/user/index',
                  icon: 'ios-list'
                }
              ]
            },
            {
              key: 'payment',
              name: '決済管理',
              icon: 'ios-card',
              child: [
                {
                  key: 'payment_history',
                  name: '決済履歴管理',
                  title: true,
                },
                {
                  key: 'payment_history_index',
                  name: '決済履歴一覧',
                  link: '/admin/payment_history/index',
                  icon: 'ios-list'
                },
                {
                  key: 'payment',
                  name: '決済',
                  title: true,
                },
                {
                  key: 'mail_payment_index',
                  name: '配信決済一覧',
                  link: '/admin/mail_payment/index',
                  icon: 'ios-list'
                },
                {
                  key: 'mail_payment',
                  name: '配信決済',
                  link: '/admin/mail_payment/new',
                  icon: 'ios-mail'
                },
                {
                  key: 'bulk_payment_index',
                  name: '一括決済一覧',
                  link: '/admin/bulk_payment/index',
                  icon: 'ios-list'
                },
                {
                  key: 'bulk_payment',
                  name: '一括決済',
                  link: '/admin/bulk_payment/new',
                  icon: 'ios-cash'
                },
                {
                  key: 'export_log_index',
                  name: 'CSVエクスポート一覧',
                  link: '/admin/export_log/index',
                  icon: 'ios-cloud-download',
                },
              ]
            },
            {
              key: 'payment_form',
              name: 'フォーム管理',
              icon: 'ios-browsers-outline',
              child: [
                {
                  key: 'payment_form',
                  name: '決済フォーム管理',
                  title: true,
                },
                {
                  key: 'payment_form_index',
                  name: '決済フォーム一覧',
                  link: '/admin/payment_form/index',
                  icon: 'ios-list'
                },
                {
                  key: 'payment_form_new',
                  name: '決済フォーム登録',
                  link: '/admin/payment_form/new',
                  icon: 'ios-browsers-outline'
                }
              ]
            },
            // {
            //   key: 'report',
            //   name: '請求/売上管理',
            //   icon: 'ios-calculator-outline',
            //   child: [
            //     {
            //       key: 'billing_statement',
            //       name: '請求書',
            //       title: true,
            //     },
            //     {
            //       key: 'billing_statement_index',
            //       name: '請求書一覧',
            //       link: '/admin/billing_statement/index',
            //       icon: 'ios-list'
            //     },
            //     {
            //       key: 'analysis',
            //       name: '分析・集計',
            //       title: true,
            //     },
            //     {
            //       key: 'analysis_period_index',
            //       name: '期間別売上',
            //       link: '/admin/analysis_period/index',
            //       icon: 'ios-podium-outline'
            //     },
            //   ]
            // },
            {
              key: 'system',
              name: 'システム管理',
              icon: 'ios-settings-outline',
              child: [
                {
                  key: 'admin_user',
                  name: '管理者管理',
                  title: true,
                },
                {
                  key: 'admin_user_index',
                  name: '管理者一覧',
                  link: '/admin/admin_user/index',
                  icon: 'ios-list'
                },
                {
                  key: 'admin_user_new',
                  name: '管理者登録',
                  link: '/admin/admin_user/new',
                  icon: 'ios-person-add-outline'
                },
                {
                  key: 'company',
                  name: '加盟店管理',
                  title: true,
                },
                {
                  key: 'company_show',
                  name: '加盟店情報',
                  link: '/admin/company/' + internalValues.companyId,
                  icon: 'ios-document-outline'
                },
              ]
            }
          ]
        }
      />
      <main className={classNames(classes.content, !sidebarOpen ? classes.contentPaddingLeftBig : '')} id="mainContent">
        <Decoration
          mode={mode}
          gradient={gradient}
          decoration={deco}
          bgPosition={bgPosition}
          horizontalMenu={false}
        />
        <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
          { !pageLoaded && (<img src="/images/spinner.gif" alt="spinner" className={classes.circularProgress} />) }
          <Fade
            in={pageLoaded}
            {...(pageLoaded ? { timeout: 700 } : {})}
          >
            <div className={!pageLoaded ? classes.hideApp : ''}>
              {/* Application content will load here */}
              { children }
            </div>
          </Fade>
        </section>
      </main>
    </Fragment>
  );
}

LeftSidebarBigLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array.isRequired,
  handleOpenGuide: PropTypes.func.isRequired,
};

const FormInit = connect(
  state => ({internalValues: state.getIn(['internalReducers', 'internalValues'])}),
)(LeftSidebarBigLayout);

export default withStyles(styles)(FormInit);
