import {companyAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';
function* onFetchCreateCompany(action) {
  const result = yield Api.postCreateCompany(action.data);
  yield put({type: companyAction.END_FETCH_CREATE_COMPANY, result: result});
}
function* onFetchShowCompany(action) {
  const result = yield Api.getShowCompany(action.data);
  yield put({type: companyAction.END_FETCH_SHOW_COMPANY, result: result});
}
function* onFetchIndexCompany(action) {
  const result = yield Api.getIndexCompany(action.data);
  yield put({type: companyAction.END_FETCH_INDEX_COMPANY, result: result});
}
function* onFetchUpdateCompany(action) {
  const result = yield Api.putUpdateCompany(action.data);
  yield put({type: companyAction.END_FETCH_UPDATE_COMPANY, result: result});
}
function* onFetchDeleteCompany(action) {
  const result = yield Api.deleteDeleteCompany(action.data);
  yield put({type: companyAction.END_FETCH_DELETE_COMPANY, result: result});
}
export function* watchOnFetchCompany() {
  yield takeLatest(companyAction.ON_FETCH_CREATE_COMPANY, onFetchCreateCompany);
  yield takeLatest(companyAction.ON_FETCH_INDEX_COMPANY, onFetchIndexCompany);
  yield takeLatest(companyAction.ON_FETCH_SHOW_COMPANY, onFetchShowCompany);
  yield takeLatest(companyAction.ON_FETCH_UPDATE_COMPANY, onFetchUpdateCompany);
  yield takeLatest(companyAction.ON_FETCH_DELETE_COMPANY, onFetchDeleteCompany);
}