import React, { useState } from "react";
import { SketchPicker } from 'react-color';
import TextField from '@material-ui/core/TextField';

const ColorPickerField = function (props) {
  const [textColor, setTextColor] = useState(props.input.value || "#000")
  
  const changeTextColor = (color) => {
    setTextColor(color.hex)
    props.input.onChange(color.hex);
  }

  return (
    <div>
      <TextField 
        value={textColor} 
        label={props.input.name == 'footer_text_color' ? "フッターテキストカラー" : "背景カラー"} 
      />
      <SketchPicker
        color={textColor}
        onChange={changeTextColor}
      />
    </div>
  );
}

export default ColorPickerField;