import { format } from 'date-fns'

// enumを日本語へ変換
export const enumToJa = (text) => {
  switch (text) {
    case 'active':
      text = "有効";
      break;
    case 'not_active':
      text = "無効";
      break;
    case 'light':
      text = "ライト";
      break;
    case 'standard':
      text = "スタンダード";
      break;
    case 'premium':
      text = "プレミアム";
      break;
    case 'approved':
      text = "有効";
      break;
    case 'not_approve':
      text = "無効";
      break;
    case 'temporary':
      text = "仮登録";
      break;
    case 'not_pay':
      text = "未決済";
      break;
    case 'auth':
      text = "与信中";
      break;
    case 'paid':
      text = "決済済み";
      break;
    case 'temporary':
      text = "仮決済";
      break;
    case 'failed':
      text = "決済失敗";
      break;
    case 'pay_cancel':
        text = "返金";
        break;
    case 'auth_cancel':
    case 'cvs_cancel':
        text = "キャンセル";
      break;
    case 'normal_payment':
      text = "通常決済";
      break;
    case 'subscription_payment':
        text = "継続課金決済";
        break;
    case 'face_auth_payment':
      text = "顔認証決済";
      break;
    case 'transfer_payment':
      text = "継続課金用データ移行";
      break;
    case 'mail_payment':
      text = "メール決済";
      break;
    case 'subscription_manual_payment':
      text = "継続課金(手動)";
      break;
    case 'male':
        text = "男性";
        break;
    case 'female':
      text = "女性";
      break;
    case 'other':
      text = "その他";
      break;
    case 'csv':
      text = "CSV";
      break;
    case 'email':
      text = "メールアドレス";
      break;
    case 'sms':
      text = "SMS";
      break;
    case 'error':
      text = "失敗";
      break;
    case 'success':
      text = "成功";
      break;
    case 'doing':
      text = "処理中";
      break;
    case 'unsent':
      text = "未送信";
      break;
    case 'sent':
      text = "送信済み";
      break;
    case 'send_failed':
      text = "送信失敗";
      break;
    case 're_auth':
      text = "再与信";
      break;
    case 're_auth_capture':
      text = "再売上計上";
      break;
    case 're_auth_capture':
      text = "再売上計上";
      break;
    default:
      break;
  }
  return text;
}

// 数字を3桁ずつのカンマ区切りに変換
export const numberWithDelimiter = (num) => {
  return Number(num).toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,').split('.')[0]
};

// 日付の形式を変換
export const dateFormat = (date) => {
  date = new Date(date)
  let format = 'YYYY/MM/DD'
  format = format.replace(/YYYY/g, date.getFullYear())
  format = format.replace(/MM/g, ('00' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('00' + date.getDate()).slice(-2));
  return format
};

// 日付の形式を変換（年を除外）
export const shortDateFormat = (date) => (
  format(new Date(date), "M/d")
);

// 日付の形式を日時に変換
export const dateTimeFormat = (dateTime) => (
  format(new Date(dateTime), "yyyy/MM/dd HH:mm")
)

// 日付の形式を変換（年月）
export const yearMonthFormat = (dateTime) => (
  dateTime ? format(new Date(dateTime), "yyyy/MM") : ''
)

// 15文字を超える場合は「…」で省略
export const sliceText = (text) => (
  text && text.length > 15 ? text.slice(0,15)+"…" : text
);
