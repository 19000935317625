import {mailPaymentAction} from "../constants/actionTypes";

export const onFetchCreateMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.ON_FETCH_CREATE_MAIL_PAYMENT,
    data
  }
};

export const onFetchIndexMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.ON_FETCH_INDEX_MAIL_PAYMENT,
    data
  }
};

export const onFetchShowMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.ON_FETCH_SHOW_MAIL_PAYMENT,
    data
  }
};

export const onFetchUpdateMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.ON_FETCH_UPDATE_MAIL_PAYMENT,
    data
  }
};

export const onFetchDeleteMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.ON_FETCH_DELETE_MAIL_PAYMENT,
    data
  }
};

export const onFetchTestSendMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.ON_FETCH_TEST_SEND_MAIL_PAYMENT,
    data
  }
};

export const clearMailPaymentAction = (data) => {
  return {
    type: mailPaymentAction.CLEAR_MAIL_PAYMENT,
    data
  }
};