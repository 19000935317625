import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CounterWidget from './CounterWidget';
import styles from './widget-jss';
import colorfull from './colorfull';
import {
  BarChart, Bar, AreaChart, Area,
} from 'recharts/umd/Recharts';

function CounterIconWidget(props) {
  const { classes, daily, monthly } = props;

  const dummyGraph = [
    { name: 'Page A', uv: 1890 },
    { name: 'Page B', uv: 2780 },
    { name: 'Page C', uv: 2000 },
    { name: 'Page D', uv: 3000 },
    { name: 'Page E', uv: 4000 },
  ];

  return (
    <div className={classes.rootCounterFull}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <CounterWidget
            color={colorfull[3]}
            numValue={daily}
            unitBefore="¥ "
            title="昨日の売上"
          >
            <AreaChart width={100} height={60} data={dummyGraph}>
              <Area type="monotone" dataKey="uv" stroke="#FFFFFF" fill="rgba(255,255,255,.5)" />
            </AreaChart>
          </CounterWidget>
        </Grid>
        <Grid item xs={6} md={6}>
          <CounterWidget
            color={colorfull[7]}
            numValue={monthly}
            unitBefore="¥ "
            title="今月の売上"
          >
            <BarChart width={100} height={40} data={dummyGraph}>
              <Bar dataKey="uv" fill="#ffffff" />
            </BarChart>
          </CounterWidget>
        </Grid>
      </Grid>
    </div>
  );
}

CounterIconWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CounterIconWidget);
