import {analysisPeriodAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchIndexAnalysisPeriodAction(action) {
  const result = yield Api.getIndexAnalysisPeriod(action.data);
  yield put({type: analysisPeriodAction.END_FETCH_INDEX_ANALYSIS_PERIOD, result: result});
}

export function* watchOnFetchAnalysisPeriod() {
  yield takeLatest(analysisPeriodAction.ON_FETCH_INDEX_ANALYSIS_PERIOD, onFetchIndexAnalysisPeriodAction);
}
