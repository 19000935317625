import {userAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchIndexUserAction(action) {
  const result = yield Api.getIndexUser(action.data);
  yield put({type: userAction.END_FETCH_INDEX_USER, result: result});
}

function* onFetchShowUserAction(action) {
  const result = yield Api.getShowUser(action.data);
  yield put({type: userAction.END_FETCH_SHOW_USER, result: result});
}

function* onFetchUpdateUserAction(action) {
  const result = yield Api.putUpdateUser(action.data);
  yield put({type: userAction.END_FETCH_UPDATE_USER, result: result});
}

function* onFetchDeleteUserAction(action) {
  const result = yield Api.deleteDeleteUser(action.data);
  yield put({type: userAction.END_FETCH_DELETE_USER, result: result});
}

function* onFetchExportAction(action) {
  const result = yield Api.getExport(action.data);
  yield put({type: userAction.END_FETCH_EXPORT, result: result});
}

function* creditCardChangeOrderAction(action) {
  const result = yield Api.creditCardChangeOrder(action.data);
  yield put({type: userAction.END_FETCH_EXPORT, result: result});
}

function* onFetchDeleteImage(action) {
  const result = yield Api.deleteImage(action.data);
  yield put({type: userAction.END_FETCH_DELETE_IMAGE, result: result});
}

function* onFetchDownloadImage(action) {
  const result = yield Api.getDownloadImage(action.data);
  yield put({type: userAction.END_FETCH_DOWNLOAD_IMAGE, result: result});
}

export function* watchOnFetchUser() {
  yield takeLatest(userAction.ON_FETCH_INDEX_USER, onFetchIndexUserAction);
  yield takeLatest(userAction.ON_FETCH_SHOW_USER, onFetchShowUserAction);
  yield takeLatest(userAction.ON_FETCH_UPDATE_USER, onFetchUpdateUserAction);
  yield takeLatest(userAction.ON_FETCH_DELETE_USER, onFetchDeleteUserAction);
  yield takeLatest(userAction.ON_FETCH_EXPORT, onFetchExportAction);
  yield takeLatest(userAction.ON_CREDIT_CARD_CHANGE_ORDER, creditCardChangeOrderAction);
  yield takeLatest(userAction.ON_FETCH_DELETE_IMAGE, onFetchDeleteImage);
  yield takeLatest(userAction.ON_FETCH_DOWNLOAD_IMAGE, onFetchDownloadImage);
}
