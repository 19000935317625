import {qaCategoryAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreateQaCategoryAction(action) {
  const result = yield Api.postCreateQaCategory(action.data);
  yield put({type: qaCategoryAction.END_FETCH_CREATE_QA_CATEGORY, result: result});
}

function* onFetchIndexQaCategoryAction(action) {
  const result = yield Api.getIndexQaCategory(action.data);
  yield put({type: qaCategoryAction.END_FETCH_INDEX_QA_CATEGORY, result: result});
}

function* onFetchShowQaCategoryAction(action) {
  const result = yield Api.getShowQaCategory(action.data);
  yield put({type: qaCategoryAction.END_FETCH_SHOW_QA_CATEGORY, result: result});
}

function* onFetchUpdateQaCategoryAction(action) {
  const result = yield Api.putUpdateQaCategory(action.data);
  yield put({type: qaCategoryAction.END_FETCH_UPDATE_QA_CATEGORY, result: result});
}

function* onFetchDeleteQaCategoryAction(action) {
  const result = yield Api.deleteDeleteQaCategory(action.data);
  yield put({type: qaCategoryAction.END_FETCH_DELETE_QA_CATEGORY, result: result});
}

export function* watchOnFetchQaCategory() {
  yield takeLatest(qaCategoryAction.ON_FETCH_CREATE_QA_CATEGORY, onFetchCreateQaCategoryAction);
  yield takeLatest(qaCategoryAction.ON_FETCH_INDEX_QA_CATEGORY, onFetchIndexQaCategoryAction);
  yield takeLatest(qaCategoryAction.ON_FETCH_SHOW_QA_CATEGORY, onFetchShowQaCategoryAction);
  yield takeLatest(qaCategoryAction.ON_FETCH_UPDATE_QA_CATEGORY, onFetchUpdateQaCategoryAction);
  yield takeLatest(qaCategoryAction.ON_FETCH_DELETE_QA_CATEGORY, onFetchDeleteQaCategoryAction);
}
