import React from 'react';
import { Line } from 'react-chartjs-2';
import { shortDateFormat } from '../../../utils/format';

function AreaChart(props) {
  const { searchedItems, searchResult } = props;

  // X軸（集計単位）
  let periods = [];
  let onlyPeriodData = [];
  switch (searchedItems[2]){
    case "day":
      let startDate = new Date(searchedItems[0].getTime())
      let endDate = new Date(searchedItems[1].getTime())
      for(let x = startDate; x < endDate; x.setDate(x.getDate()+1)) {
        periods.push(shortDateFormat(x))
      };
      searchResult.data.map((i) => {
        return onlyPeriodData.push(shortDateFormat(i.attributes.date))
      });
      break;
    case "month":
      for (let x = 1; x <= 12; x++){periods.push(x + '月')};
      searchResult.data.map((i) => {
        let month = Number(i.attributes.date.slice(-2))
        return onlyPeriodData.push(month + '月')
      });
      break;
    case "week_day":
      periods = ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'];
      searchResult.data.map((i) => {
        return onlyPeriodData.push(i.attributes.date + '曜日')
      });
      break;
    case "hour":
      for (let x = 0; x < 24; x++){periods.push(('00' + x).slice(-2))};
      searchResult.data.map((i) => {
        return onlyPeriodData.push(i.attributes.date.slice(0, 2))
      });
      break;
  }

  // Y軸（売上金額）
  let prices = [];
  periods.forEach((period) => {
    if (onlyPeriodData.includes(period)) {
      let paymentSumPrice = parseInt(searchResult.data[onlyPeriodData.indexOf(period)].attributes['payment-sum-price']);
      prices.push(paymentSumPrice)
    } else {
      prices.push(0)
    }
  })

  const analysisData = {
    labels: periods,
    datasets: [
      {
        label: '売上',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(81, 185, 245, 0.4)',
        borderColor: 'rgba(81, 185, 245, 1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(81, 185, 245, 1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 5,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: 'rgba(81, 185, 245, 1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: prices,
      }
    ]
  };

  return (
    <div>
      <Line data={analysisData}/>
    </div>
  );
}

export default AreaChart;
