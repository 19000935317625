import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { onFetchPostTwoFactorResendAction, onFetchPostTwoFactorVerifyAction } from '../../../redux/actions/account';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import TwoFactorForm from 'dan-components/Forms/TwoFactorForm';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { onSetStateCommonAction } from '../../../redux/actions/internal';

const TwoFactor = (props) => {
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const displayNotification = (message, valiant = 'error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }
  const submitForm = useCallback((values) => {
    if (props.cookies.get('twoFactorToken') != null && new Date(props.cookies.get('twoFactorTokenExpireDate')) < new Date()) {
      displayNotification('有効期限が切れました、認証コードを再送してください', 'error')
      return
    }
    const dataValues = values.set('two_factor_token', props.cookies.get('twoFactorToken'))
    props.onFetchPostTwoFactorVerify({
      data: dataValues,
      onSuccess: async data => {
        props.onSetStateCommon({
          login: data.login,
          role: data.role,
          companyId: data.company_id
        });
        var now = new Date();
        var hour = new Date();
        hour.setHours(now.getHours() + 1);
        props.cookies.remove('twoFactorToken', {path: '/', secure: process.env.NODE_ENV !== 'development'});
        props.cookies.remove('twoFactorTokenExpireDate', {path: '/', secure: process.env.NODE_ENV !== 'development'});
        props.cookies.set('company', data.company_code, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.cookies.set('accessToken', data.access_token, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.cookies.set('accessTokenExpireDate', data.access_token_expire_date, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.history.push('/admin/top');
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  });
  const {classes} = props;
  const resendForm = values => {
    props.onFetchPostTwoFactorResend({
      data: {two_factor_token: props.cookies.get('twoFactorToken')},
      onSuccess: async data => {
        var now = new Date();
        var hour = new Date();
        // 10分をコンストにする
        hour.setMinutes(now.getMinutes() + 10);
        props.cookies.set('twoFactorToken', data.two_factor_token, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        props.cookies.set('twoFactorTokenExpireDate', data.two_factor_token_expire_date, {path: '/', expires: hour, secure: process.env.NODE_ENV !== 'development', sameSite: 'lax'});
        displayNotification('認証コードを再送しました', 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };
  return (
    <>
      <Helmet>
        <title>{brand.name + ' - Two Factor'}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <TwoFactorForm onSubmit={(values) => submitForm(values)} resendForm={resendForm}/>
        </div>
        <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification}/>
      </div>
    </>
  );
};
TwoFactor.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchPostTwoFactorVerify: data => {
      dispatch(onFetchPostTwoFactorVerifyAction(data));
    },
    onFetchPostTwoFactorResend: data => {
      dispatch(onFetchPostTwoFactorResendAction(data));
    },
    onSetStateCommon: data => {
      dispatch(onSetStateCommonAction(data));
    },
  };
};
const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactor);
export default withStyles(styles)(withCookies(withRouter(FormInit)));