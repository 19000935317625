import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* getIndexAnalysisPeriod(data) {
  const url = `${API.samuraiApiUrl}/sales_analysis_periods`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  getIndexAnalysisPeriod,
};
