import {errorAction, internalAction} from "../constants/actionTypes";

export const onRaiseErrorAction = (data) => {
  return {
    type: errorAction.RAISE_ERROR,
    data
  }
};

export const onRaiseErrorToastAction = (data) => {
  return {
    type: errorAction.RAISE_ERROR_TOAST,
    data
  }
};

export const onSetStateCommonAction = (data) => {
  return {
    type: internalAction.SET_STATE_COMMON,
    data
  }
};
