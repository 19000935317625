import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Templates/Dashboard';
import { ThemeContext } from './ThemeWrapper';
import LoginRequiredRoute from '../../components/LoginRequiredRoute/LoginRequiredRoute';
import TopPage from '../Pages/TopPage/index';
import UserIndex from '../Pages/Users/Index';
import UserEdit from '../Pages/Users/Edit';
import AnalysisPeriod from '../Pages/AnalysisPeriods/Index';
import NotFound from '../NotFound/NotFound';
import AdminUserIndex from '../Pages/AdminUsers/Index';
import AdminUserNew from '../Pages/AdminUsers/Edit';
import AdminUserEdit from '../Pages/AdminUsers/Edit';
import PaymentHistoryIndex from '../Pages/PaymentHistories/Index';
import PaymentHistoryEdit from '../Pages/PaymentHistories/Edit';
import PaymentFormIndex from '../Pages/PaymentForms/Index';
import PaymentFormNew from '../Pages/PaymentForms/Edit';
import PaymentFormEdit from '../Pages/PaymentForms/Edit';
import PaymentFormItemEdit from '../Pages/PaymentForms/ItemEdit';
import MailPaymentIndex from '../Pages/MailPayment/Index';
import MailPaymentEdit from '../Pages/MailPayment/Edit';
import MailPaymentNew from '../Pages/MailPayment/Edit';
import MailPaymentRecipientIndex from '../Pages/MailPayment/Edit';
import MailPaymentRecipientEdit from '../Pages/MailPaymentRecipient/Edit';
import BulkPaymentIndex from '../Pages/BulkPayment/Index';
import BulkPaymentEdit from '../Pages/BulkPayment/Edit';
import BulkPaymentNew from '../Pages/BulkPayment/Edit';
import CompanyIndex from '../Pages/Companies';
import CompanyNew from '../Pages/Companies/New';
import CompanyEdit from '../Pages/Companies/Edit';
import CompanyShow from '../Pages/Companies/Edit';
import QaInformationIndex from '../Pages/QaInformations';
import QaInformationEdit from '../Pages/QaInformations/Edit';
import QaInformationNew from '../Pages/QaInformations/Edit';
import QaCategoryIndex from '../Pages/QaCategories';
import QaCategoryEdit from '../Pages/QaCategories/Edit';
import QaCategoryNew from '../Pages/QaCategories/Edit';
import ExportLogIndex from '../Pages/ExportLogs/Index';

function Application(props) {
  const { history } = props;
  const changeMode = useContext(ThemeContext);
  return (
    <Dashboard history={history} changeMode={changeMode}>
      <Switch>
        <LoginRequiredRoute exact path="/admin/top" component={TopPage} />
        <LoginRequiredRoute path="/admin/user/index" component={UserIndex} />
        <LoginRequiredRoute path="/admin/user/:id" component={UserEdit} />
        {/* <LoginRequiredRoute path="/admin/billing_statement/index" component={BillingStatementIndex} />
        <LoginRequiredRoute path="/admin/billing_statement/:id" component={BillingStatementEdit} /> */}
        <LoginRequiredRoute path="/admin/analysis_period" component={AnalysisPeriod} />
        <LoginRequiredRoute path="/admin/admin_user/index" component={AdminUserIndex} />
        <LoginRequiredRoute path="/admin/admin_user/new" component={AdminUserNew} />
        <LoginRequiredRoute path="/admin/admin_user/:id" component={AdminUserEdit} />
        <LoginRequiredRoute path="/admin/payment_history/index" component={PaymentHistoryIndex} />
        <LoginRequiredRoute path="/admin/payment_history/:id" component={PaymentHistoryEdit} />
        <LoginRequiredRoute path="/admin/payment_form/:id/item" component={PaymentFormItemEdit} />
        <LoginRequiredRoute path="/admin/payment_form/index" component={PaymentFormIndex} />
        <LoginRequiredRoute path="/admin/payment_form/new" component={PaymentFormNew} />
        <LoginRequiredRoute path="/admin/payment_form/:id" component={PaymentFormEdit} />
        <LoginRequiredRoute path="/admin/mail_payment/index" component={MailPaymentIndex} />
        <LoginRequiredRoute path="/admin/mail_payment/new" component={MailPaymentNew} />
        <LoginRequiredRoute path="/admin/mail_payment/:id" component={MailPaymentEdit} />
        <LoginRequiredRoute path="/admin/mail_payment_recipient/index" component={MailPaymentRecipientIndex} />
        <LoginRequiredRoute path="/admin/mail_payment_recipient/:id" component={MailPaymentRecipientEdit} />
        <LoginRequiredRoute path="/admin/bulk_payment/index" component={BulkPaymentIndex} />
        <LoginRequiredRoute path="/admin/bulk_payment/new" component={BulkPaymentNew} />
        <LoginRequiredRoute path="/admin/bulk_payment/:id" component={BulkPaymentEdit} />
        <LoginRequiredRoute path="/admin/qa_information/index" component={QaInformationIndex} />
        <LoginRequiredRoute path="/admin/qa_information/new" component={QaInformationNew} />
        <LoginRequiredRoute path="/admin/qa_information/:id" component={QaInformationEdit} />
        <LoginRequiredRoute path="/admin/qa_category/index" component={QaCategoryIndex} />
        <LoginRequiredRoute path="/admin/qa_category/new" component={QaCategoryNew} />
        <LoginRequiredRoute path="/admin/qa_category/:id" component={QaCategoryEdit} />
        <LoginRequiredRoute path="/admin/company/index" component={CompanyIndex} />
        <LoginRequiredRoute path="/admin/company/new" component={CompanyNew} />
        <LoginRequiredRoute path="/admin/company/show" component={CompanyShow} />
        <LoginRequiredRoute path="/admin/company/:id" component={CompanyEdit} />
        <LoginRequiredRoute path="/admin/export_log/index" component={ExportLogIndex} />
        <LoginRequiredRoute component={NotFound} />
      </Switch>
    </Dashboard>
  );
}

Application.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Application;
