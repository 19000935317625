import React, { useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ResetPasswordForm from 'dan-components/Forms/ResetPasswordForm';
import styles from '../../../components/Forms/user-jss';
import { connect } from 'react-redux';
import { onFetchUpdatePasswordReminderAction } from "../../../redux/actions/account";
import { withRouter } from 'react-router';
import MySnackbar from '../../../components/Notification/StyledNotif';

function ResetPassword(props) {
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const submitForm = useCallback((values) => {
    props.onFetchUpdatePasswordReminder({
      data: values,
      onSuccess: async data => {
        props.history.push({ pathname: '/admin/login', state: { from: props.location }, message: 'パスワードの再設定が完了しました。ログインしてください。', variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
        props.history.push({ pathname: '/admin/login', state: { from: props.location }, message: error, variant: 'error'});
      }
    })
  });

  const title = brand.name + ' - Reset Password';
  const description = brand.desc;
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <ResetPasswordForm onSubmit={(values) => submitForm(values)} />
        </div>
        <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loadingResetPassword: state.getIn(['accountReducers', 'loadingResetPassword'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUpdatePasswordReminder: data => {
      dispatch(onFetchUpdatePasswordReminderAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default withRouter(withStyles(styles)(FormInit));
