import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import accessToken from '../../../utils/accessToken';
import { 
  onFetchCreateAdminUserAction,
  onFetchShowAdminUserAction,
  onFetchUpdateAdminUserAction,
  onFetchDeleteAdminUserAction,
  clearAdminUserAction
} from "../../../redux/actions/adminUser";

function Edit(props) {
  const description = brand.desc;
  const { match } = props;

  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const [isNew, setIsNew] = useState(false);
  const [titleName, setTitleName] = useState("");

  const url = '/admin/admin_user/index'

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  // 詳細取得
  useEffect(() => {
    const url = props.path.split('/')
    if (url[url.length - 1] === 'new') {
      setIsNew(true)
      setTitleName("管理者登録")
      props.clearAdminUser()
    } else {
      fetchForm()
      setTitleName("管理者詳細")
    }
  }, []);

  const fetchForm = values => {
    props.onFetchShowAdminUser({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
  };
  
  // 登録
  const newForm = values => {
    let dataValues = values
    if (props.internalValues.role === "company") {
      dataValues = dataValues.set("company_id", props.internalValues.companyId).set("role", "company")
    }
    props.onFetchCreateAdminUser({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "登録が完了しました", variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 更新
  const editForm = values => {
    let dataValues = values
    dataValues.id = match.params.id
    props.onFetchUpdateAdminUser({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        if (data.is_change_password) {
          props.history.push({ pathname: '/admin/login', state: { from: props.location }, message: 'パスワードを変更しました。再度ログインしてください。', variant: 'success'});
        } else {
          displayNotification("更新が完了しました", 'success')
        }
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 削除
  const deleteForm = values => {
    props.onFetchDeleteAdminUser({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "管理者を削除しました", variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };
  
  return (
    <div>
      <Helmet>
        <title>{brand.name + " - " + titleName}</title>
      </Helmet>
      <PapperBlock title={titleName} whiteBg icon="ios-person-outline" desc="">
        <Form onSubmit={(values) => isNew ? newForm(values) : editForm(values)} deleteForm={deleteForm} internalValues={props.internalValues} url={url} action={isNew} />
      </PapperBlock>
      <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    adminUserData: state.getIn(['adminUserserReducers', 'adminUserData']),
    loadingAdminUserCreate: state.getIn(['adminUserserReducers', 'loadingAdminUserCreate']),
    loadingAdminUserShow: state.getIn(['adminUserserReducers', 'loadingAdminUserShow']),
    loadingAdminUserData: state.getIn(['adminUserserReducers', 'loadingAdminUserData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchCreateAdminUser: data => {
      dispatch(onFetchCreateAdminUserAction(data));
    },
    onFetchShowAdminUser: data => {
      dispatch(onFetchShowAdminUserAction(data));
    },
    onFetchUpdateAdminUser: data => {
      dispatch(onFetchUpdateAdminUserAction(data));
    },
    onFetchDeleteAdminUser: data => {
      dispatch(onFetchDeleteAdminUserAction(data));
    },
    clearAdminUser: data => {
      dispatch(clearAdminUserAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(FormInit));