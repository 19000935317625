import {mailPaymentAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreateMailPaymentAction(action) {
  const result = yield Api.postCreateMailPayment(action.data);
  yield put({type: mailPaymentAction.END_FETCH_CREATE_MAIL_PAYMENT, result: result});
}

function* onFetchIndexMailPaymentAction(action) {
  const result = yield Api.getIndexMailPayment(action.data);
  yield put({type: mailPaymentAction.END_FETCH_INDEX_MAIL_PAYMENT, result: result});
}

function* onFetchShowMailPaymentAction(action) {
  const result = yield Api.getShowMailPayment(action.data);
  yield put({type: mailPaymentAction.END_FETCH_SHOW_MAIL_PAYMENT, result: result});
}

function* onFetchUpdateMailPaymentAction(action) {
  const result = yield Api.putUpdateMailPayment(action.data);
  yield put({type: mailPaymentAction.END_FETCH_UPDATE_MAIL_PAYMENT, result: result});
}

function* onFetchDeleteMailPaymentAction(action) {
  const result = yield Api.deleteDeleteMailPayment(action.data);
  yield put({type: mailPaymentAction.END_FETCH_DELETE_MAIL_PAYMENT, result: result});
}

function* onFetchTestSendMailPaymentAction(action) {
  const result = yield Api.postTestSendMailPayment(action.data);
  yield put({type: mailPaymentAction.END_FETCH_TEST_SEND_MAIL_PAYMENT, result: result});
}

export function* watchOnFetchMailPayment() {
  yield takeLatest(mailPaymentAction.ON_FETCH_CREATE_MAIL_PAYMENT, onFetchCreateMailPaymentAction);
  yield takeLatest(mailPaymentAction.ON_FETCH_INDEX_MAIL_PAYMENT, onFetchIndexMailPaymentAction);
  yield takeLatest(mailPaymentAction.ON_FETCH_SHOW_MAIL_PAYMENT, onFetchShowMailPaymentAction);
  yield takeLatest(mailPaymentAction.ON_FETCH_UPDATE_MAIL_PAYMENT, onFetchUpdateMailPaymentAction);
  yield takeLatest(mailPaymentAction.ON_FETCH_DELETE_MAIL_PAYMENT, onFetchDeleteMailPaymentAction);
  yield takeLatest(mailPaymentAction.ON_FETCH_TEST_SEND_MAIL_PAYMENT, onFetchTestSendMailPaymentAction);
}
