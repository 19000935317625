import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux , renderRadioGroup, DateRedux} from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import * as Validator from '../../../utils/validate'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import { ContactSupportOutlined } from '@material-ui/icons';

function Form(props) {
  const {
    classes,
    handleSubmit,
    deleteForm,
    url,
    action,
    qaInformationData,
    qaCategories,
  } = props;

  const { login, role } = props.internalValues
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <section className={classes.formWrap}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="position"
                label="表示順 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required, Validator.num]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="qa_category_id"
                label="カテゴリ名 *"
                fullWidth
                component={SelectRedux}
                validate={[Validator.required]}
                >
                  {qaCategories.map( (category, key) => (<MenuItem value={category.id} key={key}>{category.attributes.name}</MenuItem>) )}
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="name"
                label="QA管理名称 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="question"
                label="質問内容 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="answer"
                className={classes.field}
                component={TextFieldRedux}
                validate={[Validator.required]}
                placeholder="回答内容"
                label="回答内容 *"
                multiline={true}
                rows={4}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="status"
                label="ステータス *"
                fullWidth
                component={SelectRedux}
                validate={[Validator.required]}
                >
                <MenuItem value="approved">有効</MenuItem>
                <MenuItem value="not_approve">無効</MenuItem>
              </Field>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.btnArea}>
          <Grid container justify="center">
            <Button variant="outlined" color="primary" size="large" component={Link} to={url}>
              戻る
            </Button>
            <Button variant="contained" color="primary" size="large" type="submit">
              {action ? '登録' : '更新'}
            </Button>
          </Grid>
        </div>
        {action || (qaInformationData && qaInformationData.login === login) ? '' :
          <div className={classes.btnArea}>
            <Grid container justify="center">
              <DeleteDialog color="secondary" className={classes.button} message="このQA情報を削除する" deleteForm={deleteForm}/>
            </Grid>
          </div>
        }
      </form>
    </section>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'qaInformationForm',
  enableReinitialize: true,
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    loadingQaInformationShow: state.getIn(['qaInformationReducers', 'loadingQaInformationShow']),
    initialValues: state.getIn(['qaInformationReducers', 'qaInformationData']),
    qaInformationData: state.getIn(['qaInformationReducers', 'qaInformationData'])
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
