import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import SearchForm from '../../../components/Forms/SearchForm';
import List from '../../../components/Lists/List';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchIndexPaymentHistoryAction, onFetchExportPaymentHistoryAction } from '../../../redux/actions/paymentHistory';
import { onFetchIndexPaymentFormAction } from '../../../redux/actions/paymentForm';
import accessToken from '../../../utils/accessToken';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../components/Forms/user-jss';
import {enumToJa, sliceText, dateTimeFormat, yearMonthFormat} from '../../../utils/format';
import Loading from '../../../components/Loading/index'
import CsvExportDialog from '../../../components/Dialog/CsvExportDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function Index(props) {
  const { classes } = props;
  const { companies } = props.internalValues

  const title = brand.name + ' - 決済履歴一覧';
  const description = brand.desc;
  const url = '/admin/payment_history/'

  const location = props.location

  // 検索項目
  const [searchItems, setSearchItems] = useState(
    [
      { label: "決済日時〜", name: "order_start", value: null, formType: "datetime" },
      { label: "〜決済日時", name: "order_end", value: null, formType: "datetime" },
      { label: "決済番号" , name: "order_number" , value: "" , formType: "text"},
      // TODO 決済手段増え次第修正
      // { label: "決済手段" , name: "type" , value: "" , formType: "select" , menuItems: [{key: '通常決済' , value: 'normal_payment'} , {key: '顔認証決済' , value: 'face_auth_payment'} , {key: 'メール決済' , value: 'mail_payment'}]},
      { label: "メールアドレス" , name: "email" , value: "" , formType: "text"},
      { label: "ステータス" , name: "status" , value: "" , formType: "select" , menuItems: [{key: '未決済', value: 'not_pay'}, {key: '与信中', value: 'auth'}, {key: '決済済み', value: 'paid'}, {key: 'キャンセル', value: 'auth_cancel'}, {key: '返金', value: 'pay_cancel'}, {key: '決済失敗', value: 'failed'}]},
      { label: "会員番号" , name: "user_no" , value: location.userNo || ""  , formType: "text"},
      { label: "姓" , name: "last_name" , value: "" , formType: "text"},
      { label: "名" , name: "first_name" , value: "" , formType: "text"},
      { label: "決済フォーム", name: "payment_form_id", value: "", formType: "select_suggestion"},
      { label: "決済フォーム項目名" , name: "payment_form_item_name" , value: "" , formType: "text"},
      { label: "決済フォーム項目説明" , name: "payment_form_item_description" , value: "" , formType: "text"},
      { label: "決済フォーム項目回答" , name: "payment_form_item_answer" , value: "" , formType: "text"},
      { label: "一括決済名" , name: "bulk_payment_name" , value: location.bulkPaymentName || "" , formType: "text"},
      { label: "加盟店", name: "company_id", value: "", formType: "select", super: true,
        menuItems: companies ? companies.map( (company) => ({key: company.name, value: company.id} )) : null
      }
    ]
  )

  const [indexPaymentFormData, setIndexPaymentFormData] = useState([]);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [excludeExported, setExcludeExported] = useState(false);

  const fetchIndexPaymentForm = company_id => {
    let data = {}
    if (company_id){
      data.company_id = company_id
    }
    data.page = 'all'
    props.onFetchIndexPaymentForm({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setIndexPaymentFormData(item.data)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  useEffect(() => {
    const newCompanyId = searchItems.find(item => item.name === "company_id").value;
    fetchIndexPaymentForm(newCompanyId)
  }, [searchItems && searchItems.find(item => item.name === "company_id").value]);

  const [listItems, setlistItems] = useState(
    [
      { label: "加盟店名", name: "company", super: true},
      { label: "決済番号", name: "order-number"},
      { label: "会員番号", name: "user-no", url: '/admin/user', id: 'user-id'},
      // TODO 決済手段増え次第修正
      // { label: "決済手段", name: "payment-history-type", format: (value)=>{return enumToJa(value['payment-history-type'])}},
      { label: "決済フォーム名", name: "payment-form-name", format: (value)=>{return sliceText(value['payment-form-name'])}},
      { label: "メールアドレス", name: "email", format: (value)=>{return sliceText(value['email'])}},
      { label: "金額", name: "price", format: (value)=>{return value['price'] + '円'}},
      { label: "決済日時", name: "order-date", format: (value)=>{return dateTimeFormat(value['order-date'])}},
      { label: "何月利用分", name: "use-month", format: (value)=>{return yearMonthFormat(value['use-month'])}},
      { label: "エクスポート状況", name: "exported"},
      { label: "ステータス", name: "status", format: (value)=>{return enumToJa(value['status'])}}
    ]
  )

  // 一覧
  const [loading, setLoading] = useState(true);
  const [paymentHistoryData, setPaymentHistoryData] = useState("")
  const [sort, setSort] = useState('desc');
  
  const submitForm = (values, page = 1) => {
    let data = {}
    searchItems.forEach(item => { data[item.name] = item.value})
    if (values === 'sort') {
      if (sort === 'desc') {
        setSort('asc')
        data['sort'] = 'asc'
      } else {
        setSort('desc')
        data['sort'] = 'desc'
      }
    } else {
      data['sort'] = sort
    }
    if (excludeExported) {
      data.exported = 'false';
    }
    data.page = page
    props.onFetchIndexPaymentHistory({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setPaymentHistoryData(item)
        if (!item.data.length) {
          displayNotification("検索結果がありませんでした", 'warning')
          return
        }
        if (values === 'export') {
          setCount(item.meta['total-count'])
          setIsExportDialogOpen(true);
        }
        setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // メッセージ関連
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const changeValue = (index,value) => {
    searchItems[index].value = value
    setSearchItems(searchItems.slice())
  }

  useEffect(() => {
    if (location.bulkPaymentName || location.userNo) {
      submitForm();
    }
  }, []);

  const handleExport = () => {
    let data = {}
    searchItems.forEach(item => {
      data[item.name] = item.value
    })
    if (excludeExported) {
      data.exported = 'false'
    }
    props.onFetchExportPaymentHistory({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  return (
    <div>
      <Helmet>
      <title>{title}</title>
      </Helmet>
      <PapperBlock title="決済履歴検索" whiteBg icon="ios-search" desc="">
        <section className={classes.formWrap}>
          <Grid container spacing={3}>
            { searchItems.map( (item, index) => {
                return(
                  <Grid key={index} item xs={12} sm={item.formType === 'select_suggestion' ? 12 : 6}>
                    <SearchForm 
                      searchItem={item}
                      key={index}
                      id={index}
                      changeValue={changeValue}
                      role={props.internalValues.role}
                      indexPaymentFormData={indexPaymentFormData}
                    />
                  </Grid>
                )}
              )
            }
          </Grid>
        </section>
          <div className={classes.btnArea}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} container justify="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeExported}
                      onChange={e => setExcludeExported(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="一度エクスポートした情報は除く"
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="large" onClick={submitForm}>
                  検索
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="large" onClick={() => submitForm('export')}>
                  CSVエクスポート
                </Button>
              </Grid>
            </Grid>
          </div>
      </PapperBlock>
      {/*検索を開始するまでは一覧は表示しない
         検索を開始し、管理者一覧APIが終了するまではローディング画面
         管理者一覧APIが終了し、データが格納されていない場合は一覧は表示しない
         管理者一覧APIが終了し、データが格納されている場合は一覧を表示*/}
      {loading || paymentHistoryData.data.length === 0 ?  "" :
          <PapperBlock title="決済履歴一覧" whiteBg icon="ios-menu-outline" desc="" sort="true" submitForm={submitForm}>
            { props.loadingPaymentHistoryIndex ? 
              <div><List listItems={listItems} searchResult={paymentHistoryData} url={url} submitForm={submitForm} role={props.internalValues.role} browsingRight={props.internalValues.browsingRight} /><Loading /></div> :
              <List listItems={listItems} searchResult={paymentHistoryData} url={url} submitForm={submitForm} role={props.internalValues.role} browsingRight={props.internalValues.browsingRight} />
            }
          </PapperBlock>
      }
      <CsvExportDialog open={isExportDialogOpen} count={count} onExport={handleExport} onClose={setIsExportDialogOpen}/>
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingPaymentHistoryIndex: state.getIn(['paymentHistoryReducers', 'loadingPaymentHistoryIndex']),
    loadingPaymentHistoryExport: state.getIn(['paymentHistoryReducers', 'loadingPaymentHistoryExport']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexPaymentHistory: data => {
      dispatch(onFetchIndexPaymentHistoryAction(data));
    },
    onFetchIndexPaymentForm: data => {
      dispatch(onFetchIndexPaymentFormAction(data));
    },
    onFetchExportPaymentHistory: data => {
      dispatch(onFetchExportPaymentHistoryAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default withStyles(styles)(withCookies(withRouter(FormInit)));
