import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from 'dan-components/Tables/tableStyle-jss';
import { enumToJa, dateTimeFormat, sliceText } from '../../../utils/format';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

function RecentlyList(props) {
  const { classes, paymentHistoryData, role } = props;
  const histories = paymentHistoryData.data;
  
  return (
    <Fragment>
      <div className={classes.rootTable}>
        <Table className={classNames(classes.table, classes.stripped)}>
          <TableHead>
            <TableRow>
              <TableCell padding="default">決済日</TableCell>
              {role === 'super' && <TableCell padding="default">加盟店名</TableCell>}
              <TableCell padding="default">決済番号</TableCell>
              {/* TODO 決済手段増え次第コメント解除 */}
              {/* <TableCell padding="default">決済手段</TableCell> */}
              <TableCell padding="default">決済フォーム名</TableCell>
              <TableCell padding="default">ステータス</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {histories && histories.map(n => ([
              <TableRow key={n.id}>
                <TableCell padding="default">{dateTimeFormat(n.attributes['order-date'])}</TableCell>
                {role === 'super' && <TableCell padding="default">{n.attributes['company']}</TableCell>}
                <TableCell padding="default">{n.attributes['order-number']}</TableCell>
                {/* <TableCell padding="default">{enumToJa(n.attributes['payment-history-type'])}</TableCell> */}
                <TableCell padding="default">{sliceText(n.attributes['payment-form-name'])}</TableCell>
                <TableCell padding="default">{enumToJa(n.attributes['status'])}</TableCell>
              </TableRow>
            ]))}
          </TableBody>
        </Table>
        <Button color="primary" className={classes.button} component={Link} to="/admin/payment_history/index">
          さらに詳しく
        </Button>
      </div>
    </Fragment>
  );
}

RecentlyList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecentlyList);
