import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';
import DeleteDialog from '../../../components/Dialog/DeleteDialog';
import * as Validator from '../../../utils/validate'

function Form(props) {
  const {
    classes,
    handleSubmit,
    deleteForm,
    url,
    action,
    qaCategoryData
  } = props;

  const { login, role, companies } = props.internalValues

  return (
    <section className={classes.formWrap}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="position"
                label="表示順 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required, Validator.num]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="code"
                label="カテゴリコード *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <Field
                name="name"
                label="カテゴリ名 *"
                fullWidth
                component={TextFieldRedux}
                validate={[Validator.required]}
                className={classes.field}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <Field
                name="status"
                label="ステータス *"
                fullWidth
                component={SelectRedux}
                validate={[Validator.required]}
                >
                <MenuItem value="approved">有効</MenuItem>
                <MenuItem value="not_approve">無効</MenuItem>
              </Field>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.btnArea}>
          <Grid container justify="center">
            <Button variant="outlined" color="primary" size="large" component={Link} to={url}>
              戻る
            </Button>
            <Button variant="contained" color="primary" size="large" type="submit">
              {action ? '登録' : '更新'}
            </Button>
          </Grid>
        </div>
        {action || (qaCategoryData && qaCategoryData.login === login) ? '' :
          <div className={classes.btnArea}>
            <Grid container justify="center">
              <DeleteDialog color="secondary" className={classes.button} message="このQAカテゴリ情報を削除する" deleteForm={deleteForm}/>
            </Grid>
          </div>
        }
      </form>
    </section>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'qaCategoryForm',
  enableReinitialize: true,
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    loadingQaCategoryShow: state.getIn(['qaCategoryReducers', 'loadingQaCategoryShow']),
    initialValues: state.getIn(['qaCategoryReducers', 'qaCategoryData']),
    qaCategoryData: state.getIn(['qaCategoryReducers', 'qaCategoryData'])
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
