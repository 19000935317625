import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form/immutable';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux, SelectRedux, DateTimeMinutesStepRedux } from '../../../components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import * as Validator from '../../../utils/validate';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import CsvDropZone from 'dan-components/Forms/CsvDropZone';
import CsvUploadDialog from '../../../components/Dialog/CsvUploadDialog';

function Form(props) {

  const [status, setStatus] = useState("");
  const { role, companies } = props.internalValues

  const {
    classes,
    handleSubmit,
    deleteForm,
    formId,
    formValues,
    isNew
  } = props;

  const isSend = formValues && formValues.get("is_send")

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <PapperBlock title={isNew ? "一括決済設定" : "一括決済設定詳細"} whiteBg icon="ios-contact-outline" desc="">
        <section className={classes.formWrap}>
          <div>
            <Grid container spacing={3}>
              {role ==='super' &&
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="company_id"
                      label="加盟店名 *"
                      fullWidth
                      component={SelectRedux}
                      validate={[Validator.required]}
                      disabled={isNew ? false : true}
                    >
                      {companies.map( (company, key) => (<MenuItem value={company.id} key={key}>{company.name}</MenuItem>) )}
                    </Field>
                  </FormControl>
                </Grid>
              }
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="name"
                    className={classes.field}
                    component={TextFieldRedux}
                    placeholder="一括決済名"
                    validate={[Validator.required, Validator.length]}
                    label="一括決済名 *"
                    disabled={isSend}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="payment_at"
                    label="決済実施日時 *"
                    component={DateTimeMinutesStepRedux}
                    validate={[Validator.required]}
                    className={classes.field}
                    disabled={isSend}
                    minutesStep={5}
                  />
                </FormControl>
              </Grid>
              <FormControl className={classes.formControl}>
                <Field
                  name="csv"
                  component={CsvDropZone}
                  acceptedFiles={['.csv']}
                  type="file"
                  showPreviews
                  files={(formValues && formValues.get("csv")) ? [formValues.get("csv")] : []}
                  maxSize={50000000000}
                  filesLimit={1}
                  text="ここにCSVファイルをドラッグ＆ドロップまたはクリックしてCSVファイルを選択してください"
                />
              </FormControl>
            </Grid>
          </div>
          <div className={classes.btnArea}>
            <Grid container justify="center">
              <CsvUploadDialog variant="contained" color="primary" submitForm={handleSubmit} className={classes.button} message="登録"/>
            </Grid>
          </div>
        </section>
      </PapperBlock>
      </form>
    </div>
  );
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const FormReduxed = reduxForm({
  form: 'mailPayment',
  enableReinitialize: true
})(Form);

const reducer = 'ui';
const FormReduxedMapped = connect(
  state => ({
    force: state,
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    deco: state.getIn([reducer, 'decoration']),
    formValues: getFormValues("mailPayment")(state)
  }),
)(FormReduxed);

export default withStyles(styles)(FormReduxedMapped);
