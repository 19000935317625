import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* getIndexMailPaymentRecipient(data) {
  const url = `${API.samuraiApiUrl}/mail_payment_recipients`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowMailPaymentRecipient(data) {
  const url = `${API.samuraiApiUrl}/mail_payment_recipients/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdateMailPaymentRecipient(data) {
  const url = `${API.samuraiApiUrl}/mail_payment_recipients/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteDeleteMailPaymentRecipient(data) {
  const url = `${API.samuraiApiUrl}/mail_payment_recipients/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  getIndexMailPaymentRecipient,
  getShowMailPaymentRecipient,
  putUpdateMailPaymentRecipient,
  deleteDeleteMailPaymentRecipient,
};
