import {accountAction} from '../../constants/actionTypes';
import {put, takeLatest} from 'redux-saga/effects';
import {Api} from './api';

function* onFetchCreateAccount(action) {
  const result = yield Api.postCreateAccount(action.data);
  yield put({type: accountAction.END_FETCH_CREATE_ACCOUNT, result: result});
}

function* onFetchUserLogin(action) {
  const result = yield Api.postUserLogin(action.data);
  yield put({type: accountAction.END_FETCH_USER_LOGIN, result: result});
}

function* onFetchUserLogout(action) {
  const result = yield Api.postUserLogout(action.data);
  yield put({type: accountAction.END_FETCH_USER_LOGOUT, result: result});
}

function* onFetchUserInfo(action) {
  const result = yield Api.postUserInfo(action.data);
  yield put({type: accountAction.END_FETCH_USER_INFO, result: result});
}

function* onFetchCreatePasswordReminder(action) {
  const result = yield Api.postCreatePasswordReminder(action.data);
  yield put({type: accountAction.END_FETCH_CREATE_PASSWARD_REMINDER, result: result});
}

function* onFetchUpdatePasswordReminder(action) {
  const result = yield Api.postUpdatePasswordReminder(action.data);
  yield put({type: accountAction.END_FETCH_UPDATE_PASSWARD_REMINDER, result: result});
}

function* onFetchPostTwoFactorVerify(action) {
  const result = yield Api.postTwoFactorVerify(action.data);
  yield put({type: accountAction.END_FETCH_POST_TWO_FACTOR_VERIFY, result: result});
}

function* onFetchPostTwoFactorResend(action) {
  const result = yield Api.postTwoFactorResend(action.data);
  yield put({type: accountAction.END_FETCH_POST_TWO_FACTOR_RESEND, result: result});
}

export function* watchOnFetchAccount() {
  yield takeLatest(accountAction.ON_FETCH_CREATE_ACCOUNT, onFetchCreateAccount);
  yield takeLatest(accountAction.ON_FETCH_USER_LOGIN, onFetchUserLogin);
  yield takeLatest(accountAction.ON_FETCH_USER_LOGOUT, onFetchUserLogout);
  yield takeLatest(accountAction.ON_FETCH_USER_INFO, onFetchUserInfo);
  yield takeLatest(accountAction.ON_FETCH_CREATE_PASSWARD_REMINDER, onFetchCreatePasswordReminder);
  yield takeLatest(accountAction.ON_FETCH_UPDATE_PASSWARD_REMINDER, onFetchUpdatePasswordReminder);
  yield takeLatest(accountAction.ON_FETCH_POST_TWO_FACTOR_VERIFY, onFetchPostTwoFactorVerify);
  yield takeLatest(accountAction.ON_FETCH_POST_TWO_FACTOR_RESEND, onFetchPostTwoFactorResend);
}
