import { fromJS, Map } from 'immutable';
import {qaCategoryAction} from "../constants/actionTypes";

const initialState = {
  loadingQaCategoryCreate: false,
  loadingQaCategoryIndex: true,
  loadingQaCategoryShow: false,
  loadingQaCategoryData: false,
  qaCategoryData: Map(),
  initQaCategoryData: Map({
    position: '',
    code: '',
    name: '',
    status: '',
  })
};

const initialImmutableState = fromJS(initialState);
const qaCategoryReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case qaCategoryAction.ON_FETCH_CREATE_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryCreate', true);
      });
    case qaCategoryAction.END_FETCH_CREATE_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryCreate', false);
      });
    case qaCategoryAction.ON_FETCH_INDEX_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryIndex', true);
      });
    case qaCategoryAction.END_FETCH_INDEX_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryIndex', false);
      });
    case qaCategoryAction.ON_FETCH_SHOW_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryShow', true);
      });
    case qaCategoryAction.END_FETCH_SHOW_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryShow', false);
        mutableState.set('qaCategoryData', action.result);
      });
    case qaCategoryAction.ON_FETCH_UPDATE_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryData', true);
      });
    case qaCategoryAction.END_FETCH_UPDATE_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryData', false);
      });
    case qaCategoryAction.ON_FETCH_DELETE_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryData', true);
      });
    case qaCategoryAction.END_FETCH_DELETE_QA_CATEGORY:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaCategoryData', false);
      });
    case qaCategoryAction.CLEAR_QA_CATEGORY:
        return state.withMutations((mutableState) => {
          mutableState.set('qaCategoryData', initialState.initPaymentFormData);
        });
    default:
      return state
  }
};

export default qaCategoryReducers;