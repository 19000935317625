import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { onFetchDeleteMailPaymentRecipientAction, onFetchShowMailPaymentRecipientAction, onFetchUpdateMailPaymentRecipientAction } from "../../../redux/actions/mailPaymentRecipient";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../components/Forms/user-jss";
import Form from './Form';
import accessToken from "../../../utils/accessToken";
import MySnackbar from "../../../components/Notification/StyledNotif";
import { onFetchIndexPaymentFormAction } from "../../../redux/actions/paymentForm";

const Edit = props => {
  const {match} = props;
  const [loading, setLoading] = useState(true);
  const location = props.location;
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });
  const [indexPaymentFormData, setIndexPaymentFormData] = useState([]);
  const displayNotification = (message, valiant = 'error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }
  const fetchIndexPaymentForm = company_id => {
    let data = {}
    if (company_id) {
      data.company_id = company_id
    }
    data.page = 'all'
    props.onFetchIndexPaymentForm({
      accessToken: accessToken(props),
      data: data,
      onSuccess: data => {
        const item = data
        setIndexPaymentFormData(item.data)
        item.data.length == 0 ? displayNotification("決済フォームが取得できませんでした。", 'warning') : setLoading(false)
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };
  const fetchForm = values => {
    props.onFetchShowMailPaymentRecipient({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onErrorWithNotice: error => {
        props.history.push('/admin/error');
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };
  const submitForm = values => {
    var dataValues = values
    dataValues.id = match.params.id
    props.onFetchUpdateMailPaymentRecipient({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        fetchForm();
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };
  const deleteForm = values => {
    props.onFetchDeleteMailPaymentRecipient({
      accessToken: accessToken(props),
      data: {id: match.params.id},
      onSuccess: async data => {
        props.history.push({pathname: '/admin/mail_payment/' + props.mailPaymentRecipientData.mail_payment_id, message: '配信決済を削除しました', variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };
  useEffect(() => {
    fetchForm();
    fetchIndexPaymentForm();
  }, []);
  return (
    <>
      <Helmet>
        <title>{brand.name + " - 配信設定詳細"}</title>
      </Helmet>
      {/* 配信決済情報取得Apiが終了するまでは、空のフォームを表示させる */}
      {loading || props.loadingMailPaymentRecipientShow ? <Form fetchIndexPaymentForm={fetchIndexPaymentForm}/> : <Form onSubmit={(values) => submitForm(values)} deleteForm={deleteForm} initialValues={props.mailPaymentRecipientData} indexPaymentFormData={indexPaymentFormData} submitForm={submitForm}/>}
      <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification}/>
    </>
  );
};

const mapStateToProps = state => {
  return {
    mailPaymentRecipientData: state.getIn(['mailPaymentRecipientReducers', 'mailPaymentRecipientData']),
    loadingMailPaymentRecipientShow: state.getIn(['mailPaymentRecipientReducers', 'loadingMailPaymentRecipientShow']),
    loadingMailPaymentRecipientData: state.getIn(['mailPaymentRecipientReducers', 'loadingMailPaymentRecipientData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchIndexPaymentForm: data => {
      dispatch(onFetchIndexPaymentFormAction(data));
    },
    onFetchShowMailPaymentRecipient: data => {
      dispatch(onFetchShowMailPaymentRecipientAction(data));
    },
    onFetchUpdateMailPaymentRecipient: data => {
      dispatch(onFetchUpdateMailPaymentRecipientAction(data));
    },
    onFetchDeleteMailPaymentRecipient: data => {
      dispatch(onFetchDeleteMailPaymentRecipientAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withStyles(styles)(withCookies(withRouter(FormInit)));
