const superMenu = [
  {
    key: 'home',
    name: 'ホーム',
    icon: 'ios-home-outline',
    linkParent: '/admin/top',
  },
  {
    key: 'user',
    name: '会員管理',
    icon: 'ios-contact-outline',
    child: [
      {
        key: 'user',
        name: '会員管理',
        title: true,
      },
      {
        key: 'user_index',
        name: '会員一覧',
        link: '/admin/user/index',
        icon: 'ios-list'
      },
    ]
  },
  {
    key: 'payment',
    name: '決済管理',
    icon: 'ios-card',
    child: [
      {
        key: 'payment_history',
        name: '決済履歴管理',
        title: true,
      },
      {
        key: 'payment_history_index',
        name: '決済履歴一覧',
        link: '/admin/payment_history/index',
        icon: 'ios-list'
      },
      {
        key: 'payment',
        name: '決済',
        title: true,
      },
      {
        key: 'mail_payment_index',
        name: '配信決済一覧',
        link: '/admin/mail_payment/index',
        icon: 'ios-list'
      },
      {
        key: 'mail_payment',
        name: '配信決済',
        link: '/admin/mail_payment/new',
        icon: 'ios-mail'
      },
      {
        key: 'bulk_payment_index',
        name: '一括決済一覧',
        link: '/admin/bulk_payment/index',
        icon: 'ios-list'
      },
      {
        key: 'bulk_payment',
        name: '一括決済',
        link: '/admin/bulk_payment/new',
        icon: 'ios-cash'
      },
      {
        key: 'export_log_index',
        name: 'CSVエクスポート一覧',
        link: '/admin/export_log/index',
        icon: 'ios-cloud-download',
      },
    ]
  },
  {
    key: 'payment_form',
    name: 'フォーム管理',
    icon: 'ios-browsers-outline',
    child: [
      {
        key: 'payment_form',
        name: '決済フォーム管理',
        title: true,
      },
      {
        key: 'payment_form_index',
        name: '決済フォーム一覧',
        link: '/admin/payment_form/index',
        icon: 'ios-list'
      },
      {
        key: 'payment_form_new',
        name: '決済フォーム登録',
        link: '/admin/payment_form/new',
        icon: 'ios-browsers-outline'
      }
    ]
  },
  {
    key: 'qa',
    name: 'QA管理',
    icon: 'ios-chatboxes-outline',
    child: [
      {
        key: 'qa_information',
        name: 'QA管理',
        title: true,
      },
      {
        key: 'qa_information_list',
        name: 'QA情報一覧',
        link: '/admin/qa_information/index',
        icon: 'ios-list'
      },
      {
        key: 'qa_information',
        name: 'QA情報登録',
        link: '/admin/qa_information/new',
        icon: 'ios-chatboxes-outline'
      },
      {
        key: 'qa_category',
        name: 'QAカテゴリ管理',
        title: true,
      },
      {
        key: 'qa_category_list',
        name: 'QAカテゴリ一覧',
        link: '/admin/qa_category/index',
        icon: 'ios-list'
      },
      {
        key: 'qa_category',
        name: 'QAカテゴリ登録',
        link: '/admin/qa_category/new',
        icon: 'ios-chatboxes-outline'
      },
    ]
  },
  // {
  //   key: 'report',
  //   name: '請求/売上管理',
  //   icon: 'ios-calculator-outline',
  //   child: [
  //     {
  //       key: 'billing_statement',
  //       name: '請求書',
  //       title: true,
  //     },
  //     {
  //       key: 'billing_statement_index',
  //       name: '請求書一覧',
  //       link: '/admin/billing_statement/index',
  //       icon: 'ios-list'
  //     },
  //     {
  //       key: 'analysis',
  //       name: '分析・集計',
  //       title: true,
  //     },
  //     {
  //       key: 'analysis_period_index',
  //       name: '期間別売上',
  //       link: '/admin/analysis_period/index',
  //       icon: 'ios-podium-outline'
  //     },
  //   ]
  // },
  {
    key: 'system',
    name: 'システム管理',
    icon: 'ios-settings-outline',
    child: [
      {
        key: 'admin_user',
        name: '管理者管理',
        title: true,
      },
      {
        key: 'admin_user_index',
        name: '管理者一覧',
        link: '/admin/admin_user/index',
        icon: 'ios-list'
      },
      {
        key: 'admin_user_new',
        name: '管理者登録',
        link: '/admin/admin_user/new',
        icon: 'ios-person-add-outline'
      },
      {
        key: 'company',
        name: '加盟店管理',
        title: true,
      },
      {
        key: 'company_index',
        name: '加盟店一覧',
        link: '/admin/company/index',
        icon: 'ios-list'
      },
      {
        key: 'company_create',
        name: '加盟店登録',
        link: '/admin/company/new',
        icon: 'ios-document-outline'
      },
    ]
  }
];

export default superMenu;