export const errorAction = {
  RAISE_ERROR: 'RAISE_ERROR',
  RAISE_ERROR_TOAST: 'RAISE_ERROR_TOAST',
};
export const internalAction = {
  SET_STATE_COMMON: 'SET_STATE_COMMON'
};
export const accountAction = {
  ON_FETCH_CREATE_ACCOUNT: "ON_FETCH_CREATE_ACCOUNT",
  END_FETCH_CREATE_ACCOUNT: "END_FETCH_CREATE_ACCOUNT",
  ON_FETCH_USER_LOGIN: "ON_FETCH_USER_LOGIN",
  END_FETCH_USER_LOGIN: "END_FETCH_USER_LOGIN",
  ON_FETCH_USER_LOGOUT: "ON_FETCH_USER_LOGOUT",
  END_FETCH_USER_LOGOUT: "END_FETCH_USER_LOGOUT",
  ON_FETCH_USER_INFO: "ON_FETCH_USER_INFO",
  END_FETCH_USER_INFO: "END_FETCH_USER_INFO",
  ON_FETCH_CREATE_PASSWARD_REMINDER: "ON_FETCH_CREATE_PASSWARD_REMINDER",
  END_FETCH_CREATE_PASSWARD_REMINDER: "END_FETCH_CREATE_PASSWARD_REMINDER",
  ON_FETCH_UPDATE_PASSWARD_REMINDER: "ON_FETCH_UPDATE_PASSWARD_REMINDER",
  END_FETCH_UPDATE_PASSWARD_REMINDER: "END_FETCH_UPDATE_PASSWARD_REMINDER",
  ON_FETCH_POST_TWO_FACTOR_VERIFY: "ON_FETCH_POST_TWO_FACTOR_VERIFY",
  END_FETCH_POST_TWO_FACTOR_VERIFY: "END_FETCH_POST_TWO_FACTOR_VERIFY",
  ON_FETCH_POST_TWO_FACTOR_RESEND: "ON_FETCH_POST_TWO_FACTOR_RESEND",
  END_FETCH_POST_TWO_FACTOR_RESEND: "END_FETCH_POST_TWO_FACTOR_RESEND",
};

export const userAction = {
  ON_FETCH_INDEX_USER: "ON_FETCH_INDEX_USER",
  END_FETCH_INDEX_USER: "END_FETCH_INDEX_USER",
  ON_FETCH_SHOW_USER: "ON_FETCH_SHOW_USER",
  END_FETCH_SHOW_USER: "END_FETCH_SHOW_USER",
  ON_FETCH_UPDATE_USER: "ON_FETCH_UPDATE_USER",
  END_FETCH_UPDATE_USER: "END_FETCH_UPDATE_USER",
  ON_FETCH_DELETE_USER: "ON_FETCH_DELETE_USER",
  END_FETCH_DELETE_USER: "END_FETCH_DELETE_USER",
  ON_FETCH_EXPORT: "ON_FETCH_EXPORT",
  END_FETCH_EXPORT: "END_FETCH_EXPORT",
  ON_CREDIT_CARD_CHANGE_ORDER: "ON_CREDIT_CARD_CHANGE_ORDER",
  ON_FETCH_DELETE_IMAGE: "ON_FETCH_DELETE_IMAGE",
  END_FETCH_DELETE_IMAGE: "END_FETCH_DELETE_IMAGE",
  ON_FETCH_DOWNLOAD_IMAGE: "ON_FETCH_DOWNLOAD_IMAGE",
  END_FETCH_DOWNLOAD_IMAGE: "END_FETCH_DOWNLOAD_IMAGE",
};

export const paymentHistoryAction = {
  ON_FETCH_INDEX_PAYMENT_HISTORY: "ON_FETCH_INDEX_PAYMENT_HISTORY",
  END_FETCH_INDEX_PAYMENT_HISTORY: "END_FETCH_INDEX_PAYMENT_HISTORY",
  ON_FETCH_SHOW_PAYMENT_HISTORY: "ON_FETCH_SHOW_PAYMENT_HISTORY",
  END_FETCH_SHOW_PAYMENT_HISTORY: "END_FETCH_SHOW_PAYMENT_HISTORY",
  ON_FETCH_UPDATE_PAYMENT_HISTORY: "ON_FETCH_UPDATE_PAYMENT_HISTORY",
  END_FETCH_UPDATE_PAYMENT_HISTORY: "END_FETCH_UPDATE_PAYMENT_HISTORY",
  ON_FETCH_EXPORT_PAYMENT_HISTORY: "ON_FETCH_EXPORT_PAYMENT_HISTORY",
  END_FETCH_EXPORT_PAYMENT_HISTORY: "END_FETCH_EXPORT_PAYMENT_HISTORY",
};

export const paymentFormAction = {
  ON_FETCH_CREATE_PAYMENT_FORM: "ON_FETCH_CREATE_PAYMENT_FORM",
  END_FETCH_CREATE_PAYMENT_FORM: "END_FETCH_CREATE_PAYMENT_FORM",
  ON_FETCH_INDEX_PAYMENT_FORM: "ON_FETCH_INDEX_PAYMENT_FORM",
  END_FETCH_INDEX_PAYMENT_FORM: "END_FETCH_INDEX_PAYMENT_FORM",
  ON_FETCH_SHOW_PAYMENT_FORM: "ON_FETCH_SHOW_PAYMENT_FORM",
  END_FETCH_SHOW_PAYMENT_FORM: "END_FETCH_SHOW_PAYMENT_FORM",
  ON_FETCH_UPDATE_PAYMENT_FORM: "ON_FETCH_UPDATE_PAYMENT_FORM",
  END_FETCH_UPDATE_PAYMENT_FORM: "END_FETCH_UPDATE_PAYMENT_FORM",
  ON_FETCH_DELETE_PAYMENT_FORM: "ON_FETCH_DELETE_PAYMENT_FORM",
  END_FETCH_DELETE_PAYMENT_FORM: "END_FETCH_DELETE_PAYMENT_FORM",
  ON_FETCH_UPDATE_PAYMENT_FORM_ITEM: "ON_FETCH_UPDATE_PAYMENT_FORM_ITEM",
  END_FETCH_UPDATE_PAYMENT_FORM_ITEM: "END_FETCH_UPDATE_PAYMENT_FORM_ITEM",
  ON_FETCH_UPDATE_PAYMENT_FORM_PLAN: "ON_FETCH_UPDATE_PAYMENT_FORM_PLAN",
  END_FETCH_UPDATE_PAYMENT_FORM_PLAN: "END_FETCH_UPDATE_PAYMENT_FORM_PLAN",
  ON_FETCH_QR_ISSUE_PAYMENT_FORM: "ON_FETCH_QR_ISSUE_PAYMENT_FORM",
  END_FETCH_QR_ISSUE_PAYMENT_FORM: "END_FETCH_QR_ISSUE_PAYMENT_FORM"
};

export const mailPaymentAction = {
  ON_FETCH_CREATE_MAIL_PAYMENT: "ON_FETCH_CREATE_MAIL_PAYMENT",
  END_FETCH_CREATE_MAIL_PAYMENT: "END_FETCH_CREATE_MAIL_PAYMENT",
  ON_FETCH_INDEX_MAIL_PAYMENT: "ON_FETCH_INDEX_MAIL_PAYMENT",
  END_FETCH_INDEX_MAIL_PAYMENT: "END_FETCH_INDEX_MAIL_PAYMENT",
  ON_FETCH_SHOW_MAIL_PAYMENT: "ON_FETCH_SHOW_MAIL_PAYMENT",
  END_FETCH_SHOW_MAIL_PAYMENT: "END_FETCH_SHOW_MAIL_PAYMENT",
  ON_FETCH_UPDATE_MAIL_PAYMENT: "ON_FETCH_UPDATE_MAIL_PAYMENT",
  END_FETCH_UPDATE_MAIL_PAYMENT: "END_FETCH_UPDATE_MAIL_PAYMENT",
  ON_FETCH_DELETE_MAIL_PAYMENT: "ON_FETCH_DELETE_MAIL_PAYMENT",
  END_FETCH_DELETE_MAIL_PAYMENT: "END_FETCH_DELETE_MAIL_PAYMENT",
  ON_FETCH_TEST_SEND_MAIL_PAYMENT: "ON_FETCH_TEST_SEND_MAIL_PAYMENT",
  END_FETCH_TEST_SEND_MAIL_PAYMENT: "END_FETCH_TEST_SEND_MAIL_PAYMENT",
  CLEAR_MAIL_PAYMENT: "CLEAR_MAIL_PAYMENT"
};

export const mailPaymentRecipientAction = {
  ON_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT: "ON_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT",
  END_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT: "END_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT",
  ON_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT: "ON_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT",
  END_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT: "END_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT",
  ON_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT: "ON_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT",
  END_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT: "END_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT",
  ON_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT: "ON_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT",
  END_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT: "END_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT",
  CLEAR_MAIL_PAYMENT_RECIPIENT: "CLEAR_MAIL_PAYMENT_RECIPIENT"
};

export const bulkPaymentAction = {
  ON_FETCH_CREATE_BULK_PAYMENT: "ON_FETCH_CREATE_BULK_PAYMENT",
  END_FETCH_CREATE_BULK_PAYMENT: "END_FETCH_CREATE_BULK_PAYMENT",
  ON_FETCH_INDEX_BULK_PAYMENT: "ON_FETCH_INDEX_BULK_PAYMENT",
  END_FETCH_INDEX_BULK_PAYMENT: "END_FETCH_INDEX_BULK_PAYMENT",
  ON_FETCH_SHOW_BULK_PAYMENT: "ON_FETCH_SHOW_BULK_PAYMENT",
  END_FETCH_SHOW_BULK_PAYMENT: "END_FETCH_SHOW_BULK_PAYMENT",
  ON_FETCH_UPDATE_BULK_PAYMENT: "ON_FETCH_UPDATE_BULK_PAYMENT",
  END_FETCH_UPDATE_BULK_PAYMENT: "END_FETCH_UPDATE_BULK_PAYMENT",
  ON_FETCH_DELETE_BULK_PAYMENT: "ON_FETCH_DELETE_BULK_PAYMENT",
  END_FETCH_DELETE_BULK_PAYMENT: "END_FETCH_DELETE_BULK_PAYMENT",
  CLEAR_BULK_PAYMENT: "CLEAR_BULK_PAYMENT"
};

export const billingStatementAction = {
  ON_FETCH_INDEX_BILLING_STATEMENT: "ON_FETCH_INDEX_BILLING_STATEMENT",
  END_FETCH_INDEX_BILLING_STATEMENT: "END_FETCH_INDEX_BILLING_STATEMENT",
  ON_FETCH_SHOW_BILLING_STATEMENT: "ON_FETCH_SHOW_BILLING_STATEMENT",
  END_FETCH_SHOW_BILLING_STATEMENT: "END_FETCH_SHOW_BILLING_STATEMENT",
};

export const analysisPeriodAction = {
  ON_FETCH_INDEX_ANALYSIS_PERIOD: "ON_FETCH_INDEX_ANALYSIS_PERIOD",
  END_FETCH_INDEX_ANALYSIS_PERIOD: "END_FETCH_INDEX_ANALYSIS_PERIOD",
};

export const adminUserAction = {
  ON_FETCH_CREATE_ADMIN_USER: "ON_FETCH_CREATE_ADMIN_USER",
  END_FETCH_CREATE_ADMIN_USER: "END_FETCH_CREATE_ADMIN_USER",
  ON_FETCH_INDEX_ADMIN_USER: "ON_FETCH_INDEX_ADMIN_USER",
  END_FETCH_INDEX_ADMIN_USER: "END_FETCH_INDEX_ADMIN_USER",
  ON_FETCH_SHOW_ADMIN_USER: "ON_FETCH_SHOW_ADMIN_USER",
  END_FETCH_SHOW_ADMIN_USER: "END_FETCH_SHOW_ADMIN_USER",
  ON_FETCH_UPDATE_ADMIN_USER: "ON_FETCH_UPDATE_ADMIN_USER",
  END_FETCH_UPDATE_ADMIN_USER: "END_FETCH_UPDATE_ADMIN_USER",
  ON_FETCH_DELETE_ADMIN_USER: "ON_FETCH_DELETE_ADMIN_USER",
  END_FETCH_DELETE_ADMIN_USER: "END_FETCH_DELETE_ADMIN_USER",
  CLEAR_ADMIN_USER: "CLEAR_ADMIN_USER"
};

export const companyAction = {
  ON_FETCH_CREATE_COMPANY: "ON_FETCH_CREATE_COMPANY",
  END_FETCH_CREATE_COMPANY: "END_FETCH_CREATE_COMPANY",
  ON_FETCH_INDEX_COMPANY: "ON_FETCH_INDEX_COMPANY",
  END_FETCH_INDEX_COMPANY: "END_FETCH_INDEX_COMPANY",
  ON_FETCH_SHOW_COMPANY: "ON_FETCH_SHOW_COMPANY",
  END_FETCH_SHOW_COMPANY: "END_FETCH_SHOW_COMPANY",
  ON_FETCH_UPDATE_COMPANY: "ON_FETCH_UPDATE_COMPANY",
  END_FETCH_UPDATE_COMPANY: "END_FETCH_UPDATE_COMPANY",
  ON_FETCH_DELETE_COMPANY: "ON_FETCH_DELETE_COMPANY",
  END_FETCH_DELETE_COMPANY: "END_FETCH_DELETE_COMPANY",
  CLEAR_COMPANY: "CLEAR_COMPANY"
};

export const qaInformationAction = {
  ON_FETCH_CREATE_QA_INFORMATION: "ON_FETCH_CREATE_QA_INFORMATION",
  END_FETCH_CREATE_QA_INFORMATION: "END_FETCH_CREATE_QA_INFORMATION",
  ON_FETCH_INDEX_QA_INFORMATION: "ON_FETCH_INDEX_QA_INFORMATION",
  END_FETCH_INDEX_QA_INFORMATION: "END_FETCH_INDEX_QA_INFORMATION",
  ON_FETCH_SHOW_QA_INFORMATION: "ON_FETCH_SHOW_QA_INFORMATION",
  END_FETCH_SHOW_QA_INFORMATION: "END_FETCH_SHOW_QA_INFORMATION",
  ON_FETCH_UPDATE_QA_INFORMATION: "ON_FETCH_UPDATE_QA_INFORMATION",
  END_FETCH_UPDATE_QA_INFORMATION: "END_FETCH_UPDATE_QA_INFORMATION",
  ON_FETCH_DELETE_QA_INFORMATION: "ON_FETCH_DELETE_QA_INFORMATION",
  END_FETCH_DELETE_QA_INFORMATION: "END_FETCH_DELETE_QA_INFORMATION",
  CLEAR_QA_INFORMATION: "CLEAR_QA_INFORMATION"
};

export const qaCategoryAction = {
  ON_FETCH_CREATE_QA_CATEGORY: "ON_FETCH_CREATE_QA_CATEGORY",
  END_FETCH_CREATE_QA_CATEGORY: "END_FETCH_CREATE_QA_CATEGORY",
  ON_FETCH_INDEX_QA_CATEGORY: "ON_FETCH_INDEX_QA_CATEGORY",
  END_FETCH_INDEX_QA_CATEGORY: "END_FETCH_INDEX_QA_CATEGORY",
  ON_FETCH_SHOW_QA_CATEGORY: "ON_FETCH_SHOW_QA_CATEGORY",
  END_FETCH_SHOW_QA_CATEGORY: "END_FETCH_SHOW_QA_CATEGORY",
  ON_FETCH_UPDATE_QA_CATEGORY: "ON_FETCH_UPDATE_QA_CATEGORY",
  END_FETCH_UPDATE_QA_CATEGORY: "END_FETCH_UPDATE_QA_CATEGORY",
  ON_FETCH_DELETE_QA_CATEGORY: "ON_FETCH_DELETE_QA_CATEGORY",
  END_FETCH_DELETE_QA_CATEGORY: "END_FETCH_DELETE_QA_CATEGORY",
  CLEAR_QA_CATEGORY: "CLEAR_QA_CATEGORY"
};

export const exportLogAction = {
  ON_FETCH_INDEX_EXPORT_LOG: "ON_FETCH_INDEX_EXPORT_LOG",
  END_FETCH_INDEX_EXPORT_LOG: "END_FETCH_INDEX_EXPORT_LOG",
  ON_FETCH_DELETE_EXPORT_LOG: "ON_FETCH_DELETE_EXPORT_LOG",
  END_FETCH_DELETE_EXPORT_LOG: "END_FETCH_DELETE_EXPORT_LOG",
  ON_FETCH_DOWNLOAD_EXPORT_LOG: "ON_FETCH_DOWNLOAD_EXPORT_LOG",
  END_FETCH_DOWNLOAD_EXPORT_LOG: "END_FETCH_DOWNLOAD_EXPORT_LOG"
};
