import { mailPaymentRecipientAction } from '../../constants/actionTypes';
import { put, takeLatest } from 'redux-saga/effects';
import { Api } from './api';

function* onFetchIndexMailPaymentRecipientAction(action) {
  const result = yield Api.getIndexMailPaymentRecipient(action.data);
  yield put({type: mailPaymentRecipientAction.END_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT, result: result});
}

function* onFetchShowMailPaymentRecipientAction(action) {
  const result = yield Api.getShowMailPaymentRecipient(action.data);
  yield put({type: mailPaymentRecipientAction.END_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT, result: result});
}

function* onFetchUpdateMailPaymentRecipientAction(action) {
  const result = yield Api.putUpdateMailPaymentRecipient(action.data);
  yield put({type: mailPaymentRecipientAction.END_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT, result: result});
}

function* onFetchDeleteMailPaymentRecipientAction(action) {
  const result = yield Api.deleteDeleteMailPaymentRecipient(action.data);
  yield put({type: mailPaymentRecipientAction.END_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT, result: result});
}

export function* watchOnFetchMailPaymentRecipient() {
  yield takeLatest(mailPaymentRecipientAction.ON_FETCH_INDEX_MAIL_PAYMENT_RECIPIENT, onFetchIndexMailPaymentRecipientAction);
  yield takeLatest(mailPaymentRecipientAction.ON_FETCH_SHOW_MAIL_PAYMENT_RECIPIENT, onFetchShowMailPaymentRecipientAction);
  yield takeLatest(mailPaymentRecipientAction.ON_FETCH_UPDATE_MAIL_PAYMENT_RECIPIENT, onFetchUpdateMailPaymentRecipientAction);
  yield takeLatest(mailPaymentRecipientAction.ON_FETCH_DELETE_MAIL_PAYMENT_RECIPIENT, onFetchDeleteMailPaymentRecipientAction);
}