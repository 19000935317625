import { fromJS, Map } from 'immutable';
import {qaInformationAction} from "../constants/actionTypes";

const initialState = {
  loadingQaInformationCreate: false,
  loadingQaInformationIndex: true,
  loadingQaInformationShow: false,
  loadingQaInformationData: false,
  qaInformationData: Map(),
  initQaInformationData: Map({
    id: '',
    position: '',
    name: '',
    question: '',
    answer: '',
    status: '',
    qa_category_id: '',
    qa_category: '',
  })
};

const initialImmutableState = fromJS(initialState);
const qaInformationReducers = (state = initialImmutableState, action) => {
  switch (action.type) {
    case qaInformationAction.ON_FETCH_CREATE_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationCreate', true);
      });
    case qaInformationAction.END_FETCH_CREATE_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationCreate', false);
      });
    case qaInformationAction.ON_FETCH_INDEX_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationIndex', true);
      });
    case qaInformationAction.END_FETCH_INDEX_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationIndex', false);
      });
    case qaInformationAction.ON_FETCH_SHOW_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationShow', true);
      });
    case qaInformationAction.END_FETCH_SHOW_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationShow', false);
        mutableState.set('qaInformationData', action.result);
      });
    case qaInformationAction.ON_FETCH_UPDATE_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationData', true);
      });
    case qaInformationAction.END_FETCH_UPDATE_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationData', false);
      });
    case qaInformationAction.ON_FETCH_DELETE_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationData', true);
      });
    case qaInformationAction.END_FETCH_DELETE_QA_INFORMATION:
      return state.withMutations((mutableState) => {
        mutableState.set('loadingQaInformationData', false);
      });
    case qaInformationAction.CLEAR_QA_INFORMATION:
        return state.withMutations((mutableState) => {
          mutableState.set('qaInformationData', initialState.initQaInformationData);
        });
    default:
      return state
  }
};

export default qaInformationReducers;