import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { FieldArray } from 'redux-form/immutable';
import styles from '../Forms/user-jss';
import renderFormItems from '../../containers/Pages/PaymentForms/renderFormItems';
import MySnackbar from '../Notification/StyledNotif';
import { useLocation } from 'react-router-dom';


function SelectedOptionDialog(props) {
  const {
    classes,
    select,
    handleCloseDialog,
    dialogData,
    index,
    syncErrors,
    itemIndex,
    formValues
  } = props;

  useEffect(() => { setOpen(dialogData.open) }, [dialogData]);

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const checkErrorItem = (arr) => {
    arr = arr.filter((item) => item !== undefined);
    for (const item of arr) {
      const errorArray = Object.keys(item);
      if (errorArray.length === 1 && errorArray.includes("form_item_selects_attributes")) {
        let error_options = item.form_item_selects_attributes.filter((option) => option !== undefined)
        for (const error_option of error_options) {
          if (Object.keys(error_option).length !== 0) {
            return false;
          }
        }
      } else if (errorArray.length !== 0) {
        return false;
      }
    }
    return true;
  }

  const checkError = (index) => {
    if (syncErrors.size === 0 || syncErrors.form_items_attributes === undefined) {
      handleCloseDialog()
      return
    } else {
      const form_item_error = syncErrors.form_items_attributes[itemIndex]
      if (form_item_error === undefined || form_item_error.form_item_selects_attributes === undefined) {
        handleCloseDialog()
        return
      } else {
        const errorsArray = form_item_error.form_item_selects_attributes[index]
        if (errorsArray === undefined || errorsArray.form_items_attributes === undefined) {
          handleCloseDialog()
        } else if (checkErrorItem(errorsArray.form_items_attributes)) {
          handleCloseDialog()
        } else {
          displayNotification("必須項目を入力してください")
        }
      }
    }
  }

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const getTitle = () => {
    return formValues.form_item_selects_attributes[index].value || "" ;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => checkError(index)}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'分岐フォーム詳細：'+ getTitle()}
        </DialogTitle>
        <DialogContent>
          <section className={classes.formWrap}>
            <FieldArray name={`${select}.form_items_attributes`} component={renderFormItems} display={false}/>
          </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => checkError(index)} color="primary">
            閉じる
          </Button>
        </DialogActions>
        <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(SelectedOptionDialog);