import API from '../../../constants/EnvConstants';
import callApi from "../../../utils/handleCallApi";

function* postCreatePaymentForm(data) {
  const url = `${API.samuraiApiUrl}/payment_forms`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getIndexPaymentForm(data) {
  const url = `${API.samuraiApiUrl}/payment_forms`;
  return yield callApi("get", url, data.data, data.accessToken, data.data, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* getShowPaymentForm(data) {
  const url = `${API.samuraiApiUrl}/payment_forms/${data.data.id}`;
  return yield callApi("get", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* putUpdatePaymentForm(data) {
  const url = `${API.samuraiApiUrl}/payment_forms/${data.data.id}`;
  return yield callApi("put", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* deleteDeletePaymentForm(data) {
  const url = `${API.samuraiApiUrl}/payment_forms/${data.data.id}`;
  return yield callApi("delete", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postUpdatePaymentFormItem(data) {
  const url = `${API.samuraiApiUrl}/payment_forms/${data.data.id}/update_form_item`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postUpdatePaymentFormPlan(data) {
  const url = `${API.samuraiApiUrl}/payment_forms/${data.data.id}/update_payment_plan`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

function* postQrIssuePaymentForm(data) {
  const url = `${API.samuraiApiUrl}/payment_forms/${data.data.id}/qr_issue`;
  return yield callApi("post", url, data.data, data.accessToken, null, data.onSuccess, data.onError, data.onErrorWithNotice);
}

export const Api = {
  postCreatePaymentForm,
  getIndexPaymentForm,
  getShowPaymentForm,
  putUpdatePaymentForm,
  deleteDeletePaymentForm,
  postUpdatePaymentFormItem,
  postUpdatePaymentFormPlan,
  postQrIssuePaymentForm
};
