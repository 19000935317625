import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../Pages/Standalone/NotFoundDedicated';
import Auth from './Auth';
import Application from './Application';
import LoginDedicated from '../Pages/Standalone/LoginDedicated';
import ThemeWrapper from './ThemeWrapper';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
import LoginRequiredRoute from '../../components/LoginRequiredRoute/LoginRequiredRoute' 

function App() {
  return (
    <ThemeWrapper>
      <Switch>
        <Route path="/admin" exact component={LoginDedicated} />
        <Route path="/admin/top" component={Application} />
        <Route component={Auth} />
        <Route component={NotFound} />
      </Switch>
    </ThemeWrapper>
  );
}

export default App;
