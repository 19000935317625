import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import Form from './Form';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import { connect } from 'react-redux';
import { onFetchShowBulkPaymentAction, onFetchCreateBulkPaymentAction, onFetchUpdateBulkPaymentAction, onFetchDeleteBulkPaymentAction, clearBulkPaymentAction } from "../../../redux/actions/bulkPayment";
import accessToken from '../../../utils/accessToken';
import * as Validator from '../../../utils/validate';

function Edit(props) {
  const description = brand.desc;
  const { match } = props;
  const { role } = props.internalValues
  const [loading, setLoading] = useState(true);

  const [isNew, setIsNew] = useState(false);
  const [titleName, setTitleName] = useState("");

  // const [csvFiles, setCsvFiles] = useState([]); // eslint-disable-line
  
  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const formId = match.params.id

  const url = '/admin/bulk_payment/index'

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const resetLoading = () => {
    setLoading(true)
    setLoading(false)
  }

  // 詳細取得
  useEffect(() => {
    let url = props.path.split('/')
    if (url[url.length - 1] === 'new') {
      setIsNew(true)
      setTitleName("一括決済")
      setLoading(false)
      props.clearBulkPayment()
    } else {
      fetchForm()
      setTitleName("一括決済詳細")
    }
  }, []);

  const fetchForm = values => {
    props.onFetchShowBulkPayment({
      accessToken: accessToken(props),
      data: {id: formId},
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 登録
  const newForm = values => {
    // CSVを正常に取得するためFormDataを利用
    const formData = new FormData();
    // valuesが存在し、かつvaluesのキーが存在する場合のみ処理を行う
    if (values && values.keys) {
      // valuesのキーのイテレーターを取得し、各キーと値をFormDataにセット
      for (const key of values.keys()) {
        formData.append(`bulk_payment[${key}]`, values.get(key));
      }
    }
    props.onFetchCreateBulkPayment({
      accessToken: accessToken(props),
      data: formData,
      onSuccess: async data => {
        props.history.push({ pathname: url, message: "CSV一括登録処理を開始しました", variant: 'success', createdFormUrl: data.form_url});
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  // 更新
  // const editForm = (values) => {
  //   let dataValues = values
  //   dataValues.id = formId
  //   props.onFetchUpdateBulkPayment({
  //     accessToken: accessToken(props),
  //     data: dataValues,
  //     onSuccess: async data => {
  //       displayNotification("更新が完了しました", 'success')
  //     },
  //     onErrorWithNotice: error => {
  //       displayNotification(error)
  //     }
  //   })
  // };

  // 削除
  // const deleteForm = values => {
  //   props.onFetchDeleteBulkPayment({
  //     accessToken: accessToken(props),
  //     data: {id: formId},
  //     onSuccess: async data => {
  //       props.history.push({ pathname: '/admin/bulk_payment/index', message: '一括決済を削除しました', variant: 'success'});
  //     },
  //     onErrorWithNotice: error => {
  //       displayNotification("削除できませんでした")
  //     }
  //   })
  // };
  
  return (
    <div>
      <Helmet>
        <title>{brand.name + " - " + titleName}</title>
      </Helmet>
        
          <div>
          {/* 決済フォーム情報取得Apiが終了するまでは、空のフォームを表示させる */}
          {loading || props.loadingBulkPaymentShow ? <Form /> : <Form onSubmit={(values) => isNew ? newForm(values) : editForm(values)} initialValues={props.bulkPaymentData} formId={formId} isNew={isNew}/>}
          </div>
      <MySnackbar onClose={() => setNotification({...notification, openStyle:false})} notification={notification} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    bulkPaymentData: state.getIn(['bulkPaymentReducers', 'bulkPaymentData']),
    loadingBulkPaymentShow: state.getIn(['bulkPaymentReducers', 'loadingBulkPaymentShow']),
    loadingBulkPaymentData: state.getIn(['bulkPaymentReducers', 'loadingBulkPaymentData'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShowBulkPayment: data => {
      dispatch(onFetchShowBulkPaymentAction(data));
    },
    onFetchCreateBulkPayment: data => {
      dispatch(onFetchCreateBulkPaymentAction(data));
    },
    onFetchUpdateBulkPayment: data => {
      dispatch(onFetchUpdateBulkPaymentAction(data));
    },
    onFetchDeleteBulkPayment: data => {
      dispatch(onFetchDeleteBulkPaymentAction(data));
    },
    clearBulkPayment: data => {
      dispatch(clearBulkPaymentAction(data));
    },
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(FormInit));